import React, { useEffect, useState } from "react";
import { getFullDate } from "utilities";

export const SectionDateTime = ({ isMobile }) => {
  const [time, setTime] = useState({
    minutes: new Date().getMinutes(),
    hours: new Date().getHours(),
    seconds: new Date().getSeconds(),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setTime({
        minutes: date.getMinutes(),
        hours: date.getHours(),
        seconds: date.getSeconds(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const convertToTwoDigit = (number) => {
    return number.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
  };

  return (
    <div className={`text-center ${isMobile ? 'mt-0 mb-5' : 'mt-20 mb-10'}`}>
      <div className={`${isMobile ? 'text-4xl' : 'text-5xl'} font-semibold tracking-widest`}>
        {getFullDate(new Date())}
      </div>
      <div className={`${isMobile ? 'text-5xl mt-5' : 'text-8xl mt-10'} font-bold tracking-widest`}>
        <span>{convertToTwoDigit(time.hours)}:</span>
        <span>{convertToTwoDigit(time.minutes)}:</span>
        <span>{convertToTwoDigit(time.seconds)}</span>
      </div>
    </div>
  );
};
