import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  hexToRGB,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import {
  Button,
  DeleteButon,
  EditButton,
  Input,
  Label,
  LabelDanger,
  LabelSuccess,
  ListLayout,
  Modal,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
  Select,
  ViewButton,
} from "components";
import { CoverSection, DetailSection, FormSection } from "./__BlogComponents__";
import { FileApi, KategoriBlogApi } from "api";
import { Formik } from "formik";
import BlogApi from "./__BlogApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__BlogUtilities__";
import { MdPublish } from "react-icons/md";
import { FaImage } from "react-icons/fa";

export const Blog = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isPublish: undefined,
    direction: "DESC",
  });
  const [isPublish, setIsPublish] = useState("none");
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });
  const [filter, setFilter] = useState({
    categoryPublicId: "0",
  });

  const columns = [
    {
      name: "No.",
      selector: (_, index) =>
        (getBlog.data?.pageable?.pageNumber - 1) *
          getBlog.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Kategori",
      selector: (row) => (
        <label
          className={`text-sm py-1 px-3 rounded-md font-bold w-fit`}
          style={{
            color: row.categoryColorCode,
            backgroundColor: hexToRGB(row.categoryColorCode, 0.2),
          }}
        >
          {row.categoryName}
        </label>
      ),
      wrap: true,
      width: "150px",
    },
    {
      name: "Judul",
      selector: (row) => row.title,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Cover",
      button: true,
      width: "50px",
      cell: (data) => (
        <>
          {permissions.includes("BLOG_R") && (
            <Button
              onClick={() => onViewCoverButtonClickHandler(data)}
              className="p-2 btn-info"
            >
              <FaImage />
            </Button>
          )}
        </>
      ),
    },
    {
      name: "Tanggal Buat",
      selector: (row) => row.createdAt,
      wrap: true,
      width: "120px",
    },
    {
      name: "Tanggal Ubah",
      selector: (row) => row.updatedAt,
      wrap: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (data) =>
        data?.isPublish ? (
          <LabelSuccess text="Terbit" />
        ) : (
          <LabelDanger text="Tidak Terbit" />
        ),
      wrap: true,
      width: "120px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("BLOG_R") && (
            <ViewButton
              icon
              noText
              onClick={() => onReadButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("BLOG_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("BLOG_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
          {permissions.includes("BLOG_U") && (
            <Button
              onClick={() => onPublishButtonClickHandler(data)}
              className="btn-none p-2 ml-1"
            >
              <MdPublish />
            </Button>
          )}
        </>
      ),
      width: "150px",
    },
  ];

  const getBlog = useQuery(["blog-list", pagination], () =>
    BlogApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => {
      return [];
    })
  );
  const getKategori = useQuery(["kategori-blog", "dropdown"], () =>
    KategoriBlogApi.getKategoriBlog(axiosPrivate)
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        isPublish: e.target.name,
      });
      setIsPublish(e.target.name);
    } else {
      setPagination({
        ...pagination,
        isPublish: undefined,
      });
      setIsPublish("none");
    }
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      errorText: "",
      error: false,
      data: data,
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const onPublishButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "publish",
      errorText: "",
      error: false,
      data: data,
    });

  const onViewCoverButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "view_cover",
      errorText: "",
      error: false,
      data: data,
    });

  const submitHandler = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    let resultUploadImages = [];
    if (values?.images?.length > 0) {
      if (values?.images?.[0]?.data) {
        const fileData = new FormData();
        fileData.append("file", values?.images?.[0]?.data);

        await FileApi.single(axiosPrivate, fileData)
          .then((res) =>
            resultUploadImages.push({
              name: res?.name,
              fileId: res?.id,
              fileUrl: res?.url,
              publicId: values?.tampImages?.[0]?.publicId, //jika action update, maka akan terisi
              oldFileId: values?.tampImages?.[0]?.fileId, //jika action update, maka akan memberikan fileId lama,
              isDeleted: false,
            })
          )
          .catch((err) => {
            errorFetching(err);
            resultUploadImages.push({
              name: values?.tampImages?.[0]?.name,
              fileId: values?.tampImages?.[0]?.fileId,
              fileUrl: values?.tampImages?.[0]?.fileUrl,
              publicId: values?.tampImages?.[0]?.publicId,
              isDeleted: false,
            });
          });
      } else {
        resultUploadImages.push({
          name: values?.images?.[0]?.name,
          fileId: values?.images?.[0]?.fileId,
          fileUrl: values?.images?.[0]?.fileUrl,
          publicId: values?.images?.[0]?.publicId,
          isDeleted: false,
        });
      }
    }

    const finalValues = formSubmitValueMapper(values, resultUploadImages);
    if (modal.type === "create") {
      await createBlog
        .mutateAsync(finalValues)
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      await updateBlog
        .mutateAsync({
          data: finalValues,
          publicId: values?.publicId,
        })
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const createBlog = useMutation((data) => BlogApi.create(axiosPrivate, data), {
    onSuccess: () => {
      getBlog.refetch();
    },
  });

  const updateBlog = useMutation(
    ({ data, publicId }) => BlogApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getBlog.refetch();
      },
    }
  );

  const deleteBlog = useMutation((id) => BlogApi.delete(axiosPrivate, id), {
    onSuccess: () => {
      getBlog.refetch();
    },
  });

  const setPublishBlog = useMutation(
    ({ id, statusPublish }) =>
      BlogApi.setPublish(axiosPrivate, id, { value: statusPublish }),
    {
      onSuccess: () => {
        getBlog.refetch();
      },
    }
  );

  return (
    <>
      <div className="flex flex-row mb-4">
        <div className="flex">
          <Select
            label="Kategori"
            placeholder="Pilih Kategori"
            defaultValue={getKategori?.data?.find(
              (item) => item.value === filter?.categoryPublicId
            )}
            onChange={(val) => {
              setPagination({
                ...pagination,
                categoryPublicId: val.value !== "0" ? val.value : undefined,
              });
              setFilter({
                categoryPublicId: val.value,
              });
            }}
            options={getKategori?.data ?? []}
            errorFetch={getKategori.isError}
            errorFetchText={getKategori?.error?.response?.data?.errorMessage[0]}
            loading={getKategori.isFetching}
          />
        </div>
      </div>
      <ListLayout
        permissions={permissions}
        permissionCreate="ADMIN"
        data={getBlog.data?.content}
        columns={columns}
        loading={getBlog.isFetching}
        error={getBlog.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getBlog.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
        activeStatusFilter
        activeOnChange={activeDataHandler}
        activeValues={isPublish}
        activeText={["Semua", "Terbit", "Tidak Terbit"]}
      />

      {/* <---- MODAL CREATE ----> */}
      {modal.type === "create" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues("create", {})}
          validationSchema={formValidationSchema()}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;
            return (
              <Modal
                id="modal-tambah-blog"
                header="Tambah Blog"
                size="large"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "create")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection modal={modal} publicId={modal.data.publicId} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL VIEW COVER ---> */}
      {modal.type === "view_cover" && modal.show && (
        <Modal
          id="modal-view-cover"
          header="Cover Blog"
          size="medium"
          type="read"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "view_cover")}
        >
          <CoverSection modal={modal} />
        </Modal>
      )}

      {/* <--- MODAL READ ---> */}
      {modal.type === "read" && modal.show && (
        <Modal
          id="modal-detil-blog"
          header="Detail Blog"
          size="large"
          type="read"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "read")}
        >
          <DetailSection data={modal} />
        </Modal>
      )}

      {/* <--- MODAL UPDATE ---> */}
      {modal.type === "update" && modal.show && (
        <Formik
          enableReinitialize
          validationSchema={formValidationSchema()}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;

            return (
              <Modal
                id="modal-ubah-blog"
                header="Ubah Blog"
                size="large"
                type="update"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "update")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection modal={modal} publicId={modal.data.publicId} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL DELETE ---> */}
      {modal.type === "delete" && modal.show && (
        <Modal
          id="modal-hapus-blog"
          header="Hapus Blog"
          size="large"
          type="delete"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "delete")}
          isSubmitting={deleteBlog.isLoading}
          onSubmit={() => {
            deleteBlog
              .mutateAsync(modal.data.publicId)
              .then((res) => {
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          <DetailSection data={modal} />
        </Modal>
      )}

      {/* <--- MODAL PUBLISH ---> */}
      {modal.type === "publish" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={{
            isPublish: modal.data?.isPublish,
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            setPublishBlog
              .mutateAsync({
                id: modal.data.publicId,
                statusPublish: values?.isPublish,
              })
              .then((res) => {
                successFetching(res);
                onHideModalHandler();
                resetForm();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm, values, setValues } = formik;

            return (
              <Modal
                id="modal-ubah-status-terbit-blog"
                header="Ubah Status Terbit Blog"
                size="small"
                type="update"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "publish")}
                onSubmit={handleSubmit}
                isSubmitting={setPublishBlog.isLoading}
              >
                <div className="space-y-r">
                  <Input
                    label="Kategori"
                    disabled
                    value={modal?.data?.categoryName}
                  />
                  <Input label="Judul" disabled value={modal?.data?.title} />
                  <RadioButtonWrapper label="Status">
                    <RadioButtonGroup>
                      <RadioButton
                        checked={Boolean(values.isPublish)}
                        label="Terbit"
                        onChange={() =>
                          setValues({ ...values, isPublish: true })
                        }
                      />
                      <RadioButton
                        checked={Boolean(!values.isPublish)}
                        label="Tidak Terbit"
                        onChange={() =>
                          setValues({ ...values, isPublish: false })
                        }
                      />
                    </RadioButtonGroup>
                  </RadioButtonWrapper>
                  {modal.error && <Label type="error" text={modal.errorText} />}
                </div>
              </Modal>
            );
          }}
        </Formik>
      )}
    </>
  );
};
