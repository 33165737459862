import React, { useState } from "react";
import { ClassroomsApi, GradeApi } from "api";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import {
  DataStatus,
  EditButton,
  ListLayout,
  Modal,
  SearchInput,
  Select,
} from "components";
import KegiatanKesiswaanApi from "./__KegiatanKesiswaanApi__";
import { Formik } from "formik";
import { FormSection } from "./__KegiatanKesiswaanComponents__";
import {
  formPrestasiInitialValues,
  formValidationPrestasiSchema,
} from "./__KegiatanKesiswaanUtilities__";

export const KegiatanKesiswaan = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    status: 1,
    direction: "ASC",
  });
  const [modal, setModal] = useState({
    show: false,
    type: "update",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- useQuery --->
  const getPrestasi = useQuery(["prestasi-siswa-list", pagination], () =>
    KegiatanKesiswaanApi.getListPrestasi(axiosPrivate, pagination).catch(() => {
      return [];
    })
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  // <--- useMutation --->
  const updateStatusPrestasi = useMutation(
    ({ data, studentPublicId, studentHasAchievementPublicId }) =>
      KegiatanKesiswaanApi.updateStatusPrestasi(
        axiosPrivate,
        studentPublicId,
        studentHasAchievementPublicId,
        data
      ),
    {
      onSuccess: () => {
        getPrestasi.refetch();
      },
    }
  );

  // <--- Table's column --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getPrestasi.data?.pageable?.pageNumber - 1) *
          getPrestasi.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentName,
      wrap: true,
      minWidth: "250px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      wrap: true,
      width: "80px",
    },
    {
      name: "Kegiatan",
      selector: (row) => row?.name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Partisipasi",
      selector: (row) => row?.participationName,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Jenis Kegiatan",
      selector: (row) => row?.achievementName,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Regional",
      selector: (row) => row?.regional,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Tgl. Kegiatan",
      selector: (row) =>
        row?.activityDate ? getDashDMY(row?.activityDate) : "-",
      width: "110px",
      wrap: true,
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          <div className="flex flex-row">
            {permissions.includes("ACHIEVEMENT_U") && (
              <EditButton
                icon
                noText
                onClick={() => onUpdateButtonClickHandler(data)}
                className="mr-1"
              />
            )}
          </div>
        </>
      ),
      width: "80px",
    },
  ];

  return (
    <>
      <small className="font-bold text-base">
        Daftar Kegiatan Siswa (Status: Pending)
      </small>
      {/* <--- Table ---> */}
      <ListLayout
        permissions={permissions}
        data={getPrestasi.data?.content}
        columns={columns}
        loading={Boolean(getPrestasi.isFetching)}
        error={getPrestasi.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getPrestasi.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        customLeftTopSection={() => <SearchInput onChange={searchDataHandler} placeholder="Masukkan NIS / Nama Siswa" />}
      />

      {/* <--- MODAL UPDATE ---> */}
      {modal.type === "update" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formPrestasiInitialValues()}
          validationSchema={formValidationPrestasiSchema()}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            let finalValues = {};
            if (values.status === 2) {
              finalValues = {
                status: values?.status,
              };
            } else {
              finalValues = { ...values };
            }

            updateStatusPrestasi
              .mutateAsync({
                data: finalValues,
                studentPublicId: modal?.data.studentPublicId,
                studentHasAchievementPublicId: modal?.data.publicId,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik;

            return (
              <Modal
                id="modal-ubah-status-prestasi-siswa"
                header="Ubah Status Kegiatan Siswa"
                size="large"
                type="update"
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === "update")}
                onSubmit={handleSubmit}
                isSubmitting={updateStatusPrestasi.isLoading}
              >
                <FormSection modal={modal} />
              </Modal>
            );
          }}
        </Formik>
      )}
    </>
  );
};
