export const formInitialValues = () => ({
  gradePublicId: "",
  grade: "",
  classroomPublicId: "",
  classroom: "",
  studentPublicId: "",
  student: "",
  startDate: new Date(),
  endDate: new Date(),
  description: "",
  flag: "",
});

export const formVerifikasiInitialValues = (data) => ({
  publicId: data?.publicId,
  studentNama: data?.studentNama,
  studentNis: data?.studentNis,
  studentClass: data?.studentClass,
  startDate: data?.startDate,
  endDate: data?.endDate,
  description: data?.description,
  value: "",
  flag: data?.flag,
  text: "",
});
