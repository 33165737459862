import React from "react";
import { Label, RowDetailLayout } from "components";

export const DetailSection = ({ data }) => {
  return (
    <>
      <RowDetailLayout label="Alasan Terlambat" text={data.data.value} />
      {data.error && <Label type="error" text={data.errorText} />}
    </>
  );
};
