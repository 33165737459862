export const InputFileSingle = ({
  label,
  name,
  className,
  wrapperClassName,
  placeholder,
  value,
  disabled,
  onChange,
  acceptFileType = ['pdf', 'xlsx', 'docx', 'zip', 'rar'],
  error,
  errorText,
  valid,
  validText,
}) => {
  const onChangeFileHandler = (e) => {
    const file = e.target.files && e.target.files[0]

    // is there any file selected?
    if(!file) return null

    // get file type
    const fileType = file.name.split('.').pop()

    // whether the file type is correct
    if(acceptFileType.includes(fileType ? fileType : '')){
      const name = file.name
      const link = URL.createObjectURL(file)
      const finalValue = {data: file, name: name, link: link}

      return onChange && onChange(finalValue)
    } else{
      e.target.value = ''
      return window.alert(`Format file tidak valid! Pastikan file memiliki format ${acceptFileType.join(', ')}`)
    }
  }

  return (
    <div className={`${wrapperClassName} ml-1`}>
      <small>
        {label}
      </small>
      <input 
        name={name}
        type="file"
        className={`${className ? className : 'input-file'} ${disabled ? 'disabled:cursor-not-allowed' : ''}`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChangeFileHandler}
      />
      <small className='text-clobasoft-dark-purple font-bold px-1'>Ukuran file max. 2 MB</small>
      {error && <div>
          <small className='validation-error-text'>
              {errorText}
          </small>
      </div>}
      {valid && <div>
          <small className='validation-success-text'>
              {validText}
          </small>
      </div>}
    </div>
  )
}
