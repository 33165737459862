import { DropdownApi } from "api";
import { Input, Label, RowDetailLayout, Select } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "react-query";
import { getDashDMY } from "utilities";

export const FormVerifikasiSection = ({ modal }) => {
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  const getAbsencePermitFlag = useQuery(
    ["absence-permit-flag", "verification", "dropdown"],
    () => DropdownApi.getAbsencePermitFlag()
  );

  const statusVerifikasi = [
    {
      label: "Disetujui",
      value: "APPROVED",
    },
    {
      label: "Tidak Disetujui",
      value: "REJECTED",
    },
  ];

  return (
    <div className="space-y-3">
      <RowDetailLayout label="Nama Siswa" text={values?.studentNama} />
      <RowDetailLayout
        label="NIS (Nomor Induk Siswa)"
        text={values?.studentNis}
      />
      <RowDetailLayout label="Kelas" text={values?.studentClass} />
      <RowDetailLayout
        label="Tanggal Awal Izin"
        text={getDashDMY(new Date(values?.startDate)) ?? "-"}
      />
      <RowDetailLayout
        label="Tanggal Akhir Izin"
        text={getDashDMY(new Date(values?.endDate)) ?? "-"}
      />
      <RowDetailLayout label="Keterangan Izin" text={values?.description} />
      <Select
        label="Status"
        placeholder="Pilih Status"
        name="flag"
        defaultValue={getAbsencePermitFlag?.data?.find(
          (item) => item.value === values.flag
        )}
        onChange={(val) =>
          setValues({
            ...values,
            flag: val.value,
          })
        }
        options={getAbsencePermitFlag?.data ?? []}
        loading={getAbsencePermitFlag.isFetching}
      />
      <Select
        label="Status Verifikasi"
        placeholder="Pilih Status Verifikasi"
        name="value"
        defaultValue={statusVerifikasi?.find(
          (item) => item.value === values?.value
        )}
        onChange={(val) =>
          setValues({
            ...values,
            value: val.value,
          })
        }
        options={statusVerifikasi ?? []}
        error={Boolean(errors?.value && touched?.value)}
        errorText={Boolean(errors?.value && touched?.value) && errors?.value}
      />
      {Boolean(values.value === "REJECTED") && (
        <Input
          name="text"
          label="Catatan"
          type="text"
          onChange={handleChange}
          value={values.text}
        />
      )}
      {modal.error && <Label type="error" text={modal.errorText} />}
    </div>
  );
};
