export const formInitialValues = (action, data = {}, tahunAjaranPublicId) => ({
  tahunAjaranPublicId: tahunAjaranPublicId,
  tingkatanKelasPublicId: "",
  kelasPublicId: "",
  kelasNama: data?.kelasNama,
  siswaPublicId: "",
  siswaNama: data?.siswaNama,
  keterangan: action !== "create" ? data?.keterangan : "",
  hari: action !== "create" ? data?.hari : "",
});
