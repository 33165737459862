import { ClassroomsApi, GradeApi } from "api";
import { Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";

export const ContentKembaliKeKelas = () => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched } = useFormikContext();

  // <-- useQuery -->
  const getTingkatanKelas = useQuery(["tingkatan-kelas-list"], () =>
    GradeApi.getGrade(axiosPrivate, {
      isActive: true,
      sortBy: "name",
      direction: "ASC",
    })
  );

  const getKelas = useQuery(
    ["kelas-list", values.gradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values.gradePublicId,
        sortBy: "name",
        direction: "ASC",
      }),
    { enabled: Boolean(values.gradePublicId !== "") }
  );

  return (
    <div className="space-y-3">
      <Input label="Nama Siswa" value={values.studentName} disabled />
      <Input
        label="NIS (Nomor Induk Siswa)"
        value={values?.studentNis}
        disabled
      />
      <div>Dikembalikan ke kelas</div>
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <div className="sm:flex-grow sm:mr-3">
          <Select
            label="Tingkatan Kelas"
            placeholder="Pilih Tingkatan Kelas"
            defaultValue={getTingkatanKelas?.data?.find(
              (item) => item.value === values.gradePublicId
            )}
            onChange={(val) =>
              setValues({
                ...values,
                gradePublicId: val.value,
                classroomPublicId: "",
              })
            }
            options={getTingkatanKelas?.data ?? []}
            error={Boolean(errors?.gradePublicId && touched?.gradePublicId)}
            errorText={
              Boolean(errors?.gradePublicId && touched?.gradePublicId) &&
              errors?.gradePublicId
            }
            errorFetch={getTingkatanKelas.isError}
            errorFetchText={
              getTingkatanKelas?.error?.response?.data?.errorMessage[0]
            }
            loading={getTingkatanKelas.isFetching}
          />
        </div>
        <div className="sm:flex-grow sm:ml-3">
          <Select
            label="Kelas Tujuan"
            placeholder="Pilih Kelas Tujuan"
            defaultValue={getKelas?.data?.find(
              (item) => item.value === values?.classroomPublicId
            )}
            onChange={(val) =>
              setValues({
                ...values,
                classroomPublicId: val.value,
              })
            }
            options={getKelas?.data ?? []}
            error={Boolean(
              errors?.classroomPublicId && touched?.classroomPublicId
            )}
            errorText={
              Boolean(
                errors?.classroomPublicId && touched?.classroomPublicId
              ) && errors?.classroomPublicId
            }
            errorFetch={getKelas.isError}
            errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
            loading={getKelas.isFetching}
            disable={Boolean(values.gradePublicId === "")}
          />
        </div>
      </div>
    </div>
  );
};
