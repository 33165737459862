import { TablePrint } from "components";
import React from "react";
import { getDashDMY } from "utilities";

export const ExportPelanggaranSiswa = ({ data }) => {
  const TABLE_HEADER = [
    { text: "No.", props: { width: "30px", fontSize: "11px" } },
    { text: "NIS", props: { width: "80px", fontSize: "11px" } },
    { text: "Nama Siswa", props: { fontSize: "11px" } },
    { text: "Kelas", props: { width: "60px", fontSize: "11px" } },
    { text: "Tgl. Pelanggaran", props: { width: "100px", fontSize: "11px" } },
    { text: "Pelanggaran", props: { fontSize: "11px" } },
    { text: "Keterangan", props: { fontSize: "11px" } },
  ];

  const TABLE_BODY = [
    {
      field: (_, index) => index + 1,
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => val?.studentNis,
      props: { textAlign: "center", fontSize: "11px" },
    },
    { field: (val) => val?.studentName, props: { fontSize: "11px" } },
    {
      field: (val) => val?.classroomName,
      props: { textAlign: "center", fontSize: "11px" },
    },
    {
      field: (val) => (val?.activityDate ? getDashDMY(val?.activityDate) : "-"),
      props: { textAlign: "center", fontSize: "11px" },
    },
    { field: (val) => val?.violationName, props: { fontSize: "11px" } },
    { field: (val) => val?.description, props: { fontSize: "11px" } },
  ];

  return (
    <div className="mt-5">
      <TablePrint
        tableId="export-table-pelanggaran-siswa"
        data={data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  );
};
