import { mappingDataOptions } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KegiatanApi {
    async getList(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/achievements/pageable`, { params })
        return fetch.data.payload
    }

    async getJenisKegiatan(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/achievement-libraries`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    async getRegional(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/achievement-regionals`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    async getPartisipasi(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/achievement-participations`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    create(axiosPrivate, data) {
        return axiosPrivate.post(`${url}/v1/achievements`, data)
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/achievements/${id}`, data)
    }

    delete(axiosPrivate, id) {
        return axiosPrivate.delete(`${url}/v1/achievements/${id}`)
    }
}

export default new KegiatanApi()