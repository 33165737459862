class DropdownApi {
  async getGender() {
    const data = [
      { value: "P", label: "Perempuan" },
      { value: "L", label: "Laki-Laki" },
    ];

    return data;
  }

  async getStatusPrestasi() {
    const data = [
      { value: 2, label: "APPROVED" },
      { value: 3, label: "REVISION" },
      { value: 4, label: "REJECTED" },
    ];
    return data;
  }

  async getAttendanceFlag() {
    const data = [
      { value: "-", label: "TANPA STATUS" },
      { value: "MASUK", label: "MASUK" },
      { value: "TERLAMBAT", label: "TERLAMBAT" },
      { value: "IZIN_TERLAMBAT", label: "IZIN TERLAMBAT" },
    ];
    return data;
  }

  async getAttendanceFlagVerification() {
    const data = [
      { value: "TERLAMBAT", label: "TERLAMBAT" },
      { value: "MASUK", label: "MASUK" },
      { value: "MASUK_KELUAR", label: "IZIN MENINGGALKAN KELAS" },
      { value: "IZIN_TERLAMBAT", label: "IZIN TERLAMBAT" },
    ];
    return data;
  }

  async getAbsencePermitFlag() {
    const data = [
      { value: "IZIN", label: "IZIN" },
      { value: "MASUK_KELUAR", label: "IZIN MENINGGALKAN KELAS" },
      { value: "IZIN_SAKIT", label: "IZIN SAKIT" },
    ];
    return data;
  }

  async getYearMonthPeriode(startDate, endDate) {
    const data = [];
    const startMonth = Number(startDate.split("-")[1]);
    const endMonth = Number(endDate.split("-")[1]);
    const startYear = startDate.split("-")[0];
    const endYear = endDate.split("-")[0];

    for (let i = startMonth; i <= 12; i++) {
      data.push({
        value: `${startYear}-${i < 10 ? `0${i}` : `${i}`}`,
        label: `${i < 10 ? `0${i}` : `${i}`}-${startYear}`,
      });
    }

    for (let i = 1; i <= endMonth; i++) {
      data.push({
        value: `${endYear}-${i < 10 ? `0${i}` : `${i}`}`,
        label: `${i < 10 ? `0${i}` : `${i}`}-${endYear}`,
      });
    }

    return data;
  }

  async getDays() {
    const data = [
      { value: 1, label: "Senin" },
      { value: 2, label: "Selasa" },
      { value: 3, label: "Rabu" },
      { value: 4, label: "Kamis" },
      { value: 5, label: "Jumat" },
      { value: 6, label: "Sabtu" },
      { value: 7, label: "Minggu" },
    ];

    return data;
  }
}

export default new DropdownApi();
