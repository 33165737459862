export const formInitialValues = (action, data = {}) => ({
  publicId: action !== "create" ? data?.publicId : undefined ?? undefined,
  title: action !== "create" ? data?.title : "" ?? "",
  isPublish: action !== "create" ? data?.isPublish : false ?? false,
  body: action !== "create" ? data?.body : "" ?? "",
  category: {
    publicId: action !== "create" ? data?.category?.publicId : "" ?? "",
    name: action !== "create" ? data?.category?.name : "" ?? "",
    code: action !== "create" ? data?.category?.code : "" ?? "",
  },
  images:
    action !== "create"
      ? data?.images?.map((val) => ({
          publicId: val?.publicId,
          name: val?.name,
          fileUrl: val?.fileUrl,
          fileId: val?.fileId,
          data: null,
        }))
      : [] ?? [],
  tampImages:
    action !== "create"
      ? data?.images?.map((val) => ({
          publicId: val?.publicId,
          name: val?.name,
          fileUrl: val?.fileUrl,
          fileId: val?.fileId,
          data: null,
        }))
      : undefined ?? undefined,
  user:
    action !== "create"
      ? {
          publicId: data?.user?.publicId,
          username: data?.user?.username,
          name: data?.user?.name,
        }
      : undefined,
});
