import { Spinner } from "components"
import { FaPrint } from "react-icons/fa"
import { Button } from "../Button"

export const PrintButton = ({
  onClick,
  disabled,
  loading,
  icon,
  noText,
  className,
  text = 'Cetak'
}) => {
  return (
    <Button
      className={`btn-info justify-center flex flex-row items-center p-2 ${noText ?? 'px-5'} ${className}`}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {loading ? <><Spinner />{noText ?? <span className="ml-2">Proses...</span>}</> : <>{icon && <FaPrint className={`${noText ?? "mr-2"}`} />}{noText ?? <span>{text}</span>}</>}
    </Button>
  )
}
