export const formInitialValues = (action, data = {}, classroom = {}) => ({
    publicId: action !== 'create' ? data?.publicId : undefined ?? undefined,
    gradeId: action !== 'create' ? data?.classroom?.grade?.publicId : classroom?.tingkatanPublicId ?? undefined,
    grade: action !== 'create' ? data?.classroom?.grade?.name : classroom?.tingkatanName ?? undefined,
    classroomId: action !== 'create' ? data?.classroom?.publicId : classroom?.kelasPublicId ?? undefined,
    classroom: action !== 'create' ? data?.classroom?.name : classroom?.kelasName ?? undefined,
    name: action !== 'create' ? data?.name : '' ?? '',
    nis: action !== 'create' ? data?.nis : '' ?? '',
    username: action !== 'create' ? data?.user?.username : '' ?? '',
    gender: action !== 'create' ? data?.gender : '' ?? '',
    dateOfBirth: action !== 'create' ? data?.dateOfBirth : '' ?? '',
    email: action !== 'create' ? data?.email : '' ?? '',
    isEmailAvailable: action !== 'create' ? true : false ?? false,
    phoneNo: action !== 'create' ? data?.phoneNo : '' ?? '',
    isActive: action !== 'create' ? data?.isActive : true ?? true,
    street: action !== 'create' ? data?.address?.street : '' ?? '',
    address: {
        desaId: action !== 'create' ? data?.address?.desa?.id : '' ?? '',
        kecamatanId: action !== 'create' ? data?.address?.desa?.kecamatan?.id : '' ?? '',
        kabupatenId: action !== 'create' ? data?.address?.desa?.kecamatan?.kabupaten?.id : '' ?? '',
        provinsiId: action !== 'create' ? data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.id : '' ?? '',
        street: action !== 'create' ? data?.address?.street : '' ?? ''
    }
})

export const naikKelasInitialValues = (data = {}) => ({
    originClassroomPublicId: data.kelasPublicId ?? '',
    destinationClassroomPublicId: '',
    destinationGradePublicId: '',
    periode: '',
    tingkatanKelas: data.tingkatanName ?? '',
    kelas: data.kelasName ?? '',
    isGraduated: false
})

export const lulusInitialValues = (data = {}) => ({
    originClassroomPublicId: data.kelasPublicId ?? '',
    destinationClassroomPublicId: data?.kelasPublicId ?? '',
    destinationGradePublicId: data?.kelasPublicId ?? '',
    isGraduated: true,
    periode: '',
    tingkatanKelas: data.tingkatanName ?? '',
    kelas: data.kelasName ?? '',
})

export const pindahKelasInitialValues = (data = {}, studentPublicId) => ({
    originClassroomPublicId: data?.kelasPublicId ?? '',
    studentPublicId: studentPublicId,
    destinationClassroomPublicId: '',
    destinationGradePublicId: '',
    tingkatanKelas: data.tingkatanName ?? '',
    kelas: data.kelasName ?? ''
})

export const importSiswaInitialValues = () => ({
    dataFile: null
})