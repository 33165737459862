// Components
import { 
    Select,
    Input, 
    Label, 
    RadioButton, 
    RadioButtonGroup, 
    RadioButtonWrapper, 
    TextArea 
} from "components"

// Form
import { useFormikContext } from "formik"

export const FormSection = ({ modal, dropdown }) => {
    // Hooks
    const { values, setValues, errors, touched, handleChange } = useFormikContext()

    return (
        <>
            <Select 
                label='Tingkatan Kelas'
                placeholder='Pilih Tingkatan Kelas'
                name='gradePublicId'
                defaultValue={dropdown.getTingkatanKelas?.data?.find(item => item.value === values.gradePublicId)}
                onChange={val => setValues({...values, gradePublicId: val.value})}
                options={dropdown.getTingkatanKelas?.data ?? []}
                error={Boolean(errors.gradePublicId && touched.gradePublicId)}
                errorText={Boolean(errors.gradePublicId && touched.gradePublicId) && errors.gradePublicId}
                errorFetch={dropdown.getTingkatanKelas.isError}
                errorFetchText={dropdown.getTingkatanKelas?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getTingkatanKelas.isFetching}
            />
            <Input 
                label='Nama' 
                name='name'
                type='text'
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name && touched.name)}
                errorText={Boolean(errors.name && touched.name) && errors.name}
            />
            <Input 
                label='Kode' 
                name='code'
                type='text'
                value={values.code}
                onChange={handleChange}
                error={Boolean(errors.code && touched.code)}
                errorText={Boolean(errors.code && touched.code) && errors.code}
            />
            <Select 
                label='Guru Wali Kelas'
                placeholder='Pilih Guru Wali Kelas'
                name='teacherGuidePublicId'
                defaultValue={dropdown.getGuru?.data?.find(item => item.value === values.teacherGuidePublicId)}
                onChange={val => setValues({...values, teacherGuidePublicId: val.value})}
                options={dropdown.getGuru?.data ?? []}
                error={Boolean(errors.teacherGuidePublicId && touched.teacherGuidePublicId)}
                errorText={Boolean(errors.teacherGuidePublicId && touched.teacherGuidePublicId) && errors.teacherGuidePublicId}
                errorFetch={dropdown.getGuru.isError}
                errorFetchText={dropdown.getGuru?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getGuru.isFetching}
            />
            <TextArea 
                label='Keterangan (opsional)'
                name='description'
                value={values.description}
                rows={4}
                onChange={handleChange}
                error={Boolean(errors.description && touched.description)}
                errorText={Boolean(errors.description && touched.description) && errors.description}
            />
            <RadioButtonWrapper label='Status'>
                <RadioButtonGroup>
                    <RadioButton 
                        checked={Boolean(values.isActive)}
                        label='Aktif'
                        onChange={() => setValues({...values, isActive: true})}
                    />
                    <RadioButton 
                        checked={Boolean(!values.isActive)}
                        label='Tidak Aktif'
                        onChange={() => setValues({...values, isActive: false})}
                    />
                </RadioButtonGroup>
            </RadioButtonWrapper>
            {modal.error && 
                <Label 
                    type='error'
                    text={modal.errorText}
                />
            }
        </>
    )
}
