import { mappingDataOptions } from "utilities";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class KategoriQnaApi {
  async getKategoriQna(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/qna-category`, { params });
    return mappingDataOptions(fetch.data.payload);
  }
}

export default new KategoriQnaApi();
