import { Label, RowDetailLayout } from 'components'
import React from 'react'

export const DetailSection = ({ data }) => {
    return (
        <>
            <RowDetailLayout
                label='Nama Pelanggaran'
                text={data.data.name}
            />
            <RowDetailLayout
                label='Poin Pelanggaran'
                text={data.data.point}
            />
            <RowDetailLayout
                label='Keterangan'
                text={data.data.description}
            />
            <RowDetailLayout
                label='Status'
                text={data.data.isActive ? 'Aktif' : 'Tidak Aktif'}
            />
            {data.error && 
                <Label 
                    type='error'
                    text={data.errorText}
                />
            }
        </>
    )
}
