import { Input, Table } from 'components'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getDashYMD, useAxiosPrivate } from 'utilities'
import BerandaApi from '../__BerandaApi__'

export const TabelPelanggaran = () => {
  const axiosPrivate = useAxiosPrivate()
  const [date, setDate] = useState({
    startDate: getDashYMD(new Date())
  })

  const getPelanggaranSiswa = useQuery(
    ['pelanggaran-siswa-list', date],
    () => BerandaApi.getListPelanggaran(axiosPrivate, date),
    {enabled: Boolean(date?.startDate)}
  )

  // <--- Table's column --->
  const columns = [
    {
      name: 'No',
      selector: (row, index) => index + 1,
      width: "70px" 
    },
    {
      name: 'NIS',
      selector: row => row?.studentNis,
      width: '100px',
      wrap: true
    },
    {
      name: 'Nama Siswa',
      selector: row => row?.studentName,
      wrap: true,
      minWidth: '300px'
    },
    {
      name: 'Kelas',
      selector: row => row?.classroomName,
      width: '100px',
      wrap: true
    },
    {
      name: 'Pelanggaran',
      selector: row => row?.violationName,
      wrap: true,
      minWidth: '300px'
    },
    {
      name: 'Keterangan',
      selector: row => row?.description,
      wrap: true,
      minWidth: '300px'
    },
  ]

  return (
    <div className='mt-14'>
      <h3 className="text-center mb-3 font-semibold">TABEL PELANGGARAN SISWA</h3>
      <div className='mb-3 w-52'>
        <Input 
          label='Tanggal' 
          type='date'
          value={date.startDate}
          onChange={(e) => {
            setDate({...date, startDate: e.target.value})
          }}
        />
      </div>
      <Table
        columns={columns}
        data={getPelanggaranSiswa?.data}
        loading={getPelanggaranSiswa.isFetching}
        error={getPelanggaranSiswa.error?.response?.data?.errorMessage[0]}
      />
    </div>
  )
}
