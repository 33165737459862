import React, { useState } from "react";
import {
  errorFetching,
  getDashDMY,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { useMutation, useQuery } from "react-query";
import DashboardIzinApi from "./__DashboardIzinApi__";
import {
  CreateButton,
  DatePicker,
  LabelInfo,
  ListLayout,
  Modal,
  SearchInput,
  ViewButton,
} from "components";
import { useSubscription } from "react-stomp-hooks";
import {
  ConvertAbsencePermitFlag,
  DetailSection,
  FormSection,
  FormVerifikasiSection,
} from "./__DashboardIzinComponents__";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  formVerifikasiInitialValues,
  formVerifikasiValidationSchema,
} from "./__DashboardIzinUtilities__";

const Subscription = () => {
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const axiosPrivate = useAxiosPrivate();

  const [updateIzin, isUpdateIzin] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [status, setStatus] = useState("none");
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "id",
    direction: "DESC",
    status: status,
    startDate: `${selectedDate.getFullYear()}-${
      selectedDate.getMonth() + 1 < 10
        ? `0${selectedDate.getMonth() + 1}`
        : selectedDate.getMonth() + 1
    }-${
      selectedDate.getDate() < 10
        ? `0${selectedDate.getDate()}`
        : selectedDate.getDate()
    }`,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  const getListPageable = useQuery(
    ["absence-permit-list", "pageable", pagination],
    () =>
      DashboardIzinApi.getListPageable(
        axiosPrivate,
        paginationHandler({
          ...pagination,
          status:
            status === "none"
              ? undefined
              : status === "true"
              ? "!PENDING"
              : "PENDING",
        })
      ).catch(() => {
        return [];
      }),
    {
      enable: Boolean(updateIzin),
    }
  );

  useSubscription("/all/absence-permit", (message) => {
    if (message.body) {
      isUpdateIzin(Boolean(message.body));
      if (Boolean(message.body)) {
        getListPageable.refetch();
      }
    } else {
      isUpdateIzin(false);
    }
  });

  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getListPageable.data?.pageable?.pageNumber - 1) *
          getListPageable.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis ?? "-",
      width: "120px",
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentNama ?? "-",
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.studentClass ?? "-",
      width: "80px",
    },
    {
      name: "Tanggal Awal Izin",
      selector: (row) => getDashDMY(row?.startDate) ?? "-",
      width: "150px",
    },
    {
      name: "Tanggal Akhir Izin",
      selector: (row) => getDashDMY(row?.endDate) ?? "-",
      width: "150px",
    },
    {
      name: "Keterangan Izin",
      selector: (row) => row?.description ?? "-",
      minWidth: "280px",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row?.flag ? convertAbsencePermitStatus(row?.flag, row?.status) : "-",
      width: "250px",
    },
    {
      name: "Aksi",
      button: true,
      width: "200px",
      cell: (data) => (
        <>
          {permissions.includes("SCH_ABC_PRM_A") &&
          data?.status === "PENDING" ? (
            <CreateButton
              text="Verifikasi"
              onClick={() => onClickVerifikasiButton(data)}
            />
          ) : (
            <ViewButton icon noText onClick={() => onClickDetailButton(data)} />
          )}
        </>
      ),
    },
  ];

  const convertAbsencePermitStatus = (flag, status) => {
    if (status === "PENDING") {
      return <LabelInfo text="Menunggu Persetujuan" />;
    } else {
      return <ConvertAbsencePermitFlag flag={flag} status={status} />;
    }
  };

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        status: e.target.name === "true" ? "!PENDING" : "PENDING",
      });
      setStatus(e.target.name);
    } else {
      setPagination({
        ...pagination,
        status: undefined,
      });
      setStatus("none");
    }
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickVerifikasiButton = (data) =>
    setModal({
      show: true,
      type: "verifikasi",
      errorText: "",
      error: false,
      data: data,
    });

  const onClickDetailButton = (data) =>
    setModal({
      show: true,
      type: "detail",
      errorText: "",
      error: false,
      data: data,
    });

  const onChangeDate = (date) => {
    setSelectedDate(new Date(date));
    setPagination({
      ...pagination,
      startDate: `${new Date(date).getFullYear()}-${
        new Date(date).getMonth() + 1 < 10
          ? `0${new Date(date).getMonth() + 1}`
          : new Date(date).getMonth() + 1
      }-${
        new Date(date).getDate() < 10
          ? `0${new Date(date).getDate()}`
          : new Date(date).getDate()
      }`,
    });
  };

  const verifikasiIzin = useMutation(
    ({ izinPublicId, status, flag, text }) =>
      DashboardIzinApi.verifikasi(axiosPrivate, izinPublicId, {
        value: status,
        flag: flag,
        text: text,
      }),
    {
      onSuccess: () => {
        getListPageable.remove();
        getListPageable.refetch();
      },
    }
  );

  const createIzin = useMutation(
    ({ studentPublicId, data }) =>
      DashboardIzinApi.create(axiosPrivate, studentPublicId, data),
    {
      onSuccess: () => {
        getListPageable.refetch();
      },
    }
  );

  return (
    <>
      <div className="flex flex-row items-end">
        <div className="flex flex-row flex-1 items-end">
          <div className="mr-5">
            <DatePicker
              label="Tanggal Awal Izin"
              dateFormat="dd-MM-yyyy"
              selected={selectedDate}
              onChange={onChangeDate}
            />
          </div>
          <div className="flex-1">
            <SearchInput onChange={searchDataHandler} />
          </div>
        </div>
        <div>
          {permissions.includes("SCH_ATT_C") && (
            <CreateButton type="button" onClick={onClickCreateButton} />
          )}
        </div>
      </div>
      <ListLayout
        permissions={permissions}
        permissionCreate="SCH_ABC_PRM_C"
        data={getListPageable.data?.content}
        columns={columns}
        loading={getListPageable.isFetching}
        error={getListPageable.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getListPageable.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        // onSearchChange={searchDataHandler}
        // onClickCreateButton={onClickCreateButton}
        customLeftTopSection={() => <></>}
        customRightTopSection={() => <></>}
        activeStatusFilter
        activeOnChange={activeDataHandler}
        activeValues={status}
        activeText={["Semua", "Sudah Diverifikasi", "Belum Diverifikasi"]}
      />

      {/* <--- MODAL VERIFIKASI IZIN ---> */}
      {modal.type === "verifikasi" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formVerifikasiInitialValues(modal?.data)}
          validationSchema={formVerifikasiValidationSchema()}
          onSubmit={(values, { resetForm }) =>
            verifikasiIzin
              .mutateAsync({
                izinPublicId: values?.publicId,
                status: values?.value,
                flag: values?.flag,
                text: values?.value === "REJECTED" ? values?.text : undefined,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              })
          }
        >
          {(formik) => {
            const { handleSubmit } = formik;
            return (
              <Modal
                id="modal-verifikasi-izin"
                header="Verifikasi Izin"
                size="small"
                type="update"
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === "verifikasi")}
                isSubmitting={verifikasiIzin.isLoading}
                onSubmit={handleSubmit}
              >
                <FormVerifikasiSection modal={modal} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL TAMBAH IZIN ---> */}
      {modal.type === "create" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues()}
          validationSchema={formValidationSchema()}
          onSubmit={async (values, { resetForm }) => {
            const finalValues = formSubmitValueMapper(values);

            createIzin
              .mutateAsync({
                studentPublicId: values?.studentPublicId,
                data: finalValues,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm } = formik;

            return (
              <Modal
                id="modal-tambah-izin"
                header="Tambah Izin"
                size="medium"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "create")}
                onSubmit={handleSubmit}
                isSubmitting={createIzin.isLoading}
              >
                <FormSection modal={modal} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL DETAIL IZIN ---> */}
      {modal.type === "detail" && modal.show && (
        <Modal
          id="modal-detail-izin"
          header="Detail Izin"
          size="small"
          type="detail"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "detail")}
        >
          <DetailSection data={modal.data} />
        </Modal>
      )}
    </>
  );
};

export const DashboardIzin = () => {
  return <Subscription />;
};
