import React, { useEffect, useState } from "react";
import { SectionDateTime, SectionStatus } from "./__GenerateQrCodeComponents__";
import { useStompClient, useSubscription } from "react-stomp-hooks";
import { QRCodeSVG } from "qrcode.react";
import { PacmanLoader } from "react-spinners";
import { useQuery } from "react-query";
import { TeacherApi } from "api";
import { axiosPrivate } from "services";
import { Button } from "components";
import { FaSyncAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "helper/MaxMobileWidthHelper";

const Subscription = () => {
  const [jsonResponse, setJsonResponse] = useState(null);
  const clientId = sessionStorage.getItem("ws-connection-cid");
  const [qrCodeValue, setQrCodeValue] = useState({
    qrId: undefined,
    clientId: clientId,
    teacherPublicId: undefined,
  });
  const stompClient = useStompClient();
  const isMobile = useMediaQuery({ query: MAX_MOBILE_WIDTH });

  const getDetailGuru = useQuery(["detail-guru"], () =>
    TeacherApi.getDetailTeacher(axiosPrivate)
  );

  useSubscription(`/receive/attendance/${clientId}`, (message) => {
    const responseJson = JSON.parse(message.body);
    setJsonResponse(responseJson);
    setQrCodeValue({
      ...qrCodeValue,
      qrId: responseJson?.qrId,
      clientId: clientId,
    });
  });

  const generate = () => {
    if (stompClient) {
      stompClient.publish({
        destination: "/ws/attendance/v5",
        body: qrCodeValue?.qrId
          ? JSON.stringify({ qrId: qrCodeValue.qrId, clientId: clientId })
          : undefined,
      });
    }
  };

  useEffect(() => {
    if (getDetailGuru.data?.publicId) {
      setQrCodeValue({
        ...qrCodeValue,
        teacherPublicId: getDetailGuru.data?.publicId,
      });

      if (jsonResponse?.qrStatus !== undefined) {
        if (jsonResponse?.qrStatus !== null) {
          if (jsonResponse?.qrStatus === "INIT") {
            generate();
          }
        }
      } else {
        generate();
      }
    }
  }, [
    jsonResponse,
    sessionStorage.getItem("ws-connection-status"),
    getDetailGuru.data,
  ]);

  if (isMobile) {
    return (
      <div>
        <div className="mb-5 flex justify-end">
          <Button
            className="btn-primary"
            onClick={() => window.location.reload()}
          >
            <FaSyncAlt />
          </Button>
        </div>
        <SectionDateTime isMobile={isMobile} />
        <div className="flex items-center justify-center">
          {jsonResponse !== null ? (
            jsonResponse?.qrStatus === "SHOWQR" ? (
              <QRCodeSVG value={JSON.stringify(qrCodeValue)} size={300} />
            ) : (
              <SectionStatus status={jsonResponse?.qrStatus} />
            )
          ) : (
            <div
              className={`flex flex-1 flex-col overflow-y-auto justify-center items-center p-10`}
            >
              <span className={`text-2xl font-semibold mb-5 text-center`}>
                {getDetailGuru.isError
                  ? "GAGAL GENERATE QR CODE. SILAHKAN LOGIN MENGGUNAKAN AKUN GURU!"
                  : "PROSES GENERATE QR CODE"}
              </span>
              <PacmanLoader color={"#1976D2"} size={25} />
            </div>
          )}
        </div>
        <div className="text-center">
          {jsonResponse?.qrStatus === "SUCCESS" ? (
            // MENAMPILKAN NAMA SISWA, NIS, DAN STATUS PRESENSI
            <div className="space-y-2 font-semibold">
              <div className="text-xl mt-5">{jsonResponse?.student?.name}</div>
              <div className="text-xl">{jsonResponse?.student?.nis}</div>
              <div
                className={`${
                  jsonResponse?.attendance?.flag === "TERLAMBAT"
                    ? "text-danger text-white"
                    : jsonResponse?.attendance?.flag === "MASUK"
                    ? "text-success text-white"
                    : ""
                } py-1 px-[3px] rounded-md text-2xl`}
              >
                {jsonResponse?.attendance?.flag}
              </div>
            </div>
          ) : jsonResponse?.qrStatus === "LOADING" ? (
            <></>
          ) : (
            <div className="text-3xl mt-5">
              Silahkan <b>SCAN QR CODE</b> untuk presensi
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="grid grid-cols-2"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <div className="flex items-center justify-center">
          {jsonResponse !== null ? (
            jsonResponse?.qrStatus === "SHOWQR" ? (
              <QRCodeSVG value={JSON.stringify(qrCodeValue)} size={500} />
            ) : (
              <SectionStatus status={jsonResponse?.qrStatus} />
            )
          ) : (
            <div
              className={`flex flex-1 flex-col overflow-y-auto justify-center items-center p-10`}
            >
              <span className={`text-5xl font-semibold mb-5 text-center`}>
                {getDetailGuru.isError
                  ? "GAGAL GENERATE QR CODE. SILAHKAN LOGIN MENGGUNAKAN AKUN GURU!"
                  : "PROSES GENERATE QR CODE"}
              </span>
              <PacmanLoader color={"#1976D2"} size={25} />
            </div>
          )}
        </div>
        <div className="">
          <div className="float-right">
            <Button
              className="btn-primary"
              onClick={() => window.location.reload()}
            >
              <FaSyncAlt />
            </Button>
          </div>
          <SectionDateTime isMobile={isMobile} />
          <div className="text-center">
            {jsonResponse?.qrStatus === "SUCCESS" ? (
              // MENAMPILKAN NAMA SISWA, NIS, DAN STATUS PRESENSI
              <div className="space-y-5 font-semibold">
                <div className="text-4xl">{jsonResponse?.student?.name}</div>
                <div className="text-2xl">
                  NIS: {jsonResponse?.student?.nis}
                </div>
                <div
                  className={`${
                    jsonResponse?.attendance?.flag === "TERLAMBAT"
                      ? "text-danger text-white"
                      : jsonResponse?.attendance?.flag === "MASUK"
                      ? "text-success text-white"
                      : ""
                  } py-3 px-[6px] rounded-md text-3xl`}
                >
                  {jsonResponse?.attendance?.flag}
                </div>
              </div>
            ) : jsonResponse?.qrStatus === "LOADING" ? (
              <></>
            ) : (
              <div className="text-5xl">
                Silahkan <b>SCAN QR CODE</b> untuk presensi
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export const GenerateQrCode = () => {
  return <Subscription />;
};
