export const formInitialValues = (action, data = {}) => ({
  publicId: action !== "create" ? data?.publicId : undefined ?? undefined,
  title: action !== "create" ? data?.title : "" ?? "",
  body: action !== "create" ? data?.body : "" ?? "",
  index: action !== "create" ? data?.index : 0 ?? 0,
  qnaCategory: {
    publicId: action !== "create" ? data?.qnaCategory?.publicId : "" ?? "",
    name: action !== "create" ? data?.qnaCategory?.name : "" ?? "",
    code: action !== "create" ? data?.qnaCategory?.code : "" ?? "",
  },
});
