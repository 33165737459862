import { Tabs as FTabs } from "flowbite-react";

export const Tabs = ({ data }) => {
  /**
   *  Struktur object data:
   *  [
   *    {
   *      title: 'Title',
   *      component: <Component />
   *    }
   *  ]
   */

  return (
    <FTabs.Group aria-label="Tabs" style="underline">
      {data?.map((val, index) => (
        <FTabs.Item key={index} title={val?.title}>
          {val?.component}
        </FTabs.Item>
      ))}
    </FTabs.Group>
  );
};
