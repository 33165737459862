import { HeaderKop } from "components/Header/HeaderKop";
import "./__PrintLayoutStyles__/index.css";

// Referensi: https://plnkr.co/edit/lWk6Yd?preview

export const PrintLayout = ({ footer, children }) => {
  return (
    <>
      <div className="page-header pt-10 pr-16 pl-16">
        <HeaderKop />
      </div>

      <div class="page-footer pr-16 pl-16">{footer}</div>

      <table className="w-full">
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* <!--*** CONTENT GOES HERE ***--> */}
              <div className="pr-16 pl-16">{children}</div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position footer-->  */}
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
