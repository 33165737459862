import { RowDetailLayout } from "components";
import { getDay } from "utilities";

export const DetailSection = ({ data, tahunAjaran }) => {
  const { siswaNama, kelasNama, keterangan, hari } = data;

  return (
    <>
      <RowDetailLayout label="Tahun Ajaran" text={tahunAjaran} />
      <RowDetailLayout label="Kelas" text={kelasNama} />
      <RowDetailLayout label="Nama Siswa" text={siswaNama} />
      <RowDetailLayout
        label="Hari"
        text={hari === 7 ? getDay(0) : getDay(hari)}
      />
      <RowDetailLayout label="Keterangan" text={keterangan} />
    </>
  );
};
