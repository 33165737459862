import { toast, ToastContainer } from 'react-toastify'

export const Toast = () => {
    return (
        <ToastContainer />
    )
}

export const toastTrigger = ({ variant, message }) => {
    const config = {
        position:'top-right'
    }

    switch (variant) {
        case 'info':
            return toast.info(message, config)
        case 'success':
            return toast.success(message, config)
        case 'warning':
            return toast.warning(message, config)
        case 'error':
            return toast.error(message, config)
        default:
            return toast(message, config)
    }
}

