import { useFormikContext } from "formik";
import React from "react";
import { useAxiosPrivate } from "utilities";
import { useQuery } from "react-query";
import { KategoriQnaApi } from "api";
import { DataStatus, Input, InputEditor, Select } from "components";
import SetupQnaApi from "../__SetupQnaApi__";
import { formInitialValues } from "../__SetupQnaUtilites__";

export const FormSection = ({ modal, publicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  const getKategoriQna = useQuery(
    ["qna-atribut-kategori-qna"],
    () => KategoriQnaApi.getKategoriQna(axiosPrivate),
    { enabled: modal.show }
  );

  const getDetail = useQuery(
    ["qna-atribut-detail", publicId],
    () => SetupQnaApi.detail(axiosPrivate, publicId),
    {
      enabled: Boolean(modal.show && modal.type === "update"),
      onSuccess: (data) => {
        setValues(formInitialValues("update", data));
      },
    }
  );

  if (modal.type === "update" && (getDetail.isError || getDetail.isFetching)) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data QnA..."
        text="Data QnA gagal dimuat"
      />
    );
  }

  return (
    <div className="space-y-3">
      <Select
        label="Kategori"
        placeholder="Pilih Kategori"
        name="publicId"
        defaultValue={getKategoriQna?.data?.find(
          (item) => item.value === values?.qnaCategory?.publicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            qnaCategory: {
              ...values.category,
              publicId: val.value,
              name: val.label,
              code: val.code,
            },
          })
        }
        options={getKategoriQna?.data ?? []}
        error={Boolean(
          errors?.qnaCategory?.publicId && touched?.qnaCategory?.publicId
        )}
        errorText={
          Boolean(
            errors?.qnaCategory?.publicId && touched?.qnaCategory?.publicId
          ) && errors?.qnaCategory?.publicId
        }
        errorFetch={getKategoriQna.isError}
        errorFetchText={getKategoriQna?.error?.response?.data?.errorMessage[0]}
        loading={getKategoriQna.isFetching}
      />
      <Input
        label="Judul"
        name="title"
        type="text"
        value={values?.title}
        onChange={handleChange}
        error={Boolean(errors.title && touched.title)}
        errorText={Boolean(errors.title && touched.title) && errors.title}
      />
      <Input
        label="Urutan List"
        name="index"
        type="number"
        value={values?.index}
        onChange={handleChange}
        error={Boolean(errors.index && touched.index)}
        errorText={Boolean(errors.index && touched.index) && errors.index}
      />
      <InputEditor
        label="Konten"
        value={values?.body}
        onChange={(val) => setValues({ ...values, body: val })}
        error={Boolean(errors.body && touched.body)}
        errorText={Boolean(errors.body && touched.body) && errors.body}
      />
    </div>
  );
};
