const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class FileApi {
  async single(axiosPrivate, data) {
    const fetch = await axiosPrivate.post(`${url}/v1/file/upload`, data);
    return fetch.data.payload;
  }

  async multiple(axiosPrivate, data) {
    const fetch = await axiosPrivate.post(
      `${url}/v1/file/multiple-upload`,
      data
    );
    return fetch.data.payload;
  }
}

export default new FileApi();
