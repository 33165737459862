import {
  LabelCustom,
  LabelDanger,
  LabelDefault,
  LabelInfo,
  LabelSuccess,
  LabelWarning,
} from "components";

export const ConvertAbsencePermitFlag = ({ flag, status }) => {
  if (flag === "IZIN") {
    return (
      <>
        <span className="mr-2">
          <LabelWarning text="Izin" />
        </span>
        <span>
          {status === "APPROVED" ? (
            <LabelSuccess text="Disetujui" />
          ) : (
            <LabelDanger text="Tidak Disetujui" />
          )}
        </span>
      </>
    );
  } else if (flag === "MASUK_KELUAR") {
    return (
      <>
        <span className="mr-2">
          <LabelDefault text="Izin Meninggalkan Kelas" />
        </span>
        <span>
          {status === "APPROVED" ? (
            <LabelSuccess text="Disetujui" />
          ) : (
            <LabelDanger text="Tidak Disetujui" />
          )}
        </span>
      </>
    );
  } else if (flag === "IZIN_SAKIT") {
    return (
      <>
        <span className="mr-2">
          <LabelCustom text="Izin Sakit" className="bg-pink-500" />
        </span>
        <span>
          {status === "APPROVED" ? (
            <LabelSuccess text="Disetujui" />
          ) : (
            <LabelDanger text="Tidak Disetujui" />
          )}
        </span>
      </>
    );
  } else if (flag === "IZIN_TERLAMBAT") {
    return (
      <>
        <span className="mr-2">
          <LabelInfo text="Izin Terlambat" />
        </span>
        <span>
          {status === "APPROVED" ? (
            <LabelSuccess text="Disetujui" />
          ) : (
            <LabelDanger text="Tidak Disetujui" />
          )}
        </span>
      </>
    );
  }

  return <></>;
};
