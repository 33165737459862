// <--- React --->
import { useState } from 'react'

// <--- Components --->
import { 
    DeleteButon, EditButton, LabelDanger, LabelSuccess, ListLayout, Modal, ViewButton 
} from 'components';
import { DetailSection, FormSection } from './__GuruComponents__';


// <--- API --->
import GuruApi from './__GuruApi__'

// <--- Utilities --->
import { 
    errorFetching, successFetching, paginationHandler, 
    useAuth, useAxiosPrivate, getDashDMY, mappingDataGender 
} from 'utilities'
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from './__GuruUtilities__';

// <--- Third-Party Library --->
import { useMutation, useQuery } from 'react-query'
import { Formik } from 'formik'

export const Guru = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const permissions = auth.permissions;

    // <--- States --->
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        isActive: true
	})
    const [isActive, setIsActive] = useState('true')
    const [modal, setModal] = useState({
		show: false,
		type: 'create',
        errorText: '',
        error: false,
		data: {}
	})

    // <--- Table's columns --->
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => ((getGuru.data?.pageable?.pageNumber - 1) * getGuru.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'NIP',
            selector: row => row?.nip,
            width: '100px',
            wrap: true
        },
        {
            name: 'Nama Guru',
            selector: row => row?.name,
            wrap: true,
            minWidth: "250px",
        },
        {
            name: 'Kategori Guru',
            selector: row => row?.categoryTeacherName,
            wrap: true,
            width: '200px'
        },
        {
            name: 'Jenis Kelamin',
            selector: row => mappingDataGender(row?.gender),
            width: '120px',
            wrap: true
        },
        {
            name: 'Tanggal Lahir',
            selector: row => row?.dateOfBirth ? getDashDMY(row?.dateOfBirth) : '-',
            wrap: true,
            width: '125px'
        },
        {
            name: 'Email',
            selector: row => row?.email,
            wrap: true,
            width: '200px'
        },
        {
            name: 'Status',
            cell: data => data?.isActive ? <LabelSuccess text='Aktif' /> : <LabelDanger text='Tidak Aktif' />,
            width: '120px',
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('TEACHER_R') && <ViewButton icon noText onClick={() => onReadButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('TEACHER_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('TEACHER_D') && <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />}
            </>,
        },
    ];

    // <--- useQuery --->
    const getGuru = useQuery(
		['guru-list', pagination],
		() => GuruApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => { return [] })
	)
    
    // <--- Functions --->
	const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }

    const activeDataHandler = (e) => {
        if(e.target.name !== 'all'){
            setPagination({
                ...pagination,
                isActive: e.target.name
            })
            setIsActive(e.target.name)
        } else{
            setPagination({
                ...pagination,
                isActive: undefined
            })
            setIsActive('none')
        }
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onReadButtonClickHandler = data => setModal({ show: true, type: 'read', errorText: '', error: false, data: data})

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data})

    // <--- useMutation --->
    const createGuru = useMutation((data) => GuruApi.create(axiosPrivate, data), {
		onSuccess: () => {
			getGuru.refetch()
		}
	})

    const updateGuru = useMutation(({data, publicId}) => GuruApi.update(axiosPrivate, data, publicId), {
        onSuccess: () => {
			getGuru.refetch()
		}
    })

    const deleteGuru = useMutation(id => GuruApi.delete(axiosPrivate, id), {
		onSuccess: () => {
			getGuru.refetch()
		}
	})
	
    return (
        <>
            {/* <--- Search input, Create Button, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                permissionCreate='TEACHER_C'
                data={getGuru.data?.content}
                columns={columns}
                loading={getGuru.isFetching}
                error={getGuru.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getGuru.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
                activeStatusFilter
                activeOnChange={activeDataHandler}
                activeValues={isActive}
            />

            {/* <--- MODAL CREATE ---> */}
            {modal.type === 'create' && modal.show && 
                <Formik
                    enableReinitialize
                    initialValues={formInitialValues('create', {})}
                    validationSchema={formValidationSchema()}
                    onSubmit={(values, { resetForm }) => {
                        const finalValues = formSubmitValueMapper(values)

                        createGuru.mutateAsync(finalValues).then((res) => {
                            resetForm()
                            successFetching(res)
                            onHideModalHandler()
                        }).catch(err => {
                            const errorMessage = errorFetching(err)
                            setModal({
                                ...modal,
                                error: true,
                                errorText: errorMessage
                            })
                        })
                    }}
                >
                    {(formik) => {
                        const { handleSubmit, resetForm } = formik

                        return (
                            <Modal
                                id='modal-tambah-guru'
                                header='Tambah Guru'
                                size='medium'
                                type='create'
                                onHide={() => {
                                    resetForm()
                                    onHideModalHandler()
                                }}
                                show={Boolean(modal.show && modal.type === 'create')}
                                onSubmit={handleSubmit}
                                isSubmitting={createGuru.isLoading}
                            >
                                <FormSection modal={modal} publicId={modal.data.publicId} />
                            </Modal>
                        )
                    }}
                </Formik>
            }

            {/* <--- MODAL UPDATE ---> */}
            {modal.type === 'update' && modal.show &&
                <Formik
                    enableReinitialize
                    validationSchema={formValidationSchema()}
                    onSubmit={(values, { resetForm }) => {
                        const finalValues = formSubmitValueMapper(values)

                        updateGuru.mutateAsync({
                            data: finalValues,
                            publicId: finalValues.publicId
                        }).then((res) => {
                            resetForm()
                            successFetching(res)
                            onHideModalHandler()
                        }).catch(err => {
                            const errorMessage = errorFetching(err)
                            setModal({
                                ...modal,
                                error: true,
                                errorText: errorMessage
                            })
                        })
                    }}
                >
                    {(formik) => {
                        const { handleSubmit } = formik

                        return (
                            <Modal
                                id='modal-ubah-guru'
                                header='Ubah Guru'
                                size='medium'
                                type='update'
                                onHide={onHideModalHandler}
                                show={Boolean(modal.show && modal.type === 'update')}
                                onSubmit={handleSubmit}
                                isSubmitting={updateGuru.isLoading}
                            >
                                <FormSection modal={modal} publicId={modal.data.publicId} />
                            </Modal>
                        )
                    }}
                </Formik>
            }

            {/* <--- MODAL DELETE ---> */}
            {modal.type === 'delete' && modal.show &&
                <Modal
                    id='modal-hapus-guru'
                    header='Hapus Guru'
                    size='medium'
                    type='delete'
                    onHide={onHideModalHandler}
                    show={Boolean(modal.show && modal.type === 'delete')}
                    isSubmitting={deleteGuru.isLoading}
                    onSubmit={() => {
                        deleteGuru.mutateAsync(modal.data.publicId).then((res) => {
                            successFetching(res)
                            onHideModalHandler()
                        }).catch(err => {
                            const errorMessage = errorFetching(err)
                            setModal({
                                ...modal,
                                error: true,
                                errorText: errorMessage
                            })
                        })
                    }}
                >
                    <DetailSection data={modal} />
                </Modal>
            }

            {/* <--- MODAL READ ---> */}
            {modal.type === 'read' && modal.show &&
                <Modal
                    id='modal-detil-guru'
                    header='Detail Guru'
                    size='medium'
                    type='read'
                    onHide={onHideModalHandler}
                    show={Boolean(modal.show && modal.type === 'read')}
                >
                    <DetailSection data={modal} />
                </Modal>
            }
        </>
    )
}