import { PrintLayout } from "components"
import { TablePrint } from "components/Table/TablePrint/TablePrint"
import { forwardRef } from "react"
import { getDashDMY } from "utilities"

const TabelKegiatanSiswa = ({ data }) => {
  const TABLE_HEADER = [
    {text: 'No.', props: {width: '30px', fontSize: '11px'}},
    {text: 'NIS', props: {width: '80px', fontSize: '11px'}},
    {text: 'Nama Siswa', props: {fontSize: '11px'}},
    {text: 'Kelas', props: {width: '60px', fontSize: '11px'}},
    {text: 'Tgl. Kegiatan', props: {width: '90px', fontSize: '11px'}},
    {text: 'Kegiatan', props: {fontSize: '11px'}},
    {text: 'Partisipasi', props: {fontSize: '11px'}},
    {text: 'Jenis Kegiatan', props: {fontSize: '11px'}},
    {text: 'Regional', props: {fontSize: '11px'}}
  ]

  const TABLE_BODY = [
    {field: (_, index) => index + 1, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentNis, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentName, props: {fontSize: '11px'}},
    {field: val => val?.classroomName, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.activityDate ? getDashDMY(val?.activityDate) : '-', props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.name, props: {fontSize: '11px'}},
    {field: val => val?.participationName, props: {fontSize: '11px'}},
    {field: val => val?.achievementName, props: {fontSize: '11px'}},
    {field: val => val?.regional, props: {fontSize: '11px'}}
  ]

  return (
    <div className="mt-5">
      <TablePrint
        data={data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  )
}

const DataFilter = ({ data }) => {
  const styleValue = "text-xs text-black pl-3 h-auto w-auto"
  const styleLabel = "w-32"

  return (
    <div className="mt-5">
      {data?.achievementLibrary !== '' && <div className="flex flex-row">
        <small className={styleLabel}>Jenis Kegiatan</small>
        <small>:</small>
        <strong className={styleValue}>{data?.achievementLibrary === '' ? '-' : data?.achievementLibrary}</strong>
      </div>}
      {data?.achievementRegional !== '' && <div className="flex flex-row">
        <small className={styleLabel}>Regional</small>
        <small>:</small>
        <strong className={styleValue}>{data?.achievementRegional === '' ? '-' : data?.achievementRegional}</strong>
      </div>}
      {data?.achievementParticipation !== '' && <div className="flex flex-row">
        <small className={styleLabel}>Partisipasi</small>
        <small>:</small>
        <strong className={styleValue}>{data?.achievementParticipation === '' ? '-' : data?.achievementParticipation}</strong>
      </div>}
    </div>
  )
}

export const CetakKegiatanSiswa = forwardRef(( {data, filter}, ref ) => {
  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {"\
          @page {\ size: landscape;\ }\
        "}
      </style>
      <PrintLayout>
        <>
          {/* <-- TITLE --> */}
          <div className="text-center">
            <h3 className="font-bold">LAPORAN KEGIATAN SISWA</h3>
            <small className="font-semibold">Tahun Ajaran {filter?.periode}</small>
          </div>

          {/* <-- FILTER --> */}
          <DataFilter data={filter} />

          {/* <-- DAFTAR KEGIATAN SISWA --> */}
          <TabelKegiatanSiswa data={data} />
        </>
      </PrintLayout>
    </div>
  )
})