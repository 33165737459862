import { Input, Label } from 'components'
import { useFormikContext } from 'formik'
import React from 'react'

export const FormSection = ({ modal }) => {
  const { values, errors, touched, handleChange } = useFormikContext()

  return (
    <div className='space-y-3'>
      <Input 
        label='Tahun Ajaran'
        name='periode'
        type='text'
        placeholder='Format: yyyy/yyyy'
        value={values.periode}
        onChange={handleChange}
				error={Boolean(errors.periode && touched.periode)}
				errorText={Boolean(errors.periode && touched.periode) && errors.periode}
      />
      {modal.error && 
        <Label 
          type='error'
          text={modal.errorText}
        />
      }
    </div>
  )
}
