import { Button, Spinner } from "../.."


export const CancelButton = ({
    type,
    className,
    onClick,
    disabled,
    loading,
    text,
}) => {
    return (
        <Button
            type={type}
            className={`${className} ${type === 'read' && 'btn-none'} flex flex-row justify-center items-center p-2`}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
        >
            {loading ? <><Spinner /><span className="ml-2">Proses...</span></> : <span>{text}</span>}
        </Button>
    )
}