import { TablePrint } from 'components';
import React from 'react';
import { getDashDMY } from 'utilities';

export const ExportKegiatanSiswa = ({ data }) => {
  const TABLE_HEADER = [
    {text: 'No.', props: {width: '30px', fontSize: '11px'}},
    {text: 'NIS', props: {width: '80px', fontSize: '11px'}},
    {text: 'Nama Siswa', props: {fontSize: '11px'}},
    {text: 'Kelas', props: {width: '60px', fontSize: '11px'}},
    {text: 'Tgl. Kegiatan', props: {width: '90px', fontSize: '11px'}},
    {text: 'Kegiatan', props: {fontSize: '11px'}},
    {text: 'Partisipasi', props: {fontSize: '11px'}},
    {text: 'Jenis Kegiatan', props: {fontSize: '11px'}},
    {text: 'Regional', props: {fontSize: '11px'}}
  ]

  const TABLE_BODY = [
    {field: (_, index) => index + 1, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentNis, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentName, props: {fontSize: '11px'}},
    {field: val => val?.classroomName, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.activityDate ? getDashDMY(val?.activityDate) : '-', props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.name, props: {fontSize: '11px'}},
    {field: val => val?.participationName, props: {fontSize: '11px'}},
    {field: val => val?.achievementName, props: {fontSize: '11px'}},
    {field: val => val?.regional, props: {fontSize: '11px'}}
  ]

  return (
    <div className="mt-5">
      <TablePrint
        tableId='export-table-kegiatan-siswa'
        data={data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  )
}
