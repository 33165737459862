export const RadioButtonGroup = ({
    id,
    children
}) => {
    return (
        <div 
            role='group'
            aria-labelledby={`${id}-radio-group`}
            className='flex flex-row space-x-5'
        >
            {children}
        </div>
    )
}
