const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class DashboardIzinApi {
  async getListPageable(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/absence-permit/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/absence-permit`, {
      params,
    });
    return fetch.data.payload;
  }

  verifikasi(axiosPrivate, absencePermitPublicId, data) {
    return axiosPrivate.patch(
      `${url}/v1/absence-permit/${absencePermitPublicId}/isApproved`,
      data
    );
  }

  create(axiosPrivate, studentPublicId, data) {
    return axiosPrivate.post(
      `${url}/v1/absence-permit/student/${studentPublicId}`,
      data
    );
  }
}

export default new DashboardIzinApi();
