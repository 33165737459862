import { Input, Label } from "components";
import { useFormikContext } from "formik";
import React from "react";

export const FormSection = ({ modal }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  return (
    <div className="space-y-3">
      <Input
        label="Tanggal"
        name="eventDate"
        type="date"
        value={values.eventDate}
        onChange={handleChange}
        error={Boolean(errors.eventDate && touched.eventDate)}
        errorText={
          Boolean(errors.eventDate && touched.eventDate) && errors.eventDate
        }
      />
      <Input
        label="Keterangan"
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
        errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      {modal.error && <Label type="error" text={modal.errorText} />}
    </div>
  );
};
