export const formSubmitValueMapper = (values, dataImages) => {
  return {
    title: values.title,
    body: values.body,
    categoryPublicId: values.category.publicId,
    isPublish: values.isPublish,
    images: dataImages?.length > 0 ? dataImages?.map((val) => ({
      name: val.name,
      fileId: val.fileId,
      fileUrl: val.fileUrl,
      oldFileId: val?.oldFileId,
      publicId: val?.publicId,
      isDeleted: val?.isDeleted,
    })) : undefined ?? undefined,
  };
};
