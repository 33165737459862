import * as Yup from 'yup'

export const formValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required('Nama Guru wajib diisi'),
        nip: Yup.string()
            .required('NIP wajib diisi'),
        username: Yup.string()
            .required('Username wajib diisi'),
        gender: Yup.string()
            .required('Wajib dipilih')
            .nullable(),
        dateOfBirth: Yup.date()
            .required('Tanggal Lahir wajib diisi'),
        email: Yup.string().email()
            .required('Email wajib diisi (cth. example@email.com)')
            .nullable(),
        street: Yup.string()
            .required('Alamat wajib diisi'),
        teacherCategoryPublicId: Yup.string()
            .required('Kategori Guru wajib diisi'),
        address: Yup.object().shape({
            desaId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            kecamatanId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            kabupatenId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            provinsiId: Yup.string()
                .required('Wajib dipilih')
                .nullable()
        })
    })
}