// <--- Components --->
import { DeleteButon, EditButton, LabelDanger, LabelSuccess, ListLayout, Modal } from "components"
import { DetailSection, FormSection } from "./__TingkatanKelasComponents__";

// <--- Utilities --->
import { errorFetching, paginationHandler, successFetching, useAuth, useAxiosPrivate } from "utilities";

// <--- React --->
import { useState } from "react";

// <--- Third-Party Library --->
import { useMutation, useQuery } from "react-query";
import * as Yup from 'yup'
import { Formik } from "formik";

// <--- API --->
import TingkatanKelasApi from "./__TingkatanKelasApi__";

// <--- Helper --->
import { KATEGORI_GURU_BK } from "helper/PublicIdHelper";

export const TingkatanKelas = () => {
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const permissions = auth.permissions

    // <--- States --->
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        isActive: true
	})
    const [isActive, setIsActive] = useState('true')
    const [modal, setModal] = useState({
		show: false,
		type: 'create',
        errorText: '',
        error: false,
		data: {}
	})
    
    // <--- Table's columns --->
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => ((getTingkatanKelas.data?.pageable?.pageNumber - 1) * getTingkatanKelas.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'Nama',
            selector: row => row.name,
            wrap: true,
            minWidth: '100px'
        },
        {
            name: 'Kode',
            selector: row => row.code,
            wrap: true,
            minWidth: '100px'
        },
        {
            name: 'Guru BK',
            selector: row => row.counseling.name,
            wrap: true,
            minWidth: '300px'
        },
        {
            name: 'Keterangan',
            selector: row => row.description === '' ? '-' : row.description,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Status',
            cell: data => data?.isActive ? <LabelSuccess text='Aktif' /> : <LabelDanger text='Tidak Aktif' />,
            wrap: true,
            width: "120px" 
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('CLASS_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('CLASS_D') && <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />}
            </>,
        }
    ]

    // <--- useQuery --->
    const getTingkatanKelas = useQuery(
        ['tingkatan-kelas-list', pagination],
        () => TingkatanKelasApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => { return [] })
    )

    const getGuru = useQuery(
        ['kelas-atribut-guru'],
        () => TingkatanKelasApi.getGuru(axiosPrivate, {isActive: true, teacherCategoryPublicId: KATEGORI_GURU_BK}),
        {enabled: modal.show}
    )

    // <--- useMutation --->
    const createTingkatanKelas = useMutation((data) => TingkatanKelasApi.create(axiosPrivate, data), {
        onSuccess: () => {
			getTingkatanKelas.refetch()
		}
    })

    const updateTingkatanKelas = useMutation(({data, publicId}) => TingkatanKelasApi.update(axiosPrivate, data, publicId), {
        onSuccess: () => {
			getTingkatanKelas.refetch()
		}
    })

    const deleteTingkatanKelas = useMutation(id => TingkatanKelasApi.delete(axiosPrivate, id), {
		onSuccess: () => {
			getTingkatanKelas.refetch()
		}
	})
    
    // <--- Functions --->
    const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }

    const activeDataHandler = (e) => {
        if(e.target.name !== 'all'){
            setPagination({
                ...pagination,
                isActive: e.target.name
            })
            setIsActive(e.target.name)
        } else{
            setPagination({
                ...pagination,
                isActive: undefined
            })
            setIsActive('none')
        }
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data })

    // <--- Form --->
    const formInitialValues = {
		publicId: modal.type !== 'create' ? modal?.data?.publicId ?? "" : undefined,
        name: modal.type !== 'create' ? modal?.data?.name ?? "" : "",
		description: modal.type !== 'create' ? modal?.data?.description ?? "" : "",
		code: modal.type !== 'create' ? modal?.data?.code ?? "" : "",
		grade: modal.type !== 'create' ? modal?.data?.grade ?? "" : "",
		counselingPublicId: modal.type !== 'create' ? modal?.data?.counseling?.publicId ?? "" : "",
        isActive: modal.type !== 'create' ? modal?.data?.isActive ?? true : true
	}

    const formValidationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Nama wajib diisi'),
        code: Yup.string()
            .required('Kode wajib diisi'),
        grade: Yup.number()
            .required('Tingkatan Kelas wajib diisi')
            .nullable(),
        counselingPublicId: Yup.string()
            .required('Guru BK wajib dipilih')
            .nullable()
	})
    
    return (
        <>
            {/* <--- Search input, Create Button, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                permissionCreate='CLASS_C'
                data={getTingkatanKelas.data?.content}
                columns={columns}
                loading={getTingkatanKelas.isFetching}
                error={getTingkatanKelas.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getTingkatanKelas.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
                activeStatusFilter
                activeOnChange={activeDataHandler}
                activeValues={isActive}
            />

            {/* <--- MODAL CREATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    createTingkatanKelas.mutateAsync(values).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit, resetForm } = formik

					return (
                        <Modal
                            id='modal-tambah-tingkatan-kelas'
                            header='Tambah Tingkatan Kelas'
                            size='small'
                            type='create'
                            onHide={() => {
                                resetForm()
                                onHideModalHandler()
                            }}
                            show={Boolean(modal.show && modal.type === 'create')}
                            onSubmit={handleSubmit}
                            isSubmitting={createTingkatanKelas.isLoading}
                        >
                            <FormSection modal={modal} dropdown={{getGuru}} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL UPDATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    updateTingkatanKelas.mutateAsync({
                        data: values,
                        publicId: values.publicId
                    }).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit } = formik

					return (
                        <Modal
                            id='modal-ubah-tingkatan-kelas'
                            header='Ubah Tingkatan Kelas'
                            size='small'
                            type='update'
                            onHide={onHideModalHandler}
                            show={Boolean(modal.show && modal.type === 'update')}
                            onSubmit={handleSubmit}
                            isSubmitting={updateTingkatanKelas.isLoading}
                        >
                            <FormSection modal={modal} dropdown={{getGuru}} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL DELETE ---> */}
            <Modal
                id='modal-hapus-tingkatan-kelas'
                header='Hapus Tingkatan Kelas'
                size='small'
                type='delete'
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === 'delete')}
                isSubmitting={deleteTingkatanKelas.isLoading}
                onSubmit={() => {
                    deleteTingkatanKelas.mutateAsync(modal.data.publicId).then((res) => {
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                <DetailSection data={modal} />
            </Modal>
        </>
    )
}
