// Components
import { 
    Select,
    Input, 
    Label, 
    RadioButton, 
    RadioButtonGroup, 
    RadioButtonWrapper, 
    TextArea 
} from "components"

// Form
import { useFormikContext } from "formik"

export const FormSection = ({ modal, dropdown }) => {
    // Hooks
    const { values, setValues, errors, touched, handleChange } = useFormikContext()
    
    return (
        <>
            <Select 
                label='Jenis Kegiatan'
                placeholder='Pilih Jenis Kegiatan'
                name='achievementLibraryPublicId'
                defaultValue={dropdown.getJenisKegiatan?.data?.find(item => item.value === values.achievementLibraryPublicId)}
                onChange={val => setValues({...values, achievementLibraryPublicId: val.value})}
                options={dropdown.getJenisKegiatan?.data ?? []}
                error={Boolean(errors.achievementLibraryPublicId && touched.achievementLibraryPublicId)}
                errorText={Boolean(errors.achievementLibraryPublicId && touched.achievementLibraryPublicId) && errors.achievementLibraryPublicId}
                errorFetch={dropdown.getJenisKegiatan.isError}
                errorFetchText={dropdown.getJenisKegiatan?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getJenisKegiatan.isFetching}
            />
            <Select 
                label='Regional'
                placeholder='Pilih Regional'
                name='achievementRegionalPublicId'
                defaultValue={dropdown.getRegional?.data?.find(item => item.value === values.achievementRegionalPublicId)}
                onChange={val => setValues({...values, achievementRegionalPublicId: val.value})}
                options={dropdown.getRegional?.data ?? []}
                error={Boolean(errors.achievementRegionalPublicId && touched.achievementRegionalPublicId)}
                errorText={Boolean(errors.achievementRegionalPublicId && touched.achievementRegionalPublicId) && errors.achievementRegionalPublicId}
                errorFetch={dropdown.getRegional.isError}
                errorFetchText={dropdown.getRegional?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getRegional.isFetching}
            />
            <Select 
                label='Partisipasi'
                placeholder='Pilih Partisipasi'
                name='achievementParticipationPublicId'
                defaultValue={dropdown.getPartisipasi?.data?.find(item => item.value === values.achievementParticipationPublicId)}
                onChange={val => setValues({...values, achievementParticipationPublicId: val.value})}
                options={dropdown.getPartisipasi?.data ?? []}
                error={Boolean(errors.achievementParticipationPublicId && touched.achievementParticipationPublicId)}
                errorText={Boolean(errors.achievementParticipationPublicId && touched.achievementParticipationPublicId) && errors.achievementParticipationPublicId}
                errorFetch={dropdown.getPartisipasi.isError}
                errorFetchText={dropdown.getPartisipasi?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getPartisipasi.isFetching}
            />
            <Input 
                label='Poin Prestasi' 
                name='point'
                type='number'
                value={values.point}
                onChange={handleChange}
				error={Boolean(errors.point && touched.point)}
				errorText={Boolean(errors.point && touched.point) && errors.point}
            />
            <TextArea 
                label='Keterangan (opsional)'
                name='description'
                value={values.description}
                rows={4}
                onChange={handleChange}
				error={Boolean(errors.description && touched.description)}
				errorText={Boolean(errors.description && touched.description) && errors.description}
            />
            <RadioButtonWrapper label='Status'>
                <RadioButtonGroup>
                    <RadioButton 
                        checked={Boolean(values.isActive)}
                        label='Aktif'
                        onChange={() => setValues({...values, isActive: true})}
                    />
                    <RadioButton 
                        checked={Boolean(!values.isActive)}
                        label='Tidak Aktif'
                        onChange={() => setValues({...values, isActive: false})}
                    />
                </RadioButtonGroup>
            </RadioButtonWrapper>
            {modal.error && 
                <Label 
                    type='error'
                    text={modal.errorText}
                />
            }
        </>
    )
}
