import { DeleteButon, EditButton, ListLayout, Modal } from "components";
import React, { useState } from "react";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { useMutation, useQuery } from "react-query";
import KategoriQnaApi from "./__KategoriQnaApi__";
import * as Yup from "yup";
import { Formik } from "formik";
import { DetailSection, FormSection } from "./__KategoriQnaComponents__";

export const KategoriQna = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "index",
  });

  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  const columns = [
    {
      name: "No.",
      selector: (_, index) =>
        (getKategoriQna.data?.pageable?.pageNumber - 1) *
          getKategoriQna.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Urutan List",
      selector: (row) => row.index,
      wrap: true,
      width: "100px",
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kode",
      selector: (row) => row.code,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_QNA_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_QNA_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  const getKategoriQna = useQuery(["kategori-qna-list", pagination], () =>
    KategoriQnaApi.getList(axiosPrivate, paginationHandler(pagination)).catch(
      () => {
        return [];
      }
    )
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const formInitialValues = {
    publicId: modal.type !== "create" ? modal?.data?.publicId ?? "" : undefined,
    name: modal.type !== "create" ? modal?.data?.name ?? "" : "",
    code: modal.type !== "create" ? modal?.data?.code ?? "" : "",
    index: modal.type !== "create" ? modal?.data?.index : 0 ?? 0,
  };

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required("Nama Kategori QnA wajib diisi"),
    code: Yup.string().required("Kode Kategori QnA wajib diisi"),
    index: Yup.number().required("Urutan List wajib diisi"),
  });

  const createKategoriQna = useMutation(
    (data) => KategoriQnaApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getKategoriQna.refetch();
      },
    }
  );

  const updateKategoriQna = useMutation(
    ({ data, publicId }) => KategoriQnaApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getKategoriQna.refetch();
      },
    }
  );

  const deleteKategoriQna = useMutation(
    (id) => KategoriQnaApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getKategoriQna.refetch();
      },
    }
  );

  return (
    <>
      <ListLayout
        permissions={permissions}
        permissionCreate="SCH_QNA_C"
        data={getKategoriQna.data?.content}
        columns={columns}
        loading={getKategoriQna.isFetching}
        error={getKategoriQna.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getKategoriQna.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
      />

      {/* <--- MODAL CREATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createKategoriQna
            .mutateAsync(values)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;

          return (
            <Modal
              id="modal-tambah-kategori-qna"
              header="Tambah Kategori QnA"
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "create")}
              onSubmit={handleSubmit}
              isSubmitting={createKategoriQna.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL UPDATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          updateKategoriQna
            .mutateAsync({
              data: values,
              publicId: values.publicId,
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-ubah-kategori-qna"
              header="Ubah Kategori QnA"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "update")}
              onSubmit={handleSubmit}
              isSubmitting={updateKategoriQna.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL DELETE ---> */}
      <Modal
        id="modal-hapus-kategori-qna"
        header="Hapus Kategori QnA"
        size="small"
        type="delete"
        onHide={onHideModalHandler}
        show={Boolean(modal.show && modal.type === "delete")}
        isSubmitting={deleteKategoriQna.isLoading}
        onSubmit={() => {
          deleteKategoriQna
            .mutateAsync(modal.data.publicId)
            .then((res) => {
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        <DetailSection data={modal} />
      </Modal>
    </>
  );
};
