import { 
  LabelDanger, 
  LabelDefault,
  LabelSuccess, 
  LabelWarning 
} from "components"

export const statusConvertPrestasi = (status) => {
  switch (status) {
    case 1: return <LabelDefault text='PENDING' />
    case 2: return <LabelSuccess text='APPROVED' />
    case 3: return <LabelWarning text='REVISION' />
    case 4: return <LabelDanger text='REJECTED' />
    default: return <>-</>
  }
} 