import { mappingDataOptions } from "utilities";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class StudentApi {
  async getTeachers(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/teachers`, { params });
    return mappingDataOptions(fetch.data.payload);
  }

  async getDetailTeacher(axiosPrivate) {
    const fetch = await axiosPrivate.get(`${url}/v1/teachers/use-auth`);
    return fetch.data.payload;
  }
}

export default new StudentApi();
