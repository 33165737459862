import React, { useState } from "react";
import {
  DataStatus,
  Input,
  Label,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
} from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PenggunaApi from "../__PenggunaApi__";

export const FormSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = modal?.data;
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  // <--- useQuery --->
  const getDetail = useQuery(
    [`detail-pengguna-${publicId}`, "update"],
    () => PenggunaApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(modal?.data?.publicId) }
  );

  if (getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data pengguna"
        text={getDetail.error?.response?.data?.errorMessage[0]}
      />
    );
  } else {
    return (
      <>
        <Input
          label="Nama Pengguna"
          name="name"
          type="text"
          value={values?.name}
          onChange={handleChange}
          error={Boolean(errors.name && touched.name)}
          errorText={Boolean(errors.name && touched.name) && errors.name}
        />
        <Input
          label="Username"
          name="username"
          type="text"
          value={values?.username}
          onChange={handleChange}
          error={Boolean(errors.username && touched.username)}
          errorText={
            Boolean(errors.username && touched.username) && errors.username
          }
        />
        <Input
          label="Email"
          name="email"
          type="email"
          placeholder="Cth. example@email.com"
          value={values?.email}
          onChange={handleChange}
          error={Boolean(errors.email && touched.email)}
          errorText={Boolean(errors.email && touched.email) && errors.email}
        />
        <RadioButtonWrapper label="Status">
          <RadioButtonGroup>
            <RadioButton
              checked={Boolean(!values?.isBlocked)}
              label="Aktif"
              onChange={() => setValues({ ...values, isBlocked: false })}
            />
            <RadioButton
              checked={Boolean(values?.isBlocked)}
              label="Blokir"
              onChange={() => setValues({ ...values, isBlocked: true })}
            />
          </RadioButtonGroup>
        </RadioButtonWrapper>
        {modal.error && <Label type="error" text={modal.errorText} />}
      </>
    );
  }
};

export const FormResetPasswordSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = modal?.data;
  const { values, errors, touched, handleChange } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);

  // <--- useQuery --->
  const getDetail = useQuery(
    [`detail-pengguna-${publicId}`, "reset-password"],
    () => PenggunaApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(modal?.data?.publicId) }
  );

  if (getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data pengguna"
        text={getDetail.error?.response?.data?.errorMessage[0]}
      />
    );
  } else {
    return (
      <div className="space-y-3">
        <Input label="Nama Pengguna" disabled value={modal?.data?.name} />
        <Input label="Username" disabled value={modal?.data?.username} />
        <div className="flex">
          <Input
            label="Password"
            name="password"
            wrapperClassName="flex-1"
            type={showPassword ? "text" : "password"}
            value={values?.password}
            onChange={handleChange}
            autoComplete="off"
            error={Boolean(errors.password && touched.password)}
            errorText={
              Boolean(errors.password && touched.password) && errors.password
            }
          />
          <div className="grid content-center h-[50px] mt-4 pl-2">
            <div
              className="hover:cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <FaEyeSlash className={`h-4 w-4`} />
              ) : (
                <FaEye className={`h-4 w-4`} />
              )}
            </div>
          </div>
        </div>
        {modal.error && <Label type="error" text={modal.errorText} />}
      </div>
    );
  }
};
