import {
  FaClock,
  FaExpeditedssl,
  FaKey,
  FaRegQuestionCircle,
  FaSchool,
} from "react-icons/fa";

export const PengaturanMenus = [
  {
    name: "Profil Sekolah",
    link: "/pengaturan/profil-sekolah",
    arrayLink: ["/pengaturan/profil-sekolah", "/pengaturan", "/pengaturan/"],
    key: "profil-sekolah",
    icon: <FaSchool />,
  },
  {
    name: "Batas Jam Masuk",
    link: "/pengaturan/batas-jam-masuk",
    arrayLink: ["/pengaturan/batas-jam-masuk"],
    key: "pengaturan-batas-jam-masuk",
    icon: <FaClock />,
  },
  {
    name: "Setup QnA",
    link: "/pengaturan/setup-qna",
    arrayLink: ["/pengaturan/setup-qna"],
    key: "pengaturan-setup-qna",
    icon: <FaRegQuestionCircle />,
  },
  {
    name: "Setup Kebijakan Privasi",
    link: "/pengaturan/setup-kebijakan-privasi",
    arrayLink: ["/pengaturan/setup-kebijakan-privasi"],
    key: "pengaturan-setup-kebijakan-privasi",
    icon: <FaExpeditedssl />,
  },
  {
    name: "Ubah Password",
    link: "/pengaturan/ubah-password",
    arrayLink: ["/pengaturan/ubah-password"],
    key: "ubah-password",
    icon: <FaKey />,
  },
];
