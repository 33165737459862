import { Label, RowDetailLayout } from 'components'
import React from 'react'

export const DetailSection = ({ data }) => {
  return (
    <>
      <RowDetailLayout label="Nama Kategori QnA" text={data.data.name} />
      <RowDetailLayout label="Kode Kategori QnA" text={data.data.code} />
      {data.error && <Label type="error" text={data.errorText} />}
    </>
  )
}
