import { AUTH_URL } from "../../../helper/UrlHelper";
import { axiosPublic } from "../../../services";

class LoginApi{
    login(data){
        /*
        Fungsi ini menggunakan method POST dimana body berisi username dan password.
        Balikan dari fungsi ini berupa json yang memiliki key access_token dan refresh_token.
        */
        return axiosPublic.post(`${AUTH_URL}/v1/login`, data, {
                withCredentials: true
            }
        );
    }
}

export default new LoginApi()