import { FaTrashAlt } from "react-icons/fa";
import { Button, Spinner } from "../..";

export const DeleteButon = ({
    type,
    onClick,
    disabled,
    loading,
    icon,
    noText,
    className,
    text = "Hapus"
}) => {
    return (
        <Button
            type={type}
            className={`btn-danger flex flex-row justify-center items-center p-2 ${noText ?? 'px-5'} ${className}`}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
        >
            {loading ? <><Spinner />{noText ?? <span className="ml-2">Proses...</span>}</> : <>{icon && <FaTrashAlt className={`${noText ?? "mr-2"}`} />}{noText ?? <span>{text}</span>}</>}
        </Button>
    )
}