import React from "react";
import { Input, Label } from "components";
import { useFormikContext } from "formik";

export const FormSection = ({ modal }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  return (
    <>
      <Input
        label="Alasan Terlambat"
        name="value"
        type="text"
        value={values.value}
        onChange={handleChange}
        error={Boolean(errors.value && touched.value)}
        errorText={
          Boolean(errors.value && touched.value) &&
          errors.value
        }
      />
      {modal.error && <Label type="error" text={modal.errorText} />}
    </>
  );
};
