const dateConvert = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return {
    year: String(year),
    month: String(month),
    day: String(day),
  };
};
const timeConvert = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return {
    hours: String(hours),
    minutes: String(minutes),
    seconds: String(seconds),
  };
};

export const getDay = (day) => {
  switch (day) {
    case 0:
      return "Minggu";
    case 1:
      return "Senin";
    case 2:
      return "Selasa";
    case 3:
      return "Rabu";
    case 4:
      return "Kamis";
    case 5:
      return "Jumat";
    case 6:
      return "Sabtu";
    default:
      return "";
  }
};

export const getMonth = (month) => {
  switch (month) {
    case 0:
      return "Januari";
    case 1:
      return "Februari";
    case 2:
      return "Maret";
    case 3:
      return "April";
    case 4:
      return "Mei";
    case 5:
      return "Juni";
    case 6:
      return "Juli";
    case 7:
      return "Agustus";
    case 8:
      return "September";
    case 9:
      return "Oktober";
    case 10:
      return "November";
    case 11:
      return "Desember";
    default:
      return "";
  }
};

export const getDashDMY = (date) => {
  try {
    const result = dateConvert(date);
    return `${result.day}-${result.month}-${result.year}`;
  } catch (e) {
    try {
      return date.split("-").reverse().join("-");
    } catch (er) {
      return "-";
    }
  }
};

export const getDashDMYTime = (date) => {
  const dateSplit = date.substr(0, 10).split("-");
  const time = date.substr(11);
  return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]} ${time}`;
};

export const getDashYMD = (date) => {
  try {
    const result = dateConvert(date);
    return `${result.year}-${result.month}-${result.day}`;
  } catch (e) {
    try {
      return date.split("-").reverse().join("-");
    } catch (er) {
      return "-";
    }
  }
};

export const getFullDate = (date) => {
  const newDay = getDay(date.getDay());
  const newDate = date.getDate();
  const newMonth = getMonth(date.getMonth());
  const newYear = date.getFullYear();

  return `${newDay}, ${newDate} ${newMonth} ${newYear}`;
};

export const getTime = (date) => {
  const result = timeConvert(date);
  return `${result.hours}:${result.minutes}:${result.seconds}`;
};

export const convertForDatabase = (sDate) => {
  const date = new Date(sDate);
  return `${getDashYMD(date)}T${getTime(date)}`;
};
