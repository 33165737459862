import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

export const Select = ({
    label,
    name,
    defaultValue,
    loading,
    error,
    errorText,
    errorFetch,
    errorFetchText,
    onChange,
    options,
    placeholder,
    disable
}) => {
    const [render, setRender] = useState(undefined)

    const forceRender = () => {
        setTimeout(() => {
        setRender(Math.random() * 10)
        }, 10)
    }

    useEffect(() => {
        forceRender()
        
        return () => {
          setRender(undefined)
        }
    }, [defaultValue?.value, defaultValue?.label, loading, errorFetch])
      
    return (
        <div>
            <small>
                {label}
            </small>
            <ReactSelect 
                key={render}
                placeholder={loading ? 'Memuat data...' : errorFetch ? errorFetchText ?? 'Gagal memuat data' : placeholder}
                name={name}
                className='text-sm'
                options={options}
                onChange={onChange}
                defaultValue={loading || errorFetch ? '' : defaultValue}
                isDisabled={loading || errorFetch ? true : disable}
                noOptionsMessage={errorFetchText}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
            />
            {error && <div>
                <small className='validation-error-text'>
                    {errorText}
                </small>
            </div>}
        </div>
    )
}