const { axiosPublic } = require("services");

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class PrivacyPolicyApi {
  async getCurrent() {
    const fetch = await axiosPublic.get(`${url}/v1/privacy-policy/current`);
    return fetch.data.payload;
  }
}

export default new PrivacyPolicyApi();
