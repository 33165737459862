import * as Yup from 'yup'

export const formValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required('Nama Pengguna wajib diisi'),
        username: Yup.string()
            .required('Username wajib diisi'),
        email: Yup.string().email()
            .required('Email wajib diisi (cth. example@email.com)')
            .nullable()
    })
}

export const formResetPasswordValidationSchema = () => {
    return Yup.object().shape({
        password: Yup.string()
            .required('Password wajib diisi')
    })
}