// Components
import {
  DataStatus,
  Input,
  Label,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
  Select,
  Tabs,
  TextArea,
} from "components";

// Form
import { useFormikContext } from "formik";

// Third-Party Library
import { useQuery } from "react-query";

// Utilities
import { useAuth, useAxiosPrivate } from "utilities";
import { formInitialValues } from "../__GuruUtilities__";

// API
import GuruApi from "../__GuruApi__";
import { DropdownApi, RegionalApi, UsersApi } from "api";

// React
import { useEffect, useState } from "react";

const TabInformasiUmum = ({ modal, userPublicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();
  const params = {
    except: userPublicId,
  };

  // <--- useQuery --
  const getKategoriGuru = useQuery(
    ["guru-atribut-kategori-guru"],
    () => GuruApi.getKategoriGuru(axiosPrivate, { isActive: true }),
    {
      enabled: Boolean(
        modal.show &&
          ((permissions.includes("SCH_SA_SUPER_ADMIN") &&
            modal.type === "update") ||
            modal.type === "create")
      ),
    }
  );

  const getGender = useQuery(
    ["kesiswaan-atribut-gender"],
    () => DropdownApi.getGender(),
    { enabled: modal.show }
  );

  const isUsernameAvailable = useQuery(
    ["is-username-available", "guru", values.username],
    () => UsersApi.isUsernameAvailable(axiosPrivate, values.username, params),
    { enabled: modal.show && values.username !== "" }
  );

  const isEmailAvailable = useQuery(
    ["is-email-available", "guru", values.email],
    () => UsersApi.isEmailAvailable(axiosPrivate, values.email, params),
    { enabled: modal.show && values.email !== "" }
  );

  return (
    <>
      {(permissions.includes("SCH_SA_SUPER_ADMIN") &&
        modal.type === "update") ||
      modal.type === "create" ? (
        <Select
          label="Kategori Guru"
          placeholder="Pilih Kategori Guru"
          name="teacherCategoryPublicId"
          defaultValue={getKategoriGuru?.data?.find(
            (item) => item.value === values?.teacherCategoryPublicId
          )}
          onChange={(val) =>
            setValues({ ...values, teacherCategoryPublicId: val.value })
          }
          options={getKategoriGuru?.data ?? []}
          error={Boolean(
            errors?.teacherCategoryPublicId && touched?.teacherCategoryPublicId
          )}
          errorText={
            Boolean(
              errors?.teacherCategoryPublicId &&
                touched?.teacherCategoryPublicId
            ) && errors?.teacherCategoryPublicId
          }
          errorFetch={getKategoriGuru.isError}
          errorFetchText={
            getKategoriGuru?.error?.response?.data?.errorMessage[0]
          }
          loading={getKategoriGuru.isFetching}
        />
      ) : (
        <Input
          label="Kategori Guru"
          type="text"
          value={values?.teacherCategoryName}
          disabled
        />
      )}
      <Input
        label="Nama Guru"
        name="name"
        type="text"
        value={values?.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
        errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      <Input
        label="NIP (Nomor Induk Pegawai)"
        name="nip"
        type="text"
        value={values?.nip}
        onChange={handleChange}
        error={Boolean(errors.nip && touched.nip)}
        errorText={Boolean(errors.nip && touched.nip) && errors.nip}
      />
      <Input
        label="Username"
        name="username"
        type="text"
        value={values?.username}
        onChange={(e) => {
          const { value } = e.target;
          setValues({
            ...values,
            username: value,
          });
          isUsernameAvailable.refetch();
          setValues({
            ...values,
            username: value,
            isUsernameAvailable: isUsernameAvailable.data,
          });
        }}
        error={Boolean(errors.username && touched.username)}
        errorText={
          Boolean(errors.username && touched.username) && errors.username
        }
        showCheck={true}
        loadingCheck={isUsernameAvailable.isFetching}
        isAvailable={isUsernameAvailable.data}
      />
      <Select
        label="Jenis Kelamin"
        placeholder="Pilih Jenis Kelamin"
        name="gender"
        defaultValue={getGender?.data?.find(
          (item) => item.value === values?.gender
        )}
        onChange={(val) =>
          setValues({
            ...values,
            gender: val.value,
          })
        }
        options={getGender?.data ?? []}
        error={Boolean(errors?.gender && touched?.gender)}
        errorText={Boolean(errors?.gender && touched?.gender) && errors?.gender}
        errorFetch={getGender.isError}
        errorFetchText={getGender?.error?.response?.data?.errorMessage[0]}
        loading={getGender.isFetching}
      />
      <Input
        label="Tanggal Lahir"
        name="dateOfBirth"
        type="date"
        value={values?.dateOfBirth}
        onChange={handleChange}
        error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
        errorText={
          Boolean(errors.dateOfBirth && touched.dateOfBirth) &&
          errors.dateOfBirth
        }
      />
      <Input
        label="Email"
        name="email"
        type="email"
        placeholder="Cth. example@email.com"
        value={values?.email}
        onChange={(e) => {
          const { value } = e.target;
          setValues({
            ...values,
            email: value,
          });
          isEmailAvailable.refetch();
          setValues({
            ...values,
            email: value,
            isEmailAvailable: isEmailAvailable.data,
          });
        }}
        error={Boolean(errors.email && touched.email)}
        errorText={Boolean(errors.email && touched.email) && errors.email}
        showCheck={true}
        loadingCheck={isEmailAvailable.isFetching}
        isAvailable={isEmailAvailable.data}
      />
      <RadioButtonWrapper label="Status">
        <RadioButtonGroup>
          <RadioButton
            checked={Boolean(values?.isActive)}
            label="Aktif"
            onChange={() => setValues({ ...values, isActive: true })}
          />
          <RadioButton
            checked={Boolean(!values?.isActive)}
            label="Tidak Aktif"
            onChange={() => setValues({ ...values, isActive: false })}
          />
        </RadioButtonGroup>
      </RadioButtonWrapper>
      {modal.error && <Label type="error" text={modal.errorText} />}
    </>
  );
};

const TabAlamat = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  // <--- useQuery --
  const getProvinsi = useQuery(
    ["guru-atribut-provinsi"],
    () => RegionalApi.getProvinsi(axiosPrivate),
    { enabled: modal.show }
  );

  const getKabupaten = useQuery(
    ["guru-atribut-kabupaten", values?.address?.provinsiId],
    () =>
      RegionalApi.getKabupaten(axiosPrivate, {
        provinsiId: values?.address?.provinsiId,
      }),
    { enabled: Boolean(values?.address?.provinsiId) }
  );

  const getKecamatan = useQuery(
    ["guru-atribut-kecamatan", values?.address?.kabupatenId],
    () =>
      RegionalApi.getKecamatan(axiosPrivate, {
        kabupatenId: values?.address?.kabupatenId,
      }),
    { enabled: Boolean(values?.address?.kabupatenId) }
  );

  const getDesa = useQuery(
    ["guru-atribut-desa", values?.address?.kecamatanId],
    () =>
      RegionalApi.getDesa(axiosPrivate, {
        kecamatanId: values?.address?.kecamatanId,
      }),
    { enabled: Boolean(values?.address?.kecamatanId) }
  );

  return (
    <>
      <Select
        label="Provinsi"
        placeholder="Pilih Provinsi"
        name="provinsiId"
        defaultValue={getProvinsi?.data?.find(
          (item) => item.value === values?.address?.provinsiId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              provinsiId: val.value,
              kabupatenId: "",
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getProvinsi?.data ?? []}
        error={Boolean(
          errors?.address?.provinsiId && touched?.address?.provinsiId
        )}
        errorText={
          Boolean(
            errors?.address?.provinsiId && touched?.address?.provinsiId
          ) && errors?.address?.provinsiId
        }
        errorFetch={getProvinsi.isError}
        errorFetchText={getProvinsi?.error?.response?.data?.errorMessage[0]}
        loading={getProvinsi.isFetching}
      />
      <Select
        label="Kabupaten"
        placeholder="Pilih Kabupaten"
        name="kabupatenId"
        defaultValue={getKabupaten?.data?.find(
          (item) => item.value === values?.address?.kabupatenId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kabupatenId: val.value,
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getKabupaten?.data ?? []}
        error={Boolean(
          errors?.address?.kabupatenId && touched?.address?.kabupatenId
        )}
        errorText={
          Boolean(
            errors?.address?.kabupatenId && touched?.address?.kabupatenId
          ) && errors?.address?.kabupatenId
        }
        errorFetch={getKabupaten.isError}
        errorFetchText={getKabupaten?.error?.response?.data?.errorMessage[0]}
        loading={getKabupaten.isFetching}
        disable={!Boolean(values?.address?.provinsiId)}
      />
      <Select
        label="Kecamatan"
        placeholder="Pilih Kecamatan"
        name="kecamatanId"
        defaultValue={getKecamatan?.data?.find(
          (item) => item.value === values?.address?.kecamatanId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kecamatanId: val.value,
              desaId: "",
            },
          })
        }
        options={getKecamatan?.data ?? []}
        error={Boolean(
          errors?.address?.kecamatanId && touched?.address?.kecamatanId
        )}
        errorText={
          Boolean(
            errors?.address?.kecamatanId && touched?.address?.kecamatanId
          ) && errors?.address?.kecamatanId
        }
        errorFetch={getKecamatan.isError}
        errorFetchText={getKecamatan?.error?.response?.data?.errorMessage[0]}
        loading={getKecamatan.isFetching}
        disable={!Boolean(values?.address?.kabupatenId)}
      />
      <Select
        label="Desa"
        placeholder="Pilih Desa"
        name="desaId"
        defaultValue={getDesa?.data?.find(
          (item) => item.value === values?.address?.desaId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: { ...values.address, desaId: val.value },
          })
        }
        options={getDesa?.data ?? []}
        error={Boolean(errors?.address?.desaId && touched?.address?.desaId)}
        errorText={
          Boolean(errors?.address?.desaId && touched?.address?.desaId) &&
          errors?.address?.desaId
        }
        errorFetch={getDesa.isError}
        errorFetchText={getDesa?.error?.response?.data?.errorMessage[0]}
        loading={getDesa.isFetching}
        disable={!Boolean(values?.address?.kecamatanId)}
      />
      <TextArea
        label="Alamat"
        name="street"
        value={values?.street}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.street && touched.street)}
        errorText={Boolean(errors.street && touched.street) && errors.street}
      />
    </>
  );
};

const TabSection = ({ modal, userPublicId }) => {
  const keyInformasiUmum = "informasi_umum";
  const keyAlamat = "alamat";

  return (
    <Tabs
      data={[
        {
          title: "Informasi Umum",
          component: (
            <TabInformasiUmum modal={modal} userPublicId={userPublicId} />
          ),
        },
        {
          title: "Alamat",
          component: <TabAlamat modal={modal} />,
        },
      ]}
    />
  );
};

export const FormSection = ({ modal, publicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { setValues, resetForm } = useFormikContext();
  const [userPublicId, setUserPublicId] = useState("");

  useEffect(() => {
    if (modal.show && modal.type === "create") {
      resetForm();
    }
  }, [modal.show]);

  const getDetail = useQuery(
    ["guru-atribut-detail", publicId],
    () => GuruApi.detail(axiosPrivate, publicId),
    {
      enabled: Boolean(modal.show && modal.type === "update"),
      onSuccess: (data) => {
        setValues(formInitialValues("update", data));
        setUserPublicId(data?.user?.publicId);
      },
    }
  );

  if (modal.type === "create") {
    return <TabSection modal={modal} />;
  } else {
    if (getDetail.isError || getDetail.isFetching) {
      return (
        <DataStatus
          loading={getDetail.isFetching}
          loadingText="Memuat data guru..."
          text="Data guru gagal dimuat"
        />
      );
    } else {
      return <TabSection modal={modal} userPublicId={userPublicId} />;
    }
  }
};
