const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KegiatanSiswaApi {
  async getListPrestasiPageable(axiosPrivate, params){
    const fetch = await axiosPrivate.get(`${url}/v1/students/achievements/pageable`, { params })
    return fetch.data.payload
  }

  async getListPrestasi(axiosPrivate, params){
    const fetch = await axiosPrivate.get(`${url}/v1/students/achievements`, { params })
    return fetch.data.payload
  }
}

export default new KegiatanSiswaApi()