import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Accordion, DataStatus } from "components";
import { PrivacyPolicyApi } from "api";

export const KebijakanPrivasiPublicPage = () => {
  const navigate = useNavigate();
  const getCurrent = useQuery(["kebijakan-privasi", "current"], () =>
    PrivacyPolicyApi.getCurrent()
  );

  if (getCurrent.isError || getCurrent.isFetching) {
    return (
      <>
        <div>
          <label
            className="font-normal underline hover:cursor-pointer hover:text-clobasoft-dark-blue"
            onClick={() => navigate(-1)}
          >
            Kembali
          </label>
        </div>
        <DataStatus
          loading={getCurrent.isFetching}
          loadingText="Memuat data kebijakan privasi..."
          text="Data kebijakan privasi gagal dimuat"
        />
      </>
    );
  }

  return (
    <div>
      <label
        className="font-normal underline hover:cursor-pointer hover:text-clobasoft-dark-blue"
        onClick={() => navigate(-1)}
      >
        Kembali
      </label>
      <div className="mt-5">
        <Accordion data={getCurrent?.data} />
      </div>
    </div>
  );
};
