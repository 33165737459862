import { PrintLayout, TablePrint } from "components";
import { forwardRef } from "react";
import { getDashDMY } from "utilities";

const TabelPelanggaranSiswa = ({ data }) => {
  const TABLE_HEADER = [
    {text: 'No.', props: {width: '30px', fontSize: '11px'}},
    {text: 'NIS', props: {width: '80px', fontSize: '11px'}},
    {text: 'Nama Siswa', props: {fontSize: '11px'}},
    {text: 'Kelas', props: {width: '60px', fontSize: '11px'}},
    {text: 'Tgl. Pelanggaran', props: {width: '100px', fontSize: '11px'}},
    {text: 'Pelanggaran', props: {fontSize: '11px'}},
    {text: 'Keterangan', props: {fontSize: '11px'}}
  ]

  const TABLE_BODY = [
    {field: (_, index) => index + 1, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentNis, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.studentName, props: {fontSize: '11px'}},
    {field: val => val?.classroomName, props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.activityDate ? getDashDMY(val?.activityDate) : '-', props: {textAlign: 'center', fontSize: '11px'}},
    {field: val => val?.violationName, props: {fontSize: '11px'}},
    {field: val => val?.description, props: {fontSize: '11px'}}
  ]

  return (
    <div className="mt-5">
      <TablePrint
        data={data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  )
}

const DataFilter = ({ data }) => {
  const styleValue = "text-xs text-black pl-3 h-auto w-auto"
  const styleLabel = "w-32"

  return (
    <div className="mt-5">
      <div className="flex flex-row">
        <small className={styleLabel}>Tanggal Awal</small>
        <small>:</small>
        <strong className={styleValue}>{getDashDMY(data?.startDate) ?? '-'}</strong>
      </div>
      <div className="flex flex-row">
        <small className={styleLabel}>Tanggal Akhir</small>
        <small>:</small>
        <strong className={styleValue}>{getDashDMY(data?.endDate) ?? '-'}</strong>
      </div>
    </div>
  )
}

export const CetakPelanggaranSiswa = forwardRef(( {data, filter}, ref) => {
  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {"\
          @page {\ size: landscape;\ }\
        "}
      </style>
      <PrintLayout>
        <>
          {/* <-- TITLE --> */}
          <div className="text-center">
            <h3 className="font-bold">LAPORAN PELANGGARAN SISWA</h3>
          </div>

          {/* <-- FILTER --> */}
          <DataFilter data={filter} />

          {/* <-- DAFTAR PELANGGARAN SISWA --> */}
          <TabelPelanggaranSiswa data={data} />
        </>
      </PrintLayout>
    </div>
  )
})
