import * as Yup from 'yup'

export const formValidationPrestasiSchema = () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Deskripsi wajib diisi'),
    status: Yup.string()
      .required('Wajib dipilih')
      .nullable()
  })
}