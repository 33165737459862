export const generateBodyTablePresensiBulanan = (listDate) => {
  const convertFlag = (flag) => {
    switch (flag) {
      case "MASUK":
        return <div className="text-green-500">M</div>;
      case "TERLAMBAT":
        return <div className="text-red-500">T</div>;
      case "IZIN_SAKIT":
        return <div className="bg-pink-200 text-center font-bold">S</div>;
      case "IZIN":
        return <div className="bg-amber-200 text-center font-bold">I</div>;
      case "MASUK_KELUAR":
        return <div className="bg-gray-200 text-center font-bold">IM</div>;
      case "IZIN_TERLAMBAT":
        return <div className="bg-blue-200 text-center font-bold">IT</div>;
      default:
        return <div>-</div>;
    }
  };

  const generalBody = [
    {
      field: (_, index) => index + 1,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.studentNis,
      props: { textAlign: "center", fontSize: "10px" },
    },
    { field: (val) => val?.studentName, props: { fontSize: "10px" } },
  ];

  const presensiBody = () => {
    const tampArray = [];

    listDate.forEach((data) =>
      tampArray.push({
        field: (val) =>
          val.date
            ?.filter((value) => value.date.toString() === data.date.toString())
            ?.map((value) =>
              value.attendance ? (
                convertFlag(value.attendance.flag)
              ) : value.isHoliday || value.isWeekend ? (
                <div className="text-red-600 font-semibold">L</div>
              ) : (
                "-"
              )
            ),
        props: { textAlign: "center", fontSize: "9px" },
      })
    );

    return tampArray;
  };

  const totalBody = [
    {
      field: (val) => val?.totalEffectiveDays,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.totalMasuk,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.totalTerlambat,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.totalIzin,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.totalIzinSakit,
      props: { textAlign: "center", fontSize: "10px" },
    },
    {
      field: (val) => val?.totalAlpha,
      props: { textAlign: "center", fontSize: "10px" },
    },
  ];

  return generalBody.concat(presensiBody()).concat(totalBody);
};
