import React from "react";
import { useFormikContext } from "formik";
import { DatePicker } from "components";

export const FormSection = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  
  return (
    <div className="space-y-3">
      <DatePicker
        label="Batas Jam Masuk"
        selected={values?.maxTime}
        onChangeDefault={(date) => setFieldValue("maxTime", date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        dateFormat="HH:mm"
        timeCaption="Jam"
        error={Boolean(errors.maxTime && touched.maxTime)}
        errorText={Boolean(errors.maxTime && touched.maxTime) && errors.maxTime}
      />
      <DatePicker
        label="Tanggal dan Jam Berlaku"
        selected={values?.effectiveAt}
        onChangeDefault={(date) => setFieldValue("effectiveAt", date)}
        showTimeSelect
        timeIntervals={1}
        dateFormat="dd-MM-yyyy HH:mm"
        timeCaption="Jam"
        error={Boolean(errors.effectiveAt && touched.effectiveAt)}
        errorText={
          Boolean(errors.effectiveAt && touched.effectiveAt) &&
          errors.effectiveAt
        }
      />
    </div>
  );
};
