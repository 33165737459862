import React from "react";
import { default as ReactDatePicker } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export const DatePicker = ({
  label,
  selected,
  dateFormat,
  onChange,
  onChangeDefault,
  className,
  wrapperClassName,
  error,
  errorText,
  valid,
  validText,
  ...props
}) => {
  const checkSelected = selected ? new Date(selected) : null;
  
  const formatDate = (date) => {
    const padTo2Digits = (num) => {
      return num.toString().padStart(2, "0");
    };

    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  return (
    <div className={wrapperClassName}>
      <small>{label}</small>
      <div>
        <ReactDatePicker
          {...props}
          dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
          onChange={(date) => {
            const newDate = formatDate(date ?? new Date());

            return onChangeDefault
              ? onChangeDefault(date)
              : onChange && onChange(newDate);
          }}
          selected={checkSelected}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className={`${className} rounded-md text-sm border-gray-300 h-[34px]`}
        />
      </div>
      {error && (
        <div>
          <small className="validation-error-text">{errorText}</small>
        </div>
      )}
      {valid && (
        <div>
          <small className="validation-success-text">{validText}</small>
        </div>
      )}
    </div>
  );
};
