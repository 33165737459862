import image from '../../assets/image/404.jpg'
import { ViewButton } from 'components'
import { useNavigate } from 'react-router-dom'

export const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <>
      <img
        className="mx-auto w-96"
        src={image}
        alt="Workflow"
      />
      <div className='w-100 flex justify-center'>
        <ViewButton text='Kembali ke Beranda' onClick={() => navigate('/')} />
      </div>
    </>
  )
}