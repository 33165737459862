/* eslint-disable import/no-anonymous-default-export */
import logo from "../assets/image/logo.png";
import { Beranda } from "../views/Beranda";
import { TahunAjaran } from "../views/Kamus/TahunAjaran";
import { JenisKegiatan } from "../views/Kamus/JenisKegiatan";
import { Regional } from "../views/Kamus/Regional";
import { Partisipasi } from "../views/Kamus/Partisipasi";
import { Kegiatan } from "../views/Kamus/Kegiatan";
import { Pelanggaran } from "../views/Kamus/Pelanggaran";
import { TingkatanKelas } from "../views/Kamus/TingkatanKelas";
import { Kelas } from "../views/Kamus/Kelas";
import { KategoriGuru } from "../views/Kamus/KategoriGuru";
import { DaftarSiswa } from "../views/Kesiswaan/DaftarSiswa";
import { KegiatanKesiswaan } from "../views/Kesiswaan/KegiatanKesiswaan";
import { Guru } from "../views/Guru";
import { Pengguna } from "../views/Pengguna";
import {
  BatasJamMasuk,
  ProfilSekolah,
  SetupKebijakanPrivasi,
  SetupQna,
  UbahPassword,
} from "../views/Pengaturan";
import {
  KegiatanSiswa,
  PelanggaranSiswa,
  PresensiBulananSiswa,
} from "../views/Laporan";
import { Alumni } from "../views/Alumni";
import { Blog } from "../views/Blog";
import { KategoriBlog } from "../views/Kamus/KategoriBlog";
import { GenerateQrCode } from "../views/Presensi/GenerateQrCode";
import { DashboardPresensi } from "../views/Presensi/DashboardPresensi";
import { AlasanTerlambat } from "../views/Kamus/AlasanTerlambat";
import { HariLibur } from "../views/Kamus/HariLibur";
import { DashboardIzin } from "../views/Presensi/DashboardIzin";
import { KategoriQna } from "../views/Kamus/KategoriQna";
import { KebijakanPrivasiPublicPage } from "../views/Addition/KebijakanPrivasiPublicPage";
import { DispensasiSiswa } from "../views/Presensi/DispensasiSiswa";

export default {
  LOGO: logo,
  MENU: [
    {
      name: "Beranda",
      link: "/",
      type: "menu",
      permissions: ["ACHIEVEMENT_R", "VIOLATION_R"],
    },
    {
      name: "Kamus",
      type: "dropdown",
      link: "/kamus",
      menu: [
        {
          name: "Tahun Ajaran",
          link: "/kamus/tahun-ajaran",
          permissions: ["SCHOOL_R"],
        },
        {
          name: "Jenis Kegiatan",
          link: "/kamus/jenis-kegiatan",
          permissions: ["ACHIEVEMENT_LIB_R"],
        },
        {
          name: "Regional",
          link: "/kamus/regional",
          permissions: ["ACHIEVEMENT_LIB_R"],
        },
        {
          name: "Partisipasi",
          link: "/kamus/partisipasi",
          permissions: ["ACHIEVEMENT_LIB_R"],
        },
        {
          name: "Kegiatan",
          link: "/kamus/kegiatan",
          permissions: ["ACHIEVEMENT_LIB_R"],
        },
        {
          name: "Pelanggaran",
          link: "/kamus/pelanggaran",
          permissions: ["VIOLATION_LIB_R"],
        },
        {
          name: "Tingkatan Kelas",
          link: "/kamus/tingkatan-kelas",
          permissions: ["CLASS_R"],
        },
        {
          name: "Kelas",
          link: "/kamus/kelas",
          permissions: ["CLASS_R"],
        },
        {
          name: "Kategori Guru",
          link: "/kamus/kategori-guru",
          permissions: ["TES_R"],
        },
        {
          name: "Kategori Blog",
          link: "/kamus/kategori-blog",
          permissions: ["BLOG_CAT_R"],
        },
        {
          name: "Alasan Terlambat",
          link: "/kamus/alasan-terlambat",
          permissions: ["SCH_LIB_LATE_R"],
        },
        {
          name: "Hari Libur",
          link: "/kamus/hari-libur",
          permissions: ["SCH_LIB_HD_R"],
        },
        {
          name: "Kategori QnA",
          link: "/kamus/kategori-qna",
          permissions: ["SCH_QNA_R"],
        },
      ],
      permissions: [
        "ACHIEVEMENT_LIB_R",
        "VIOLATION_LIB_R",
        "CLASS_R",
        "TES_R",
        "SCHOOL_R",
        "BLOG_CAT_R",
        "SCH_LIB_LATE_R",
        "SCH_LIB_HD_R",
        "SCH_QNA_R",
      ],
    },
    {
      name: "Kesiswaan",
      link: "/kesiswaan",
      type: "dropdown",
      menu: [
        {
          name: "Daftar Siswa",
          link: "/kesiswaan/daftar-siswa",
          permissions: ["STUDENT_R"],
        },
        {
          name: "Kegiatan Siswa",
          link: "/kesiswaan/kegiatan-siswa",
          permissions: ["ACHIEVEMENT_R"],
        },
      ],
      permissions: ["STUDENT_R", "ACHIEVEMENT_R"],
    },
    {
      name: "Guru",
      link: "/guru",
      type: "menu",
      permissions: ["TEACHER_R"],
    },
    {
      name: "Alumni",
      link: "/alumni",
      type: "menu",
      permissions: ["STUDENT_R"],
    },
    {
      name: "Pengguna",
      link: "/pengguna",
      type: "menu",
      permissions: ["USER_R"],
    },
    {
      name: "Laporan",
      link: "/laporan",
      type: "dropdown",
      menu: [
        {
          name: "Kegiatan Siswa",
          link: "/laporan/kegiatan-siswa",
          permissions: ["ADMIN"],
        },
        {
          name: "Pelanggaran Siswa",
          link: "/laporan/pelanggaran-siswa",
          permissions: ["ADMIN"],
        },
        {
          name: "Presensi Bulanan Siswa",
          link: "/laporan/presensi-bulanan-siswa",
          permissions: ["ADMIN", "WALI_KELAS", "GURU_BK", "GURU"],
        },
      ],
      permissions: ["ADMIN", "WALI_KELAS", "GURU_BK", "GURU"],
    },
    {
      name: "Blog",
      link: "/blog",
      type: "menu",
      permissions: ["BLOG_R"],
    },
    {
      name: "Presensi",
      link: "/presensi",
      type: "dropdown",
      menu: [
        {
          name: "Generate QR Code",
          link: "/presensi/generate-qr-code",
          permissions: ["SCH_ATT_G"],
        },
        {
          name: "Dashboard Presensi",
          link: "/presensi/dashboard",
          permissions: ["WALI_KELAS", "GURU_BK", "GURU", "SCH_ATT_R"],
        },
        {
          name: "Dashboard Izin",
          link: "/presensi/dashboard-izin",
          permissions: ["SCH_ABC_PRM_R"],
        },
        {
          name: "Dispensasi Siswa",
          link: "/presensi/dispensasi-siswa",
          permissions: ["SCH_DP_R"],
        },
      ],
      permissions: [
        "SCH_ATT_G",
        "WALI_KELAS",
        "GURU_BK",
        "GURU",
        "SCH_ATT_R",
        "SCH_ABC_PRM_R",
        "SCH_DP_R",
      ],
    },
  ],
  PROFILE_DROPDOWN: [
    {
      name: "Pengaturan",
      link: "/pengaturan/profil-sekolah",
    },
    {
      name: "Keluar",
      link: undefined,
    },
  ],
  ROUTES: [
    {
      path: "/",
      page: <Beranda />,
      name: "Beranda",
      permissions: ["ADMIN"],
    },
    {
      path: "/beranda",
      page: <Beranda />,
      name: "Beranda",
      permissions: ["ADMIN"],
    },
    {
      path: "/kebijakan-privasi",
      page: <KebijakanPrivasiPublicPage />,
      name: "Kebijakan Privasi",
      permissions: [],
    },
    {
      path: "/pengaturan/",
      page: <ProfilSekolah />,
      name: "Pengaturan",
      permissions: ["ADMIN"],
    },
    {
      path: "/pengaturan/profil-sekolah",
      page: <ProfilSekolah />,
      name: "Pengaturan",
      permissions: ["ADMIN"],
    },
    {
      path: "/pengaturan/ubah-password",
      page: <UbahPassword />,
      name: "Pengaturan",
      permissions: ["ADMIN"],
    },
    {
      path: "/pengaturan/setup-qna",
      page: <SetupQna />,
      name: "Pengaturan",
      permissions: ["SCH_QNA_R", "ADMIN"],
    },
    {
      path: "/pengaturan/setup-kebijakan-privasi",
      page: <SetupKebijakanPrivasi />,
      name: "Pengaturan",
      permissions: ["SCH_KP_R", "ADMIN"],
    },
    {
      path: "/pengaturan/batas-jam-masuk",
      page: <BatasJamMasuk />,
      name: "Pengaturan",
      permissions: ["ADMIN"],
    },
    {
      path: "/kamus/tahun-ajaran",
      page: <TahunAjaran />,
      name: "Kamus Tahun Ajaran",
      permissions: ["SCHOOL"],
    },
    {
      path: "/kamus/jenis-kegiatan",
      page: <JenisKegiatan />,
      name: "Kamus Jenis Kegiatan",
      permissions: ["ACHIEVEMENT_LIB_R"],
    },
    {
      path: "/kamus/regional",
      page: <Regional />,
      name: "Kamus Regional",
      permissions: ["ACHIEVEMENT_LIB_R"],
    },
    {
      path: "/kamus/partisipasi",
      page: <Partisipasi />,
      name: "Kamus Partisipasi",
      permissions: ["ACHIEVEMENT_LIB_R"],
    },
    {
      path: "/kamus/kegiatan",
      page: <Kegiatan />,
      name: "Kamus Kegiatan",
      permissions: ["ACHIEVEMENT_LIB_R"],
    },
    {
      path: "/kamus/pelanggaran",
      page: <Pelanggaran />,
      name: "Kamus Pelanggaran",
      permissions: ["ACHIEVEMENT_LIB_R"],
    },
    {
      path: "/kamus/tingkatan-kelas",
      page: <TingkatanKelas />,
      name: "Kamus Tingkatan Kelas",
      permissions: ["CLASS_R"],
    },
    {
      path: "/kamus/kelas",
      page: <Kelas />,
      name: "Kamus Kelas",
      permissions: ["CLASS_R"],
    },
    {
      path: "/kamus/kategori-guru",
      page: <KategoriGuru />,
      name: "Kamus Kategori Guru",
      permissions: ["ROLE_R"],
    },
    {
      path: "/kamus/kategori-blog",
      page: <KategoriBlog />,
      name: "Kamus Kategori Blog",
      permissions: ["BLOG_CAT_R"],
    },
    {
      path: "/kamus/alasan-terlambat",
      page: <AlasanTerlambat />,
      name: "Kamus Alasan Terlambat",
      permissions: ["SCH_LIB_LATE_R"],
    },
    {
      path: "/kamus/hari-libur",
      page: <HariLibur />,
      name: "Kamus Hari Libur",
      permissions: ["SCH_LIB_HD_R"],
    },
    {
      path: "/kamus/kategori-qna",
      page: <KategoriQna />,
      name: "Kategori QnA",
      permissions: ["SCH_QNA_R"],
    },
    {
      path: "/kesiswaan/daftar-siswa",
      page: <DaftarSiswa />,
      name: "Daftar Siswa",
      permissions: ["STUDENT_R"],
    },
    {
      path: "/kesiswaan/kegiatan-siswa",
      page: <KegiatanKesiswaan />,
      name: "Kegiatan Siswa",
      permissions: ["ACHIEVEMENT_R"],
    },
    {
      path: "/guru",
      page: <Guru />,
      name: "Guru",
      permissions: ["TEACHER_R"],
    },
    {
      path: "/alumni",
      page: <Alumni />,
      name: "Alumni",
      permissions: ["STUDENT_R"],
    },
    {
      path: "/pengguna",
      page: <Pengguna />,
      name: "Pengguna",
      permissions: ["USER_R"],
    },
    {
      path: "/laporan/kegiatan-siswa",
      page: <KegiatanSiswa />,
      name: "Laporan Kegiatan Siswa",
      permissions: ["ADMIN"],
    },
    {
      path: "/laporan/pelanggaran-siswa",
      page: <PelanggaranSiswa />,
      name: "Laporan Pelanggaran Siswa",
      permissions: ["ADMIN"],
    },
    {
      path: "/laporan/presensi-bulanan-siswa",
      page: <PresensiBulananSiswa />,
      name: "Laporan Presensi Bulanan Siswa",
      permissions: ["ADMIN", "WALI_KELAS", "GURU_BK", "GURU"],
    },
    {
      path: "/blog",
      page: <Blog />,
      name: "Blog",
      permissions: ["BLOG_R"],
    },
    {
      path: "/presensi/generate-qr-code",
      page: <GenerateQrCode />,
      name: "Generate QR Code",
      permissions: ["SCH_ATT_G"],
    },
    {
      path: "/presensi/dashboard",
      page: <DashboardPresensi />,
      name: "Dashboard Presensi",
      permissions: ["WALI_KELAS", "GURU_BK", "GURU", "SCH_ATT_R"],
    },
    {
      path: "/presensi/dashboard-izin",
      page: <DashboardIzin />,
      name: "Dashboard Izin",
      permissions: ["SCH_ABC_PRM_R"],
    },
    {
      path: "/presensi/dispensasi-siswa",
      page: <DispensasiSiswa />,
      name: "Dispensasi Siswa",
      permissions: ["SCH_DP_R"],
    },
  ],
};
