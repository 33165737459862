const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class SetupKebijakanPrivasiApi {
  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/privacy-policy/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  async getCurrent(axiosPrivate) {
    const fetch = await axiosPrivate.get(`${url}/v1/privacy-policy/current`);
    return fetch.data.payload;
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v1/privacy-policy`, data);
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.put(`${url}/v1/privacy-policy/${id}`, data);
  }

  delete(axiosPrivate, id) {
    return axiosPrivate.delete(`${url}/v1/privacy-policy/${id}`);
  }
}

export default new SetupKebijakanPrivasiApi();
