const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class DaftarSiswaApi {
    async getList(axiosPrivate, classroomId, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/classrooms/${classroomId}/students/pageable`, { params })
        return fetch.data.payload
    }

    async detail(axiosPrivate, id) {
        const fetch = await axiosPrivate.get(`${url}/v1/students/${id}`)
        return fetch.data.payload
    }

    async import(axiosPrivate, classroomPublicId, data) {
        return await axiosPrivate.post(`${url}/v1/students/import-excel/classroom/${classroomPublicId}`, data)
    }

    create(axiosPrivate, data) {
        return axiosPrivate.post(`${url}/v1/students`, data)
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/students/${id}`, data)
    }

    naikKelas(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/classrooms/${id}/students`, data)
    }

    pindahKelas(axiosPrivate, data, originClassroomPublicId, studentPublicId) {
        return axiosPrivate.put(`${url}/v1/classrooms/${originClassroomPublicId}/students/${studentPublicId}`, data)
    }

    delete(axiosPrivate, id) {
        return axiosPrivate.delete(`${url}/v1/students/${id}`)
    }
}

export default new DaftarSiswaApi()