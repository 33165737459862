import { Navigate, Route, Routes } from "react-router-dom";
import { BaseLayout } from "components";
import Config from "../config";
import { useAuth, useRefreshToken } from "../utilities";
import {
  KebijakanPrivasiPublicPage,
  LoadingPage,
  Login,
  PageNotFound,
} from "../views";
import { StompSessionProvider } from "react-stomp-hooks";

const urlStudentship = window._env_.REACT_APP_API_STUDENTSHIP_URL;

export const MainRoutes = () => {
  const { ROUTES } = Config;
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  // Authorization's routes
  const AuthRoutes = () => {
    return (
      <Routes>
        {/* ROUTES CONFIG */}
        {ROUTES.map((value, index) => (
          <Route
            key={index}
            path={value.path}
            element={
              <BaseLayout
                title={value.name}
                showHeaderMenu={Boolean(
                  !value.permissions.includes("SCH_ATT_G")
                )}
              >
                {value.page}
              </BaseLayout>
            }
          />
        ))}

        {/* LOGIN FORM */}
        <Route
          path="/login"
          element={
            <Navigate
              to={localStorage?.getItem("siswaku-admin-link") ?? "/"}
              replace
            />
          }
        />

        {/* PAGE NOT FOUND */}
        <Route
          path="*"
          element={
            <BaseLayout title="Halaman Tidak Ditemukan">
              <PageNotFound />
            </BaseLayout>
          }
        />
      </Routes>
    );
  };

  // UnAuthorization's routes
  const UnAuthRoute = () => {
    return (
      <Routes>
        {/* LOGIN FORM */}
        <Route path="/login" element={<Login />} />
        {/* KEBIJAKAN PRIVASI */}
        <Route
          path="/kebijakan-privasi"
          element={
            <BaseLayout title="Kebijakan Privasi">
              <KebijakanPrivasiPublicPage />
            </BaseLayout>
          }
        />
        {/* OTHER PATH */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  };

  // All routes for MainRoutes' return
  const AllRoutes = () => {
    if (auth?.status === "unauthenticated") {
      return <UnAuthRoute />;
    } else {
      if (auth?.status === undefined) {
        refresh();
        return <LoadingPage />;
      } else if (auth?.status === "unauthenticated") {
        return <UnAuthRoute />;
      } else {
        sessionStorage.setItem("ws-connection-status", false);
        sessionStorage.setItem("ws-connection-cid", null);

        return (
          <StompSessionProvider
            url={`${urlStudentship}/websocket/sockjs`}
            onConnect={(e) => {
              const headers = e.headers;
              const userName = headers["user-name"];
              sessionStorage.setItem("ws-connection-status", true);
              sessionStorage.setItem("ws-connection-cid", userName);
              console.log("connect");
            }}
            onDisconnect={() => {
              sessionStorage.setItem("ws-connection-status", false);
              sessionStorage.setItem("ws-connection-cid", null);
              console.log("disconnect");
              // window.location.reload();
            }}
            connectionTimeout={() => {
              sessionStorage.setItem("ws-connection-status", false);
              sessionStorage.setItem("ws-connection-cid", null);
              console.log("connection timeout");
              // window.location.reload();
            }}
            onWebSocketClose={() => {
              sessionStorage.setItem("ws-connection-status", false);
              sessionStorage.setItem("ws-connection-cid", null);
              console.log("websocket close");
            }}
            onWebSocketError={() => {
              sessionStorage.setItem("ws-connection-status", false);
              sessionStorage.setItem("ws-connection-cid", null);
              console.log("websocket error");
            }}
            reconnectDelay={10000}
          >
            <AuthRoutes />
          </StompSessionProvider>
        );
      }
    }
  };

  return <AllRoutes />;
};
