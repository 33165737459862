import {
  DeleteButon,
  EditButton,
  ListLayout,
  Modal,
  Sidebar,
  ViewButton,
} from "components";
import React, { useState } from "react";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { PengaturanMenus } from "../PengaturanMenus";
import { useMutation, useQuery } from "react-query";
import SetupQnaApi from "./__SetupQnaApi__";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__SetupQnaUtilites__";
import { DetailSection, FormSection } from "./__SetupQnaComponents__";

export const SetupQna = () => {
  const axiosPrivate = useAxiosPrivate();
  const menus = PengaturanMenus;
  const { auth } = useAuth();
  const permissions = auth.permissions;

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "index"
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  const columns = [
    {
      name: "No",
      selector: (_, index) =>
        (getQna.data?.pageable?.pageNumber - 1) *
          getQna.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Urutan List",
      selector: (row) => row.index,
      wrap: true,
      width: "100px",
    },
    {
      name: "Kategori",
      selector: (row) => row.qnaCategoryName,
      wrap: true,
    },
    {
      name: "Judul",
      selector: (row) => row.title,
      wrap: true,
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_QNA_R") && (
            <ViewButton
              icon
              noText
              onClick={() => onReadButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_QNA_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_QNA_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
      width: "150px",
    },
  ];

  const getQna = useQuery(["qna-list", pagination], () =>
    SetupQnaApi.getList(axiosPrivate, paginationHandler(pagination)).catch(
      () => {
        return [];
      }
    )
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      errorText: "",
      error: false,
      data: data,
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const createQna = useMutation(
    (data) => SetupQnaApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getQna.refetch();
      },
    }
  );

  const updateQna = useMutation(
    ({ data, publicId }) => SetupQnaApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getQna.refetch();
      },
    }
  );

  const deleteQna = useMutation((id) => SetupQnaApi.delete(axiosPrivate, id), {
    onSuccess: () => {
      getQna.refetch();
    },
  });

  const submitHandler = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const finalValues = formSubmitValueMapper(values);
    if (modal.type === "create") {
      await createQna
        .mutateAsync(finalValues)
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      await updateQna
        .mutateAsync({
          data: finalValues,
          publicId: values?.publicId,
        })
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Sidebar name="Setup QnA" menus={menus}>
      <ListLayout
        permissions={permissions}
        permissionCreate="SCH_QNA_C"
        data={getQna.data?.content}
        columns={columns}
        loading={getQna.isFetching}
        error={getQna.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getQna.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
      />

      {/* <---- MODAL CREATE ----> */}
      {modal.type === "create" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues("create", {})}
          validationSchema={formValidationSchema()}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;
            return (
              <Modal
                id="modal-tambah-qna"
                header="Tambah QnA"
                size="large"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "create")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection modal={modal} publicId={modal.data.publicId} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL READ ---> */}
      {modal.type === "read" && modal.show && (
        <Modal
          id="modal-detil-qna"
          header="Detail QnA"
          size="large"
          type="read"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "read")}
        >
          <DetailSection data={modal} />
        </Modal>
      )}

      {/* <--- MODAL UPDATE ---> */}
      {modal.type === "update" && modal.show && (
        <Formik
          enableReinitialize
          validationSchema={formValidationSchema()}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;

            return (
              <Modal
                id="modal-ubah-qna"
                header="Ubah QnA"
                size="large"
                type="update"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "update")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection modal={modal} publicId={modal.data.publicId} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL DELETE ---> */}
      {modal.type === "delete" && modal.show && (
        <Modal
          id="modal-hapus-qna"
          header="Hapus QnA"
          size="large"
          type="delete"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "delete")}
          isSubmitting={deleteQna.isLoading}
          onSubmit={() => {
            deleteQna
              .mutateAsync(modal.data.publicId)
              .then((res) => {
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          <DetailSection data={modal} />
        </Modal>
      )}
    </Sidebar>
  );
};
