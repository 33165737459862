const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class BlogApi {
  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/blog/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  async detail(axiosPrivate, id) {
    const fetch = await axiosPrivate.get(`${url}/v1/blog/${id}`);
    return fetch.data.payload;
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v1/blog`, data);
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.put(`${url}/v1/blog/${id}`, data);
  }

  delete(axiosPrivate, id) {
    return axiosPrivate.delete(`${url}/v1/blog/${id}`);
  }

  async setPublish(axiosPrivate, id, params) {
    const fetch = await axiosPrivate.patch(
      `${url}/v1/blog/${id}/isPublish`,
      {},
      {
        params,
      }
    );
    return fetch.data.payload;
  }
}

export default new BlogApi();
