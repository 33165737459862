import { Footer, Header } from './__BaseLayoutComponents__'

export const BaseLayout = ({ children, title, showHeaderMenu = true }) => {
    return (
        <div className='w-full overflow-y-auto h-full'>
            <Header title={title} showHeaderMenu={showHeaderMenu} />
            <div className='mx-auto py-6 px-4 sm:px-6 lg:px-8' style={{minHeight: 'calc(100% - 210px)'}}>
                {children}
            </div>
            <Footer />
        </div>
    )
}