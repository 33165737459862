import React from "react";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";
import BlogApi from "../__BlogApi__";
import { DataStatus } from "components";

export const CoverSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = modal?.data;

  const getDetail = useQuery(
    ["blog-cover-atribut-detail", publicId],
    () => BlogApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(modal.show && modal.type === "view_cover") }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat cover blog..."
        text="Cover blog gagal dimuat"
      />
    );
  } else {
    return (
      <div>
        {getDetail?.data?.images?.length > 0 ? (
          <div className="mb-5">
            <img
              alt={getDetail?.data?.images?.[0]?.name}
              src={getDetail?.data?.images?.[0]?.fileUrl}
              className="w-full object-cover"
            />
          </div>
        ) : (
          <div className="text-center">
            <label className="validation-error-text">Blog "{getDetail?.data?.title}" tidak memiliki cover.</label>
          </div>
        )}
      </div>
    );
  }
};
