import React from 'react'

export const RowDetailLayout = ({
    label,
    text,
    withComponent = false,
    component = <></>
}) => {
    return (
        <div className='flex flex-col mb-2'>
            <small >{label ? label : '-'}</small>
            {withComponent ? component : <strong className='text-sm pl-2 text-gray-700'>{text ? text : '-'}</strong>}
        </div>
    )
}
