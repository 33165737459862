import { mappingDataOptions, mappingDataOptionsAddition } from "utilities";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KategoriBlogApi {
  async getKategoriBlog(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/blog-category`, { params })
    return mappingDataOptionsAddition(fetch.data.payload)
  }

  async getKategoriBlogWithoutOptionsAddition(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/blog-category`, { params })
    return mappingDataOptions(fetch.data.payload)
  }
}

export default new KategoriBlogApi()