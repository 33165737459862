export const generateHeaderTablePresensiBulanan = (data) => {
  const generalHeader = [
    { text: "No.", props: { width: "30px", fontSize: "10px" } },
    { text: "NIS", props: { width: "80px", fontSize: "10px" } },
    { text: "Nama Siswa", props: { width: "300px", fontSize: "10px" } },
  ];

  const dateHeader = () => {
    const tamp = [];

    data?.students?.[0].date.forEach((val) => {
      tamp.push({
        text: val.date.split("-")[2],
        props: {
          fontSize: "9px",
          color: (val.isHoliday || val.isWeekend) && "red",
        },
      });
    });

    return tamp;
  };

  const totalHeader = [
    { text: "Total Hari", props: { width: "50px", fontSize: "10px" } },
    { text: "M", props: { width: "50px", fontSize: "10px" } },
    { text: "T", props: { width: "50px", fontSize: "10px" } },
    { text: "I", props: { width: "50px", fontSize: "10px" } },
    { text: "S", props: { width: "50px", fontSize: "10px" } },
    { text: "A", props: { width: "50px", fontSize: "10px" } },
  ];

  return generalHeader.concat(dateHeader()).concat(totalHeader);
};
