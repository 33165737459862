import { mappingDataOptionsAddition } from "utilities"

const url = window._env_.REACT_APP_API_AUTH_URL

class RolesApi {
    async getRoles(axiosPrivate) {
        const fetch = await axiosPrivate.get(`${url}/v1/roles`, { isActive: true })
        return mappingDataOptionsAddition(fetch.data.payload)
    }
}

export default new RolesApi()