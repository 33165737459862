import { DataStatus, SaveButton, Sidebar } from "components"
import { Formik } from "formik"
import { useMutation, useQuery } from "react-query"
import { errorFetching, successFetching, useAxiosPrivate } from "utilities"
import { formInitialValues, formValidationSchema } from "./__ProfilSekolahUtilities__"
import { FormSection } from "./__ProfilSekolahComponents__"
import { SchoolsApi } from "api"
import { PengaturanMenus } from "../PengaturanMenus"
import ProfilSekolahApi from "./__ProfilSekolahApi__"

export const ProfilSekolah = () => {
  const axiosPrivate = useAxiosPrivate()
  const menus = PengaturanMenus

  // useQuery
  const getSchoolAccount = useQuery(
    ['school-account-atribut-admin'],
    () => SchoolsApi.getAccount(axiosPrivate)
  )

  const update = useMutation(({data, publicId}) => ProfilSekolahApi.update(axiosPrivate, data, publicId), {
    onSuccess: () => {
      window.location.reload()
    }
  })

  if(getSchoolAccount.isFetching || getSchoolAccount.isError){
    return (
      <Sidebar name='Profil Sekolah' menus={menus}>
        <DataStatus 
          loading={getSchoolAccount.isFetching} 
          loadingText='Memuat data sekolah...'
          text='Data sekolah gagal dimuat'
        /> 
      </Sidebar>
    )
  } else{
    return (
      <Sidebar name='Profil Sekolah' menus={menus}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues(getSchoolAccount.data)}
          validationSchema={formValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const finalValues = {
              ...values,
              address: {
                ...values?.address,
                street: values?.street,
                postalCode: values?.postalCode
              }
            }

            update.mutateAsync({
              data: finalValues,
              publicId: finalValues?.publicId
            }).then(res => {
              resetForm()
              successFetching(res)
            }).catch(err => {
              errorFetching(err)
            })
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik
            return (
              <>
                <FormSection />
                <div>
                  <SaveButton 
                    icon
                    type='submit'
                    onClick={handleSubmit}
                    className='mt-3 w-full sm:w-auto'
                    disabled={update.isLoading}
                    loading={update.isLoading}
                  />
                </div>
              </>
            )
          }}
        </Formik>
      </Sidebar>
    )
  }
}
