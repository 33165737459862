const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class DashboardPresensiApi {
  async getListPageable(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/attendance/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/attendance`, { params });
    return fetch.data.payload;
  }

  verifikasi(axiosPrivate, attendanceQrCodePublicId, data) {
    return axiosPrivate.patch(
      `${url}/v1/attendance/${attendanceQrCodePublicId}/isApproved`,
      data
    );
  }

  listVerifikasi(axiosPrivate, data) {
    return axiosPrivate.patch(`${url}/v1/attendance/isApproved`, data);
  }

  byPassPresensi(axiosPrivate, studentPublicId, date, data) {
    return axiosPrivate.post(
      `${url}/v1/attendance/student/${studentPublicId}/date/${date}`,
      data
    );
  }
}

export default new DashboardPresensiApi();
