// <--- React --->
import { useState } from "react";

// <--- API --->
import { ClassroomsApi, GradeApi } from "api";
import DaftarSiswaApi from "./__DaftarSiswaApi__";

// <--- Utilities --->
import {
  errorFetching,
  getDashDMY,
  mappingDataGender,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";

// <--- Third-Party Library --->
import { useMutation, useQuery } from "react-query";

// <--- Components --->
import {
  DataStatus,
  DefaultButton,
  DeleteButon,
  EditButton,
  LabelDanger,
  LabelSuccess,
  ListLayout,
  Modal,
  Select,
  ViewButton,
} from "components";
import {
  ContentImportSiswa,
  ContentLulus,
  ContentNaikKelas,
  ContentPindahKelas,
  DetailSection,
  FormSection,
} from "./__DaftarSiswaComponents__";

// <--- Form --->
import { Formik } from "formik";

// <--- Utilities --->
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  naikKelasInitialValues,
  naikKelasValidationSchema,
  pindahKelasInitialValues,
  pindahKelasValidationSchema,
  importSiswaInitialValues,
  importSiswaValidationSchema,
  lulusInitialValues,
} from "./__DaftarSiswaUtilities__";

export const DaftarSiswa = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "student_name",
    isActive: true,
  });
  const [isActive, setIsActive] = useState("true");
  const [filter, setFilter] = useState({
    tingkatanName: "",
    tingkatanPublicId: "",
    kelasName: "",
    kelasPublicId: "",
  });
  const [dataKelas, setDataKelas] = useState({
    guruBk: "",
    guruWaliKelas: "",
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });
  const [tingkatanKelas, setTingkatanKelas] = useState(0);

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getSiswa.data?.pageable?.pageNumber - 1) *
          getSiswa.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.nis,
      width: "150px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.name,
      minWidth: "400px",
      wrap: true,
    },
    {
      name: "Jenis Kelamin",
      selector: (row) => mappingDataGender(row?.gender),
      width: "120px",
      wrap: true,
    },
    {
      name: "Tanggal Lahir",
      selector: (row) =>
        row?.dateOfBirth ? getDashDMY(row?.dateOfBirth) : "-",
      width: "130px",
      wrap: true,
    },
    {
      name: "Status",
      cell: (data) =>
        data?.isActive ? (
          <LabelSuccess text="Aktif" />
        ) : (
          <LabelDanger text="Tidak Aktif" />
        ),
      width: "120px",
    },
    {
      name: "Aksi",
      button: true,
      width: "230px",
      cell: (data) => (
        <>
          {permissions.includes("STUDENT_R") && (
            <ViewButton
              icon
              noText
              onClick={() => onReadButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("STUDENT_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("STUDENT_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("STUDENT_U") && (
            <ViewButton
              className="w-auto"
              text="Pindah Kelas"
              onClick={() => onPindahKelasButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  // <--- useQuery --->
  const getTingkatanKelas = useQuery(["tingkatan-kelas-list"], () =>
    GradeApi.getGrade(axiosPrivate, {
      isActive: true,
      sortBy: "name",
      direction: "ASC",
    })
  );

  const getKelas = useQuery(
    ["kelas-list", filter?.tingkatanPublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        gradePublicId: filter.tingkatanPublicId,
      }),
    { enabled: Boolean(filter?.tingkatanPublicId) }
  );

  const getSiswa = useQuery(
    ["siswa-list", filter, pagination],
    () =>
      DaftarSiswaApi.getList(
        axiosPrivate,
        filter?.kelasPublicId,
        paginationHandler(pagination)
      ).catch(() => {
        return [];
      }),
    { enabled: Boolean(filter?.kelasPublicId) }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        isActive: e.target.name,
      });
      setIsActive(e.target.name);
    } else {
      setPagination({
        ...pagination,
        isActive: undefined,
      });
      setIsActive("none");
    }
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickImportButton = () =>
    setModal({
      show: true,
      type: "import",
      errorText: "",
      error: false,
      data: {},
    });

  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      errorText: "",
      error: false,
      data: data,
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const onNaikKelasButtonClickHandler = () =>
    setModal({
      show: true,
      type: "naikKelas",
      errorText: "",
      error: false,
      data: {},
    });

  const onLulusButtonClickHandler = () =>
    setModal({
      show: true,
      type: "lulus",
      errorText: "",
      error: false,
      data: {},
    });

  const onPindahKelasButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "pindahKelas",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- useMutation --->
  const createSiswa = useMutation(
    (data) => DaftarSiswaApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  const updateSiswa = useMutation(
    ({ data, publicId }) => DaftarSiswaApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  const deleteSiswa = useMutation(
    (id) => DaftarSiswaApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  const naikKelas = useMutation(
    ({ data, publicId }) =>
      DaftarSiswaApi.naikKelas(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  const lulus = useMutation(
    ({ data, publicId }) =>
      DaftarSiswaApi.naikKelas(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  const pindahKelas = useMutation(
    ({ data, originClassroomPublicId, studentPublicId }) =>
      DaftarSiswaApi.pindahKelas(
        axiosPrivate,
        data,
        originClassroomPublicId,
        studentPublicId
      ),
    {
      onSuccess: () => {
        getSiswa.refetch();
      },
    }
  );

  return (
    <>
      <div className="sm:flex sm:flex-row mb-4">
        <div className="flex">
          <div className="mr-2">
            <Select
              label="Tingkatan Kelas"
              placeholder="Pilih Tingkatan Kelas"
              defaultValue={getTingkatanKelas?.data?.find(
                (item) => item.value === filter?.tingkatanPublicId
              )}
              onChange={(val) => {
                setFilter({
                  ...filter,
                  tingkatanName: val.label,
                  tingkatanPublicId: val.value,
                  kelasName: "",
                  kelasPublicId: "",
                });
                setPagination({
                  ...pagination,
                  search: undefined,
                  searchBy: undefined,
                });
                setDataKelas({
                  guruBk: val?.counseling?.name,
                  guruWaliKelas: "",
                });
                setTingkatanKelas(val?.grade);
              }}
              options={getTingkatanKelas?.data ?? []}
              errorFetch={getTingkatanKelas.isError}
              errorFetchText={
                getTingkatanKelas?.error?.response?.data?.errorMessage[0]
              }
              loading={getTingkatanKelas.isFetching}
            />
          </div>
          <div className="ml-2">
            <Select
              label="Kelas"
              placeholder="Pilih Kelas"
              defaultValue={getKelas?.data?.find(
                (item) => item.value === filter?.kelasPublicId
              )}
              onChange={(val) => {
                setFilter({
                  ...filter,
                  kelasName: val.label,
                  kelasPublicId: val.value,
                });
                setDataKelas({
                  ...dataKelas,
                  guruWaliKelas: val?.teacherGuide?.name,
                });
              }}
              options={getKelas?.data ?? []}
              errorFetch={getKelas.isError}
              errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
              loading={getKelas.isFetching}
              disable={Boolean(filter.tingkatanPublicId === "")}
            />
          </div>
        </div>
        <div className="flex flex-1 sm:justify-end">
          {tingkatanKelas !== 9 && (
            <ViewButton
              type="button"
              disabled={
                Boolean(filter?.kelasPublicId === "") ||
                Boolean(getSiswa?.data?.length === 0)
              }
              text="Naik Kelas"
              className="mt-6"
              onClick={onNaikKelasButtonClickHandler}
            />
          )}
          {tingkatanKelas === 9 && (
            <ViewButton
              type="button"
              disabled={
                Boolean(filter?.kelasPublicId === "") ||
                Boolean(getSiswa?.data?.length === 0)
              }
              text="Lulus"
              className="mt-6"
              onClick={onLulusButtonClickHandler}
            />
          )}
          <DefaultButton
            type="button"
            text="Import Siswa"
            className="mt-6 ml-3"
            disabled={
              Boolean(filter?.kelasPublicId === "") ||
              Boolean(getSiswa?.data?.length !== 0)
            }
            onClick={onClickImportButton}
          />
        </div>
      </div>
      {filter?.kelasPublicId === "" ? (
        <DataStatus text="Silahkan memilih kelas terlebih dahulu" />
      ) : (
        <>
          <div className="mb-3 space-y-2">
            <div className="text-sm sm:flex sm:flex-row">
              <div className="font-semibold">Guru Bimbingan Konseling : </div>
              <div className="ml-2">{dataKelas?.guruBk}</div>
            </div>
            <div className="text-sm sm:flex sm:flex-row">
              <div className="font-semibold">Guru Wali Kelas : </div>
              <div className="ml-2">{dataKelas?.guruWaliKelas}</div>
            </div>
          </div>
          {/* <--- Search input, Create Button, and Table ---> */}
          <ListLayout
            permissions={permissions}
            permissionCreate="STUDENT_C"
            data={getSiswa.data?.content}
            columns={columns}
            loading={getSiswa.isFetching}
            error={getSiswa.error?.response?.data?.errorMessage[0]}
            pagination={true}
            totalRows={getSiswa.data?.pageable?.totalElements}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            onSearchChange={searchDataHandler}
            onClickCreateButton={onClickCreateButton}
            activeStatusFilter
            activeOnChange={activeDataHandler}
            activeValues={isActive}
          />

          {/* <--- MODAL NAIK KELAS ---> */}
          {modal.type === "naikKelas" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={naikKelasInitialValues(filter)}
              validationSchema={naikKelasValidationSchema()}
              onSubmit={async (values, { resetForm }) => {
                naikKelas
                  .mutateAsync({
                    data: values,
                    publicId: values.originClassroomPublicId,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;

                return (
                  <Modal
                    id="modal-naik-kelas"
                    header="Naik Kelas"
                    size="small"
                    type="update"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "naikKelas")}
                    onSubmit={handleSubmit}
                    isSubmitting={naikKelas.isLoading}
                  >
                    <ContentNaikKelas modal={modal} />
                  </Modal>
                );
              }}
            </Formik>
          )}

          {/* <--- MODAL LULUS ---> */}
          {modal.type === "lulus" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={lulusInitialValues(filter)}
              onSubmit={async (values, { resetForm }) => {
                lulus
                  .mutateAsync({
                    data: values,
                    publicId: values.originClassroomPublicId,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;

                return (
                  <Modal
                    id="modal-lulus"
                    header="Lulus"
                    size="small"
                    type="update"
                    customSaveText="Ya"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "lulus")}
                    onSubmit={handleSubmit}
                    isSubmitting={lulus.isLoading}
                  >
                    <ContentLulus modal={modal} />
                  </Modal>
                );
              }}
            </Formik>
          )}

          {/* <--- MODAL CREATE ---> */}
          {modal.type === "create" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={formInitialValues("create", {}, filter)}
              validationSchema={formValidationSchema()}
              onSubmit={async (values, { resetForm }) => {
                const finalValues = formSubmitValueMapper(values);

                createSiswa
                  .mutateAsync(finalValues)
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;

                return (
                  <Modal
                    id="modal-tambah-siswa"
                    header="Tambah Siswa"
                    size="medium"
                    type="create"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "create")}
                    onSubmit={handleSubmit}
                    isSubmitting={createSiswa.isLoading}
                  >
                    <FormSection modal={modal} publicId={modal.data.publicId} />
                  </Modal>
                );
              }}
            </Formik>
          )}

          {/* <--- MODAL IMPORT ---> */}
          {modal.type === "import" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={importSiswaInitialValues}
              validationSchema={importSiswaValidationSchema()}
              onSubmit={async (values, { resetForm, setSubmitting }) => {
                if (values?.dataFile !== null) {
                  let dataFile = new FormData();
                  dataFile.append("file", values?.dataFile?.data);

                  await DaftarSiswaApi.import(
                    axiosPrivate,
                    filter?.kelasPublicId,
                    dataFile
                  )
                    .then((res) => {
                      resetForm();
                      successFetching(res);
                      getSiswa.refetch();
                      onHideModalHandler();
                    })
                    .catch((err) => {
                      const errorMessage = errorFetching(err);
                      setModal({
                        ...modal,
                        error: true,
                        errorText: errorMessage,
                      });
                    })
                    .finally(() => setSubmitting(false));
                }
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm, isSubmitting } = formik;

                return (
                  <Modal
                    id="modal-import-siswa"
                    header="Import Data Siswa"
                    size="small"
                    type="create"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "import")}
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  >
                    <ContentImportSiswa data={filter} />
                  </Modal>
                );
              }}
            </Formik>
          )}

          {/* <--- MODAL UPDATE ---> */}
          {modal.type === "update" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={formInitialValues("update", modal?.data, filter)}
              validationSchema={formValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                const finalValues = formSubmitValueMapper(values);

                updateSiswa
                  .mutateAsync({
                    data: finalValues,
                    publicId: finalValues.publicId,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit } = formik;

                return (
                  <Modal
                    id="modal-ubah-siswa"
                    header="Ubah Siswa"
                    size="medium"
                    type="update"
                    onHide={onHideModalHandler}
                    show={Boolean(modal.show && modal.type === "update")}
                    onSubmit={handleSubmit}
                    isSubmitting={updateSiswa.isLoading}
                  >
                    <FormSection modal={modal} publicId={modal.data.publicId} />
                  </Modal>
                );
              }}
            </Formik>
          )}

          {/* <--- MODAL DELETE ---> */}
          {modal.type === "delete" && modal.show && (
            <Modal
              id="modal-hapus-siswa"
              header="Hapus Siswa"
              size="large"
              type="delete"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "delete")}
              isSubmitting={deleteSiswa.isLoading}
              onSubmit={() => {
                deleteSiswa
                  .mutateAsync(modal.data.publicId)
                  .then((res) => {
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              <DetailSection data={modal} kelas={filter} />
            </Modal>
          )}

          {/* <--- MODAL READ ---> */}
          {modal.type === "read" && modal.show && (
            <Modal
              id="modal-detil-siswa"
              header="Detail Siswa"
              size="large"
              type="read"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "read")}
            >
              <DetailSection data={modal} kelas={filter} />
            </Modal>
          )}

          {/* <--- MODAL PINDAH KELAS ---> */}
          {modal.type === "pindahKelas" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={pindahKelasInitialValues(
                filter,
                modal?.data?.publicId
              )}
              validationSchema={pindahKelasValidationSchema()}
              onSubmit={async (values, { resetForm }) => {
                pindahKelas
                  .mutateAsync({
                    data: values,
                    originClassroomPublicId: values.originClassroomPublicId,
                    studentPublicId: values.studentPublicId,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;

                return (
                  <Modal
                    id="modal-pindah-kelas"
                    header="Pindah Kelas"
                    size="medium"
                    type="update"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "pindahKelas")}
                    onSubmit={handleSubmit}
                    isSubmitting={pindahKelas.isLoading}
                  >
                    <ContentPindahKelas modal={modal} />
                  </Modal>
                );
              }}
            </Formik>
          )}
        </>
      )}
    </>
  );
};
