const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class StudentApi {
    async getStudentViolations(axiosPrivate, studentId, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/students/${studentId}/violations/pageable`, { params })
        return fetch.data.payload
    }

    async getStudentAchievements(axiosPrivate, studentId, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/students/${studentId}/achievements/pageable`, { params })
        return fetch.data.payload
    }
}

export default new StudentApi()