import * as Yup from "yup";

export const formValidationSchema = () =>
  Yup.object().shape({
    gradePublicId: Yup.string().required("Tingkatan kelas wajib dipilih"),
    classroomPublicId: Yup.string().required("Kelas wajib diisi"),
    studentPublicId: Yup.string().required("Siswa wajib dipilih"),
    description: Yup.string().required("Keterangan Izin wajib diisi"),
    startDate: Yup.string().required("Tanggal Awal wajib diisi"),
    endDate: Yup.string().required("Tanggal Akhir wajib diisi"),
  });

export const formVerifikasiValidationSchema = () =>
  Yup.object().shape({
    value: Yup.string().required("Status verifikasi wajib dipilih"),
  });
