import { Spinner } from "components"
import { FaCheck, FaTimes } from "react-icons/fa"

export const Input = ({
    label,
    name,
    type,
    className,
    wrapperClassName,
    placeholder,
    value,
    readOnly,
    disabled,
    onChange,
    error,
    errorText,
    valid,
    validText,
    showCheck = false,
    loadingCheck,
    isAvailable,
    autoComplete = ""
}) => {
    return (
        <div className={wrapperClassName}>
            <small>
                {label}
            </small>
            {showCheck ?
                <div className="flex">
                    <input 
                        name={name}
                        type={type}
                        className={`${className ? className : 'input-primary'} ${disabled ? 'disabled:cursor-not-allowed' : ''}`}
                        placeholder={placeholder}
                        value={value}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={onChange}
                        autoComplete={autoComplete}
                    />
                    <span className="inline-flex items-center px-2 border-l-0">
                        {loadingCheck ? 
                            <Spinner /> : 
                                isAvailable ?
                                    <FaCheck className={`h-4 w-4 ${value === '' ? 'text-white' : 'text-green-600'}`} /> :
                                        <FaTimes className={`h-4 w-4 ${value === '' ? 'text-white' : 'text-red-600'}`} />
                        }
                    </span>
                </div> :
                <input
                    name={name}
                    type={type}
                    className={`${className ? className : 'input-primary'} ${disabled ? 'disabled:cursor-not-allowed' : ''}`}
                    placeholder={placeholder}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    onChange={onChange}
                    autoComplete={autoComplete}
                />
            }
            {error && <div>
                <small className='validation-error-text'>
                    {errorText}
                </small>
            </div>}
            {valid && <div>
                <small className='validation-success-text'>
                    {validText}
                </small>
            </div>}
        </div>
    )
}
