import { NavLink } from "react-router-dom"

export const Sidebar = ({ name, children, menus = [] }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="sm:flex sm:flex-row">
      <div className="sm:w-60 flex flex-col">
        {menus.map(item => {
          return (
            <NavLink
              key={item.key}
              to={item.link}
              onClick={() => localStorage.setItem('siswaku-admin-link', item.link)}
              className={classNames(
                item.arrayLink.includes(window.location.pathname) ? 'bg-gray-900 text-white' : 'text-gray-900 hover:bg-gray-700 hover:text-white',
                'px-3 py-3 rounded-md text-sm font-medium flex flex-row items-center'
              )}
            >
              <span className="mr-2">{item.icon}</span>
              <span>{item.name}</span>
            </NavLink>
          )
        })}
      </div>
      <div className="flex-1 mt-5 sm:mt-0 sm:ml-10 flex flex-col border-t-[1px] sm:border-t-0">
        <div className="text-xl font-semibold mb-4 text-gray-900 mt-5 sm:mt-0">
          {name}
        </div>
        {children}
      </div>
    </div>
  )
}
