// <--- React --->
import { useState } from 'react'

// <--- Components --->
import { 
    DataStatus,
    DefaultButton,
    DeleteButon, EditButton, LabelDanger, LabelSuccess, ListLayout, Modal, Select 
} from 'components';
import { DetailSection, FormSection } from './__KegiatanComponents__';

// <--- API --->
import KegiatanApi from './__KegiatanApi__'

// <--- Utilities --->
import { errorFetching, paginationHandler, successFetching, useAuth, useAxiosPrivate } from 'utilities'

// <--- Third-Party Library --->
import { useMutation, useQuery } from 'react-query'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { AchievementApi } from 'api';

export const Kegiatan = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const permissions = auth.permissions;

    // <--- States --->
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        isActive: true,
        achievementLibraryPublicId: undefined,
        achievementRegionalPublicId: undefined,
        achievementParticipationPublicId: undefined
	})
    const [isActive, setIsActive] = useState('true')
    const [modal, setModal] = useState({
		show: false,
		type: 'create',
        errorText: '',
        error: false,
		data: {}
	})

    // <--- Table's columns --->
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => ((getKegiatan.data?.pageable?.pageNumber - 1) * getKegiatan.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'Jenis Kegiatan',
            selector: row => row.achievementLibrary.name,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Regional',
            selector: row => row.achievementRegional.name,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Partisipasi',
            selector: row => row.achievementParticipation.name,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: 'Poin',
            selector: row => row.point,
            width: "80px",
            wrap: true,
        },
        {
            name: 'Keterangan',
            selector: row => row.description === '' ? '-' : row.description,
            wrap: true,
            minWidth: '300px'
        },
        {
            name: 'Status',
            cell: data => data?.isActive ? <LabelSuccess text='Aktif' /> : <LabelDanger text='Tidak Aktif' />,
            wrap: true,
            width: "120px" 
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('ACHIEVEMENT_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('ACHIEVEMENT_D') && <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />}
            </>,
        },
    ];

    // <--- useQuery --->
    const getKegiatan = useQuery(
		['kegiatan-list', pagination],
		() => KegiatanApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => { return [] }),
        {enabled: Boolean(pagination?.achievementLibraryPublicId)}
    )

    const getJenisKegiatan = useQuery(
        ['kegiatan-atribut-jenis-kegiatan'],
        () => KegiatanApi.getJenisKegiatan(axiosPrivate, {isActive: true}),
        {enabled: modal.show}
    )

    const getRegional = useQuery(
        ['kegiatan-atribut-regional'],
        () => KegiatanApi.getRegional(axiosPrivate, {isActive: true}),
        {enabled: modal.show}
    )

    const getPartisipasi = useQuery(
        ['kegiatan-atribut-partisipasi'],
        () => KegiatanApi.getPartisipasi(axiosPrivate, {isActive: true}),
        {enabled: modal.show}
    )

    const getJenisKegiatanFilter = useQuery(
        ['kegiatan-siswa-atribut-jenis-kegiatan'],
        () => AchievementApi.getAchievementLibrary(axiosPrivate, { isActive: true, hasAchievement: true })
    )
    
    const getRegionalFilter = useQuery(
        ['kegiatan-siswa-atribut-regional', pagination?.achievementLibraryPublicId],
        () => AchievementApi.getAchievementRegionals(axiosPrivate, { isActive: true, achievementLibraryPublicId: pagination?.achievementLibraryPublicId }),
        {enabled: Boolean(pagination?.achievementLibraryPublicId)}
    )

    const getPartisipasiFilter = useQuery(
        ['kegiatan-siswa-atribut-partisipasi', pagination?.achievementLibraryPublicId, pagination?.achievementRegionalPublicId],
        () => AchievementApi.getAchievementParticipation(axiosPrivate, { isActive: true, achievementLibraryPublicId: pagination?.achievementLibraryPublicId, achievementRegionalPublicId: pagination?.achievementRegionalPublicId }),
        {enabled: Boolean(pagination?.achievementLibraryPublicId && pagination?.achievementRegionalPublicId)}
    )
    
    // <--- Functions --->
	const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }

    const activeDataHandler = (e) => {
        if(e.target.name !== 'all'){
            setPagination({
                ...pagination,
                isActive: e.target.name
            })
            setIsActive(e.target.name)
        } else{
            setPagination({
                ...pagination,
                isActive: undefined
            })
            setIsActive('none')
        }
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data })

    // <--- Form --->
    const formInitialValues = {
		publicId: modal.type !== 'create' ? modal?.data?.publicId ?? "" : undefined,
        point: modal.type !== 'create' ? modal?.data?.point ?? 0 : 0,
		description: modal.type !== 'create' ? modal?.data?.description ?? "" : "",
		achievementLibraryPublicId: modal.type !== 'create' ? modal?.data?.achievementLibrary?.publicId ?? "" : "",
		achievementRegionalPublicId: modal.type !== 'create' ? modal?.data?.achievementRegional?.publicId ?? "" : "",
		achievementParticipationPublicId: modal.type !== 'create' ? modal?.data?.achievementParticipation?.publicId ?? "" : "",
        isActive: modal.type !== 'create' ? modal?.data?.isActive ?? true : true
	}

    const formValidationSchema = Yup.object().shape({
        point: Yup.number()
            .required('Poin Prestasi wajib diisi'),
        achievementLibraryPublicId: Yup.string()
            .required('Jenis Kegiatan wajib dipilih')
            .nullable(),
        achievementRegionalPublicId: Yup.string()
            .required('Regional wajib dipilih')
            .nullable(),
        achievementParticipationPublicId: Yup.string()
            .required('Partisipasi wajib dipilih')
            .nullable()
	})

    // <--- useMutation --->
    const createKegiatan = useMutation((data) => KegiatanApi.create(axiosPrivate, data), {
		onSuccess: () => {
			getKegiatan.refetch()
		}
	})

    const updateKegiatan = useMutation(({data, publicId}) => KegiatanApi.update(axiosPrivate, data, publicId), {
        onSuccess: () => {
			getKegiatan.refetch()
		}
    })

    const deleteKegiatan = useMutation(id => KegiatanApi.delete(axiosPrivate, id), {
		onSuccess: () => {
			getKegiatan.refetch()
		}
	})
	
    return (
        <>
            <div className="sm:flex sm:flex-row sm:space-x-3 mb-5 space-y-3 sm:space-y-0">
                <div className="sm:flex-grow">
                    <Select 
                    label='Jenis Kegiatan'
                    placeholder='Pilih Jenis Kegiatan'
                    defaultValue={getJenisKegiatanFilter?.data?.find(item => item.value === pagination?.achievementLibraryPublicId)}
                    onChange={val => {
                        setPagination({
                        ...pagination,
                        achievementLibraryPublicId: val.value,
                        achievementRegionalPublicId: undefined,
                        achievementParticipationPublicId: undefined
                        })
                    }}
                    options={getJenisKegiatanFilter?.data ?? []}
                    errorFetch={getJenisKegiatanFilter.isError}
                    errorFetchText={getJenisKegiatanFilter?.error?.response?.data?.errorMessage[0]}
                    loading={getJenisKegiatanFilter.isFetching}
                    />
                </div>
                <div className="sm:flex-grow">
                    <Select 
                    label='Regional'
                    placeholder='Pilih Regional'
                    defaultValue={getRegionalFilter?.data?.find(item => item.value === pagination?.achievementRegionalPublicId)}
                    onChange={val => {
                        setPagination({
                        ...pagination,
                        achievementRegionalPublicId: val.value,
                        achievementParticipationPublicId: undefined
                        })
                    }}
                    options={getRegionalFilter?.data ?? []}
                    errorFetch={getRegionalFilter.isError}
                    errorFetchText={getRegionalFilter?.error?.response?.data?.errorMessage[0]}
                    loading={getRegionalFilter.isFetching}
                    disable={!Boolean(pagination?.achievementLibraryPublicId)}
                    />
                </div>
                <div className="sm:flex-grow">
                    <Select 
                    label='Partisipasi'
                    placeholder='Pilih Partisipasi'
                    defaultValue={getPartisipasiFilter?.data?.find(item => item.value === pagination?.achievementParticipationPublicId)}
                    onChange={val => {
                        setPagination({
                        ...pagination,
                        achievementParticipationPublicId: val.value
                        })
                    }}
                    options={getPartisipasiFilter?.data ?? []}
                    errorFetch={getPartisipasiFilter.isError}
                    errorFetchText={getPartisipasiFilter?.error?.response?.data?.errorMessage[0]}
                    loading={getPartisipasiFilter.isFetching}
                    disable={!Boolean(pagination?.achievementLibraryPublicId && pagination?.achievementRegionalPublicId)}
                    />
                </div>
                <div className="grid content-end">
                    <DefaultButton 
                        text='Reset Pencarian'
                        onClick={() => {
                        setPagination({
                            ...pagination,
                            achievementLibraryPublicId: undefined,
                            achievementRegionalPublicId: undefined,
                            achievementParticipationPublicId: undefined
                        })
                        }}
                    />
                </div>
            </div>
            {/* <--- Search input, Create Button, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                permissionCreate='ACHIEVEMENT_C'
                data={getKegiatan.data?.content}
                columns={columns}
                loading={getKegiatan.isFetching}
                error={getKegiatan.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getKegiatan.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
                activeStatusFilter
                activeOnChange={activeDataHandler}
                activeValues={isActive}
                showTable={Boolean(pagination?.achievementLibraryPublicId)}
            />
            
            {pagination?.achievementLibraryPublicId || pagination?.achievementRegionalPublicId || pagination?.achievementParticipationPublicId  ? <></> :
                <DataStatus text='Silahkan memilih pencarian jenis kegiatan terlebih dahulu' />
            }

            {/* <--- MODAL CREATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    createKegiatan.mutateAsync(values).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit, resetForm } = formik

					return (
                        <Modal
                            id='modal-tambah-kegiatan'
                            header='Tambah Kegiatan'
                            size='small'
                            type='create'
                            onHide={() => {
                                resetForm()
                                onHideModalHandler()
                            }}
                            show={Boolean(modal.show && modal.type === 'create')}
                            onSubmit={handleSubmit}
                            isSubmitting={createKegiatan.isLoading}
                        >
                            <FormSection modal={modal} dropdown={{getJenisKegiatan, getRegional, getPartisipasi}} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL UPDATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    updateKegiatan.mutateAsync({
                        data: values,
                        publicId: values.publicId
                    }).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit } = formik

					return (
                        <Modal
                            id='modal-ubah-kegiatan'
                            header='Ubah Kegiatan'
                            size='small'
                            type='update'
                            onHide={onHideModalHandler}
                            show={Boolean(modal.show && modal.type === 'update')}
                            onSubmit={handleSubmit}
                            isSubmitting={updateKegiatan.isLoading}
                        >
                            <FormSection modal={modal} dropdown={{getJenisKegiatan, getRegional, getPartisipasi}} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL DELETE ---> */}
            <Modal
                id='modal-hapus-kegiatan'
                header='Hapus Kegiatan'
                size='small'
                type='delete'
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === 'delete')}
                isSubmitting={deleteKegiatan.isLoading}
                onSubmit={() => {
                    deleteKegiatan.mutateAsync(modal.data.publicId).then((res) => {
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                <DetailSection data={modal} />
            </Modal>
        </>
    )
}