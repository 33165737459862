import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSubscription } from "react-stomp-hooks";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import {
  CancelButton,
  Checkbox,
  CreateButton,
  DatePicker,
  LabelCustom,
  LabelDanger,
  LabelDefault,
  LabelInfo,
  LabelSuccess,
  LabelWarning,
  ListLayout,
  Modal,
  SearchInput,
  ViewButton,
  toastTrigger,
} from "components";
import DashboardPresensiApi from "./__DashboardPresensiApi__";
import {
  FormByPassSection,
  FormPersetujuanMasukKelasSection,
} from "./__DashboardPresensiComponents__";
import { Formik } from "formik";
import {
  formByPassPresensiInitialValues,
  formByPassPresensiValidationSchema,
  formPersetujuanMasukKelasInitialValues,
} from "./__DashboardUtilities__";

const Subscription = () => {
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const axiosPrivate = useAxiosPrivate();

  const [isTerlambat, setIsTerlambat] = useState("none");
  const [verifikasi, isVerifikasi] = useState(false);
  const [updateAttendance, isUpdateAttendance] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "id",
    direction: "DESC",
    date: `${selectedDate.getFullYear()}-${
      selectedDate.getMonth() + 1 < 10
        ? `0${selectedDate.getMonth() + 1}`
        : selectedDate.getMonth() + 1
    }-${
      selectedDate.getDate() < 10
        ? `0${selectedDate.getDate()}`
        : selectedDate.getDate()
    }`,
    flag: undefined,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });
  const [listIdVerifikasi, setListIdVerifikasi] = useState([]);

  const getListPageable = useQuery(
    ["attendance-list", "pageable", pagination],
    () =>
      DashboardPresensiApi.getListPageable(
        axiosPrivate,
        paginationHandler(pagination)
      ),
    {
      enabled: Boolean(updateAttendance),
    }
  );

  const getListTerlambat = useQuery(
    ["terlambat-list", "belum-approved"],
    () =>
      DashboardPresensiApi.getList(axiosPrivate, {
        sortBy: "id",
        direction: "DESC",
        date: `${selectedDate.getFullYear()}-${
          selectedDate.getMonth() + 1 < 10
            ? `0${selectedDate.getMonth() + 1}`
            : selectedDate.getMonth() + 1
        }-${
          selectedDate.getDate() < 10
            ? `0${selectedDate.getDate()}`
            : selectedDate.getDate()
        }`,
        flag: "TERLAMBAT",
        isApproved: false,
      }),
    {
      enabled: Boolean(verifikasi),
      onSuccess: (res) => checkBoxAll(res),
    }
  );

  useSubscription("/all/update-attendance", (message) => {
    if (message.body) {
      isUpdateAttendance(Boolean(message.body));
      if (Boolean(message.body)) {
        getListPageable.refetch();
        getListTerlambat.refetch();
      }
    } else {
      isUpdateAttendance(false);
    }
  });

  const checkBoxAll = (data) => {
    if (listIdVerifikasi?.length === 0) return false;
    return data?.length === listIdVerifikasi?.length;
  };

  const columns = [
    {
      name: "No",
      selector: (_, index) =>
        (getListPageable.data?.pageable?.pageNumber - 1) *
          getListPageable.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis ?? "-",
      width: "120px",
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentName ?? "-",
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.studentClass ?? "-",
      width: "80px",
    },
    {
      name: "Jam",
      selector: (row) => row?.time ?? "-",
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) => convertAttendanceStatus(row?.flag, row?.isApproved),
      width: "250px",
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description ?? "-",
      minWidth: "300px",
      wrap: true,
    },
    {
      name: !verifikasi ? (
        "Aksi"
      ) : (
        <Checkbox
          inputStyle={{
            width: "25px",
            height: "25px",
          }}
          value="all"
          id="all"
          checked={checkBoxAll(getListTerlambat?.data)}
          onChange={(e) => checkboxHandleChange(e)}
          disabled={Boolean(getListTerlambat.isError)}
        />
      ),
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_ATT_A") &&
          data?.flag === "TERLAMBAT" &&
          !Boolean(data?.isApproved) ? (
            !verifikasi ? (
              <CreateButton
                text="Verifikasi"
                onClick={() => onClickVerifikasiButton(data)}
              />
            ) : (
              <Checkbox
                inputStyle={{
                  width: "25px",
                  height: "25px",
                }}
                key={data?.attendanceQrCodePublicId}
                id={data?.attendanceQrCodePublicId}
                checked={listIdVerifikasi?.some(
                  (val) =>
                    val?.qrCodePublicId === data?.attendanceQrCodePublicId
                )}
                onChange={(e) => checkboxHandleChange(e)}
              />
            )
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  const convertAttendanceStatus = (flag, isApproved) => {
    if (flag === "MASUK") {
      return <LabelSuccess text="Tidak Terlambat" />;
    } else if (flag === "TERLAMBAT" && !isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelDanger text="Terlambat" />
          </span>
          <span>
            <LabelWarning text="Belum Disetujui" />
          </span>
        </>
      );
    } else if (flag === "TERLAMBAT" && isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelDanger text="Terlambat" />
          </span>
          <span>
            <LabelSuccess text="Disetujui" />
          </span>
        </>
      );
    } else if (flag === "IZIN" && isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelWarning text="Izin" />
          </span>
          <span>
            <LabelSuccess text="Disetujui" />
          </span>
        </>
      );
    } else if (flag === "MASUK_KELUAR" && isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelDefault text="Izin Meninggalkan Kelas" />
          </span>
          <span>
            <LabelSuccess text="Disetujui" />
          </span>
        </>
      );
    } else if (flag === "IZIN_TERLAMBAT" && isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelInfo text="Izin Terlambat" />
          </span>
          <span>
            <LabelSuccess text="Disetujui" />
          </span>
        </>
      );
    } else if (flag === "IZIN_SAKIT" && isApproved) {
      return (
        <>
          <span className="mr-2">
            <LabelCustom text="Izin Sakit" className="bg-pink-500" />
          </span>
          <span>
            <LabelSuccess text="Disetujui" />
          </span>
        </>
      );
    }
    return <></>;
  };

  const checkboxHandleChange = (e) => {
    const { id, checked } = e.target;
    let tampArrayIdVerifikasi = [];

    if (id === "all") {
      if (checked) {
        getListTerlambat?.data?.forEach((val) => {
          tampArrayIdVerifikasi.push({
            value: true,
            qrCodePublicId: val?.attendanceQrCodePublicId,
          });
        });
      }
    } else {
      tampArrayIdVerifikasi = [...listIdVerifikasi];
      if (checked) {
        tampArrayIdVerifikasi.push({
          value: true,
          qrCodePublicId: id,
        });
      } else {
        tampArrayIdVerifikasi = listIdVerifikasi.filter(
          (val) => val?.qrCodePublicId !== id
        );
      }
    }

    setListIdVerifikasi(tampArrayIdVerifikasi);
  };

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        flag: e.target.name === "true" ? "TERLAMBAT" : "MASUK",
      });
      setIsTerlambat(e.target.name);
    } else {
      setPagination({
        ...pagination,
        flag: undefined,
      });
      setIsTerlambat("none");
    }
  };

  const onChangeDate = (date) => {
    setSelectedDate(new Date(date));
    setPagination({
      ...pagination,
      date: `${new Date(date).getFullYear()}-${
        new Date(date).getMonth() + 1 < 10
          ? `0${new Date(date).getMonth() + 1}`
          : new Date(date).getMonth() + 1
      }-${
        new Date(date).getDate() < 10
          ? `0${new Date(date).getDate()}`
          : new Date(date).getDate()
      }`,
    });
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickVerifikasiButton = (data) =>
    setModal({
      show: true,
      type: "verifikasi",
      errorText: "",
      error: false,
      data: data,
    });

  const onClickSimpanListVerifikasiButton = () => {
    if (listIdVerifikasi.length === 0) {
      toastTrigger({
        variant: "error",
        message: "Wajib centang minimal 1 siswa untuk persetujuan masuk kelas!",
      });
    } else {
      setModal({
        show: true,
        type: "verifikasi-list",
        errorText: "",
        error: false,
        data: listIdVerifikasi,
      });
    }
  };

  const onClickByPassButton = () =>
    setModal({
      show: true,
      type: "bypass",
      errorText: "",
      error: false,
      data: {},
    });

  const verifikasiAbsen = useMutation(
    ({ attendanceQrCodePublicId, flag }) =>
      DashboardPresensiApi.verifikasi(axiosPrivate, attendanceQrCodePublicId, {
        value: true,
        flag: flag,
      }),
    {
      onSuccess: () => getListPageable.refetch(),
    }
  );

  const verifikasiListAbsen = useMutation(
    (data) => DashboardPresensiApi.listVerifikasi(axiosPrivate, data),
    {
      onSuccess: () => {
        setListIdVerifikasi([]);
        getListTerlambat.remove();
        getListTerlambat.refetch();
      },
    }
  );

  const byPassPresensi = useMutation(
    ({ studentPublicId, date, data }) =>
      DashboardPresensiApi.byPassPresensi(
        axiosPrivate,
        studentPublicId,
        date,
        data
      ),
    {
      onSuccess: () => {
        isVerifikasi(false);
        getListPageable.refetch();
      },
    }
  );

  return (
    <>
      <div className="flex flex-row items-end">
        <div className="flex flex-row flex-1 items-end">
          <div className="mr-5">
            <DatePicker
              label="Tanggal"
              dateFormat="dd-MM-yyyy"
              selected={selectedDate}
              onChange={onChangeDate}
            />
          </div>
          <div className="flex-1">
            <SearchInput onChange={searchDataHandler} />
          </div>
        </div>
        <div>
          {permissions.includes("SCH_ATT_C") && (
            <ViewButton
              type="button"
              text="By Pass Presensi"
              onClick={onClickByPassButton}
            />
          )}
        </div>
      </div>
      <ListLayout
        permissions={permissions}
        data={
          verifikasi ? getListTerlambat.data : getListPageable.data?.content
        }
        columns={columns}
        loading={
          verifikasi ? getListTerlambat.isFetching : getListPageable.isFetching
        }
        error={
          verifikasi
            ? getListTerlambat.error?.response?.data?.errorMessage[0]
            : getListPageable.error?.response?.data?.errorMessage[0]
        }
        pagination={!verifikasi}
        totalRows={getListPageable.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        activeStatusFilter
        activeOnChange={activeDataHandler}
        activeValues={isTerlambat}
        activeText={["Semua", "Terlambat", "Tidak Terlambat"]}
        customLeftTopSection={() => <></>}
        customRightStatusSection={() => {
          return (
            permissions.includes("SCH_ATT_A") &&
            (!verifikasi ? (
              <>
                <CreateButton
                  type="button"
                  text="Verifikasi"
                  onClick={() => {
                    isVerifikasi(true);
                    getListTerlambat.refetch();
                  }}
                />
              </>
            ) : (
              <div className="flex flex-row">
                <CreateButton
                  text="Simpan"
                  onClick={onClickSimpanListVerifikasiButton}
                  className="mr-2"
                />
                <CancelButton
                  text="Batal"
                  onClick={() => {
                    isVerifikasi(false);
                    setListIdVerifikasi([]);
                    getListPageable.refetch();
                  }}
                />
              </div>
            ))
          );
        }}
      />

      {/* <--- MODAL VERIFIKASI PER SISWA ---> */}
      {modal.type === "verifikasi" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formPersetujuanMasukKelasInitialValues(modal?.data)}
          onSubmit={(values, { resetForm }) => {
            verifikasiAbsen
              .mutateAsync({
                attendanceQrCodePublicId: modal.data?.attendanceQrCodePublicId,
                flag: values?.flag,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm } = formik;
            return (
              <Modal
                id="modal-verifikasi-per-siswa"
                header="Persetujuan Masuk Kelas"
                size="small"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "verifikasi")}
                isSubmitting={verifikasiAbsen.isLoading}
                onSubmit={handleSubmit}
              >
                <FormPersetujuanMasukKelasSection />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL VERIFIKASI LIST SISWA ---> */}
      <Modal
        id="modal-konfirmasi-list-verifikasi-siswa"
        header="Konfirmasi Persetujuan Masuk Kelas"
        size="small"
        type="create"
        onHide={onHideModalHandler}
        show={Boolean(modal.show && modal.type === "verifikasi-list")}
        isSubmitting={verifikasiListAbsen.isLoading}
        onSubmit={() =>
          verifikasiListAbsen
            .mutateAsync(listIdVerifikasi)
            .then((res) => {
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            })
        }
      >
        <label>
          Konfirmasi persetujuan masuk kelas untuk{" "}
          <b className="text-red-600">{listIdVerifikasi.length} siswa</b>
        </label>
      </Modal>

      {/* <--- MODAL BY PASS PRESENSI ---> */}
      <Formik
        enableReinitialize
        initialValues={formByPassPresensiInitialValues()}
        validationSchema={formByPassPresensiValidationSchema}
        onSubmit={(values, { resetForm }) => {
          byPassPresensi
            .mutateAsync({
              studentPublicId: values?.studentPublicId,
              date: `${selectedDate.getFullYear()}-${
                selectedDate.getMonth() + 1 < 10
                  ? `0${selectedDate.getMonth() + 1}`
                  : selectedDate.getMonth() + 1
              }-${
                selectedDate.getDate() < 10
                  ? `0${selectedDate.getDate()}`
                  : selectedDate.getDate()
              }`,
              data:
                values?.flag === "-"
                  ? undefined
                  : {
                      flag: values?.flag,
                      description: values?.description,
                    },
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;
          return (
            <Modal
              id="modal-by-pass-presensi"
              header={`By Pass Presensi (${
                selectedDate.getDate() < 10
                  ? `0${selectedDate.getDate()}`
                  : selectedDate.getDate()
              }-${
                selectedDate.getMonth() + 1 < 10
                  ? `0${selectedDate.getMonth() + 1}`
                  : selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()})`}
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "bypass")}
              onSubmit={handleSubmit}
              isSubmitting={byPassPresensi.isLoading}
            >
              <FormByPassSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export const DashboardPresensi = () => {
  return <Subscription />;
};
