const url = window._env_.REACT_APP_API_AUTH_URL

class UbahPasswordApi {
  async checkPasswordIsValid(axiosPrivate, data) {
    const fetch = await axiosPrivate.get(`${url}/v1/users/password/${data}/isValid`)
    return fetch.data.payload
  }

  update(axiosPrivate, data) {
    return axiosPrivate.patch(`${url}/v1/users/password`, data)
  }
}

export default new UbahPasswordApi()