export const RadioButtonWrapper = ({
    id,
    label,
    children
}) => {
    return (
        <div>
            <div id={`${id}-radio-group`}>
                <small>{label}</small>
            </div>
            {children}
        </div>
    )
}
