const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KamusHariLiburApi {
  async getList(axiosPrivate, params) {
      const fetch = await axiosPrivate.get(`${url}/v1/library-holy-day/pageable`, { params })
      return fetch.data.payload
  }

  create(axiosPrivate, data) {
      return axiosPrivate.post(`${url}/v1/library-holy-day`, data)
  }

  update(axiosPrivate, data, id) {
      return axiosPrivate.put(`${url}/v1/library-holy-day/${id}`, data)
  }

  delete(axiosPrivate, id) {
      return axiosPrivate.delete(`${url}/v1/library-holy-day/${id}`)
  }
}

export default new KamusHariLiburApi()