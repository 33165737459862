import React from "react";
import { useAxiosPrivate } from "utilities";
import { useQuery } from "react-query";
import SetupKebijakanPrivasiApi from "../__SetupKebijakanPrivasiApi__";
import { Accordion, DataStatus } from "components";

export const PreviewSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const getCurrent = useQuery(
    ["kebijakan-privasi", "current"],
    () => SetupKebijakanPrivasiApi.getCurrent(axiosPrivate),
    {
      enabled: Boolean(modal.show && modal.type === "preview"),
    }
  );

  if (getCurrent.isError || getCurrent.isFetching) {
    return (
      <DataStatus
        loading={getCurrent.isFetching}
        loadingText="Memuat data kebijakan privasi..."
        text="Data kebijakan privasi gagal dimuat"
      />
    );
  } else {
    return <Accordion data={getCurrent?.data} />;
  }
};
