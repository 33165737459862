export const Button = ({
    children,
    type,
    onClick,
    disabled,
    loading,
    className
}) => {
    return (
        <button 
            type={type} 
            onClick={onClick} 
            disabled={loading ? true : disabled}
            className={className}
        >
            {children}
        </button>
    )
}