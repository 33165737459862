import { Input, Label } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { hexToRGB } from "utilities";

export const FormSection = ({ modal }) => {
  const { values, errors, touched, handleChange, setValues } =
    useFormikContext();

  return (
    <div className="space-y-3">
      <Input
        label="Nama Kategori Blog"
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
        errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      <Input
        label="Kode Kategori Blog"
        name="code"
        type="text"
        value={values.code}
        onChange={handleChange}
        error={Boolean(errors.code && touched.code)}
        errorText={Boolean(errors.code && touched.code) && errors.code}
      />
      <Input
        label="Warna Kategori Blog"
        name="colorCode"
        type="color"
        value={values.colorCode}
        onChange={(e) => setValues({ ...values, colorCode: e.target.value })}
        error={Boolean(errors.colorCode && touched.colorCode)}
        errorText={
          Boolean(errors.colorCode && touched.colorCode) && errors.colorCode
        }
      />
      <div>
        <label
          className={`text-sm py-1 px-3 rounded-md font-bold w-fit`}
          style={{
            color: values.colorCode,
            backgroundColor: hexToRGB(values.colorCode, 0.2),
          }}
        >
          {values.name === "" ? "Contoh" : values.name}
        </label>
      </div>
      {modal.error && <Label type="error" text={modal.errorText} />}
    </div>
  );
};
