import { mappingDataPeriode } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class SchoolsApi {
    async getAccount(axiosPrivate) {
        const fetch = await axiosPrivate.get(`${url}/v1/schools/account`)
        return fetch.data.payload
    }

    async getTahunAjaran(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/school-periode`, { params })
        return mappingDataPeriode(fetch.data.payload)
    }
}

export default new SchoolsApi()