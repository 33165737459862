
import { Input, SaveButton, Sidebar } from "components"
import { Formik } from "formik"
import { useMutation } from "react-query"
import { errorFetching, infoFetching, successFetching, useAxiosPrivate, useLogout } from "utilities"
import { formInitialValues, formValidationSchema } from "./__UbahPasswordUtilities__"
import { PengaturanMenus } from "../PengaturanMenus"
import UbahPasswordApi from "./__UbahPasswordApi__"

export const UbahPassword = () => {
    const axiosPrivate = useAxiosPrivate()
    const logout = useLogout()
    const checkPasswordIsValid = useMutation((data) => UbahPasswordApi.checkPasswordIsValid(axiosPrivate, data))
    const update = useMutation(({data}) => UbahPasswordApi.update(axiosPrivate, data))
    const menus = PengaturanMenus
    
    return (
        <Sidebar name='Ubah Password' menus={menus}>
            <Formik
                enableReinitialize
                initialValues={formInitialValues()}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    checkPasswordIsValid.mutateAsync(values.oldPassword).then(() => {
                        update.mutateAsync({
                            data: values
                        }).then(res => {
                            resetForm()
                            successFetching(res)
                            infoFetching('Silahkan login ulang')
                            logout()
                        }).catch(err => {
                            errorFetching(err)
                        })
                    }).catch(err => {
                        errorFetching(err)
                    })
                }}
            >
                {(formik) => {
                    const { handleSubmit, values, handleChange, errors, touched } = formik

                    return (
                        <div className="space-y-5">
                            <Input 
                                label='Masukkan Password Lama'
                                name='oldPassword'
                                type='password'
                                value={values?.oldPassword}
                                onChange={handleChange}
                                error={Boolean(errors.oldPassword && touched.oldPassword)}
                                errorText={Boolean(errors.oldPassword && touched.oldPassword) && errors.oldPassword}
                                wrapperClassName='sm:w-1/3'
                            />
                            <Input 
                                label='Masukkan Password Baru'
                                name='password'
                                type='password'
                                value={values?.password}
                                onChange={handleChange}
                                error={Boolean(errors.password && touched.password)}
                                errorText={Boolean(errors.password && touched.password) && errors.password}
                                wrapperClassName='sm:w-1/3'
                            />
                            <SaveButton 
                                icon
                                type='submit'
                                disabled={Boolean(checkPasswordIsValid.isLoading) || Boolean(update.isLoading)}
                                loading={Boolean(checkPasswordIsValid.isLoading) || Boolean(update.isLoading)}
                                onClick={handleSubmit}
                                className='mt-3 w-full sm:w-auto'
                            />
                        </div>
                    )
                }}
            </Formik>
        </Sidebar>
    )
}
