import { ClassroomsApi, GradeApi, SchoolsApi } from "api";
import { Checkbox, Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";

export const ContentNaikKelas = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched } = useFormikContext();

  // <--- useQuery --->
  const getAkunSekolah = useQuery(
    ["naik-kelas-atribut-akun-sekolah"],
    () => SchoolsApi.getAccount(axiosPrivate),
    {
      enabled: modal.show,
      onSuccess: (res) => {
        setValues({
          ...values,
          periode: res?.periode,
        });
      },
    }
  );

  const getTingkatanKelas = useQuery(["tingkatan-kelas-list"], () =>
    GradeApi.getGrade(axiosPrivate, {
      isActive: true,
      sortBy: "name",
      direction: "ASC",
    })
  );

  const getKelas = useQuery(
    ["kelas-list", values.destinationGradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values.destinationGradePublicId,
        sortBy: "name",
        direction: "ASC",
      }),
    { enabled: Boolean(values.destinationGradePublicId !== "") }
  );

  return (
    <div className="space-y-3">
      <div className="flex flex-row">
        <Input
          label="Tahun Ajaran"
          value={
            getAkunSekolah.isFetching
              ? "Memuat data tahun ajaran..."
              : getAkunSekolah?.data?.periode
          }
          wrapperClassName="flex-1 mr-1"
          disabled
        />
        <Input
          label="Kelas"
          value={`${values?.tingkatanKelas} ${values?.kelas}`}
          wrapperClassName="flex-1 ml-1"
          disabled
        />
      </div>
      {/* <Checkbox 
        label='Lulus'
        name='isGraduated'
        checked={values?.isGraduated}
        onChange={(e) => {
          const { checked } = e.target

          const tampDestination = {
            destinationClassroomPublicId: '',
            destinationGradePublicId: ''
          }

          if(checked) {
            tampDestination.destinationClassroomPublicId = values.originClassroomPublicId
            tampDestination.destinationGradePublicId = values.originClassroomPublicId
          } else {
            tampDestination.destinationClassroomPublicId = ''
            tampDestination.destinationGradePublicId = ''
          }

          setValues({
            ...values, 
            ...tampDestination,
            isGraduated: checked,
          })
        }}
      /> */}
      {!values?.isGraduated && (
        <>
          <Select
            label="Tingkatan Kelas"
            placeholder="Pilih Tingkatan Kelas"
            defaultValue={getTingkatanKelas?.data?.find(
              (item) => item.value === values.destinationGradePublicId
            )}
            onChange={(val) =>
              setValues({
                ...values,
                destinationGradePublicId: val.value,
                destinationClassroomPublicId: "",
              })
            }
            options={getTingkatanKelas?.data ?? []}
            error={Boolean(
              errors?.destinationGradePublicId &&
                touched?.destinationGradePublicId
            )}
            errorText={
              Boolean(
                errors?.destinationGradePublicId &&
                  touched?.destinationGradePublicId
              ) && errors?.destinationGradePublicId
            }
            errorFetch={getTingkatanKelas.isError}
            errorFetchText={
              getTingkatanKelas?.error?.response?.data?.errorMessage[0]
            }
            loading={getTingkatanKelas.isFetching}
          />
          <Select
            label="Kelas Tujuan"
            placeholder="Pilih Kelas Tujuan"
            defaultValue={getKelas?.data?.find(
              (item) => item.value === values?.destinationClassroomPublicId
            )}
            onChange={(val) =>
              setValues({
                ...values,
                destinationClassroomPublicId: val.value,
              })
            }
            options={
              getKelas?.data?.filter(
                (val) => val.value !== values.originClassroomPublicId
              ) ?? []
            }
            error={Boolean(
              errors?.destinationClassroomPublicId &&
                touched?.destinationClassroomPublicId
            )}
            errorText={
              Boolean(
                errors?.destinationClassroomPublicId &&
                  touched?.destinationClassroomPublicId
              ) && errors?.destinationClassroomPublicId
            }
            errorFetch={getKelas.isError}
            errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
            loading={getKelas.isFetching}
            disable={Boolean(values.destinationGradePublicId === "")}
          />
        </>
      )}
    </div>
  );
};
