const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KegiatanKesiswaanApi {
  async getListPrestasi(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/achievements/pageable`, { params })
    return fetch.data.payload
  }

  async getDetailPrestasiSiswa(axiosPrivate, studentPublicId, studentHasAchievementPublicId) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}`)
    return fetch.data.payload
  }

  async getAchievementLogsPrestasiSiswa(axiosPrivate, studentPublicId, studentHasAchievementPublicId, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}/logs/pageable`, { params })
    return fetch.data.payload
  }

  updateStatusPrestasi(axiosPrivate, studentPublicId, studentHasAchievementPublicId, data) {
    return axiosPrivate.patch(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}/status`, data)
  }
}

export default new KegiatanKesiswaanApi();