import { RegionalApi, TeacherApi } from "api"
import { Input, Select, TextArea } from "components"
import { useFormikContext } from "formik"
import { useQuery } from "react-query"
import { useAxiosPrivate } from "utilities"

export const FormSection = () => {
  const axiosPrivate = useAxiosPrivate()
  const { values, setValues, errors, touched, handleChange } = useFormikContext()

  // <--- useQuery --
  const getTeachers = useQuery(
    ['admin-atribut-guru'],
    () => TeacherApi.getTeachers(axiosPrivate, { isActive: true })
  )

  const getProvinsi = useQuery(
    ['admin-atribut-provinsi'],
    () => RegionalApi.getProvinsi(axiosPrivate)
  )

  const getKabupaten = useQuery(
    ['admin-atribut-kabupaten', values?.address?.provinsiId],
    () => RegionalApi.getKabupaten(axiosPrivate, {provinsiId: values?.address?.provinsiId}),
    {enabled: Boolean(values?.address?.provinsiId)}
  )

  const getKecamatan = useQuery(
    ['admin-atribut-kecamatan', values?.address?.kabupatenId],
    () => RegionalApi.getKecamatan(axiosPrivate, {kabupatenId: values?.address?.kabupatenId}),
    {enabled: Boolean(values?.address?.kabupatenId)}
  )

  const getDesa = useQuery(
    ['admin-atribut-desa', values?.address?.kecamatanId],
    () => RegionalApi.getDesa(axiosPrivate, {kecamatanId: values?.address?.kecamatanId}),
    {enabled: Boolean(values?.address?.kecamatanId)}
  )

  return (
    <div className="space-y-3">
      <Input 
        label='Nama Sekolah' 
        name='name'
        type='text'
        value={values?.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
        errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      <Input 
        label='Tahun Ajaran'
        name='periode'
        type='text'
        value={values?.periode}
        onChange={handleChange}
        error={Boolean(errors.periode && touched.periode)}
        errorText={Boolean(errors.periode && touched.periode) && errors.periode}
        disabled
      />
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <Input 
          label='Website'
          name='website'
          type='text'
          value={values?.website}
          onChange={handleChange}
          error={Boolean(errors.website && touched.website)}
          errorText={Boolean(errors.website && touched.website) && errors.website}
          wrapperClassName='sm:mr-2 sm:flex-1'
        />
        <Input 
          label='Email'
          name='email'
          type='email'
          value={values?.email}
          onChange={handleChange}
          error={Boolean(errors.email && touched.email)}
          errorText={Boolean(errors.email && touched.email) && errors.email}
          wrapperClassName='sm:ml-2 sm:flex-1'
        />
      </div>
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <Input 
          label='Telepon'
          name='telp'
          type='text'
          value={values?.telp}
          onChange={handleChange}
          error={Boolean(errors.telp && touched.telp)}
          errorText={Boolean(errors.telp && touched.telp) && errors.telp}
          wrapperClassName='sm:mr-2 sm:flex-1'
        />
        <Input 
          label='Fax'
          name='fax'
          type='text'
          value={values?.fax}
          onChange={handleChange}
          error={Boolean(errors.fax && touched.fax)}
          errorText={Boolean(errors.fax && touched.fax) && errors.fax}
          wrapperClassName='sm:ml-2 sm:flex-1'
        />
      </div>
      <Select 
        label='Kepala Sekolah'
        placeholder='Pilih Kepala Sekolah'
        name='principalPublicId'
        defaultValue={getTeachers?.data?.find(item => item.value === values.principalPublicId)}
        onChange={val => setValues({...values, principalPublicId: val.value})}
        options={getTeachers?.data ?? []}
        error={Boolean(errors.principalPublicId && touched.principalPublicId)}
        errorText={Boolean(errors.principalPublicId && touched.principalPublicId) && errors.principalPublicId}
        errorFetch={getTeachers.isError}
        errorFetchText={getTeachers?.error?.response?.data?.errorMessage[0]}
        loading={getTeachers.isFetching}
      />
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <div className="sm:mr-2 sm:flex-1">
          <Select 
            label='Wakil Kepala Sekolah Bidang Kurikulum'
            placeholder='Pilih Wakil Kepala Sekolah Bidang Kurikulum'
            name='vicePrincipalOfAcademicPublicId'
            defaultValue={getTeachers?.data?.find(item => item.value === values.vicePrincipalOfAcademicPublicId)}
            onChange={val => setValues({...values, vicePrincipalOfAcademicPublicId: val.value})}
            options={getTeachers?.data ?? []}
            error={Boolean(errors.vicePrincipalOfAcademicPublicId && touched.vicePrincipalOfAcademicPublicId)}
            errorText={Boolean(errors.vicePrincipalOfAcademicPublicId && touched.vicePrincipalOfAcademicPublicId) && errors.vicePrincipalOfAcademicPublicId}
            errorFetch={getTeachers.isError}
            errorFetchText={getTeachers?.error?.response?.data?.errorMessage[0]}
            loading={getTeachers.isFetching}
          />
        </div>
        <div className="sm:ml-2 sm:flex-1">
          <Select 
            label='Wakil Kepala Sekolah Bidang Kesiswaan'
            placeholder='Pilih Wakil Kepala Sekolah Bidang Kesiswaan'
            name='vicePrincipalOfStudentPublicId'
            defaultValue={getTeachers?.data?.find(item => item.value === values.vicePrincipalOfStudentPublicId)}
            onChange={val => setValues({...values, vicePrincipalOfStudentPublicId: val.value})}
            options={getTeachers?.data ?? []}
            error={Boolean(errors.vicePrincipalOfStudentPublicId && touched.vicePrincipalOfStudentPublicId)}
            errorText={Boolean(errors.vicePrincipalOfStudentPublicId && touched.vicePrincipalOfStudentPublicId) && errors.vicePrincipalOfStudentPublicId}
            errorFetch={getTeachers.isError}
            errorFetchText={getTeachers?.error?.response?.data?.errorMessage[0]}
            loading={getTeachers.isFetching}
          />
        </div>
      </div>
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <div className="sm:mr-2 sm:flex-1">
          <Select 
            label='Wakil Kepala Sekolah Bidang Sarana dan Prasarana'
            placeholder='Pilih Wakil Kepala Sekolah Bidang Sarana dan Prasarana'
            name='vicePrincipalOfFinanceAndOperationPublicId'
            defaultValue={getTeachers?.data?.find(item => item.value === values.vicePrincipalOfFinanceAndOperationPublicId)}
            onChange={val => setValues({...values, vicePrincipalOfFinanceAndOperationPublicId: val.value})}
            options={getTeachers?.data ?? []}
            error={Boolean(errors.vicePrincipalOfFinanceAndOperationPublicId && touched.vicePrincipalOfFinanceAndOperationPublicId)}
            errorText={Boolean(errors.vicePrincipalOfFinanceAndOperationPublicId && touched.vicePrincipalOfFinanceAndOperationPublicId) && errors.vicePrincipalOfFinanceAndOperationPublicId}
            errorFetch={getTeachers.isError}
            errorFetchText={getTeachers?.error?.response?.data?.errorMessage[0]}
            loading={getTeachers.isFetching}
          />
        </div>
        <div className="sm:ml-2 sm:flex-1">
          <Select 
            label='Wakil Kepala Sekolah Bidang Hubungan Masyarakat'
            placeholder='Pilih Wakil Kepala Sekolah Bidang Hubungan Masyarakat'
            name='vicePrincipalOfPublicRelationAndPartnerPublicId'
            defaultValue={getTeachers?.data?.find(item => item.value === values.vicePrincipalOfPublicRelationAndPartnerPublicId)}
            onChange={val => setValues({...values, vicePrincipalOfPublicRelationAndPartnerPublicId: val.value})}
            options={getTeachers?.data ?? []}
            error={Boolean(errors.vicePrincipalOfPublicRelationAndPartnerPublicId && touched.vicePrincipalOfPublicRelationAndPartnerPublicId)}
            errorText={Boolean(errors.vicePrincipalOfPublicRelationAndPartnerPublicId && touched.vicePrincipalOfPublicRelationAndPartnerPublicId) && errors.vicePrincipalOfPublicRelationAndPartnerPublicId}
            errorFetch={getTeachers.isError}
            errorFetchText={getTeachers?.error?.response?.data?.errorMessage[0]}
            loading={getTeachers.isFetching}
          />
        </div>
      </div>
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <div className="sm:mr-2 sm:flex-1">
          <Select 
            label='Provinsi'
            placeholder='Pilih Provinsi'
            name='provinsiId'
            defaultValue={getProvinsi?.data?.find(item => item.value === values?.address?.provinsiId)}
            onChange={val => setValues({
              ...values, 
              address: { 
                ...values.address, 
                provinsiId: val.value,
                kabupatenId: '',
                kecamatanId: '',
                desaId: ''
              }
            })}
            options={getProvinsi?.data ?? []}
            error={Boolean(errors?.address?.provinsiId && touched?.address?.provinsiId)}
            errorText={Boolean(errors?.address?.provinsiId && touched?.address?.provinsiId) && errors?.address?.provinsiId}
            errorFetch={getProvinsi.isError}
            errorFetchText={getProvinsi?.error?.response?.data?.errorMessage[0]}
            loading={getProvinsi.isFetching}
          />
        </div>
        <div className="sm:ml-2 sm:flex-1">
          <Select 
            label='Kabupaten'
            placeholder='Pilih Kabupaten'
            name='kabupatenId'
            defaultValue={getKabupaten?.data?.find(item => item.value === values?.address?.kabupatenId)}
            onChange={val => setValues({
              ...values, 
              address: { 
                ...values.address, 
                kabupatenId: val.value,
                kecamatanId: '',
                desaId: ''
              }
            })}
            options={getKabupaten?.data ?? []}
            error={Boolean(errors?.address?.kabupatenId && touched?.address?.kabupatenId)}
            errorText={Boolean(errors?.address?.kabupatenId && touched?.address?.kabupatenId) && errors?.address?.kabupatenId}
            errorFetch={getKabupaten.isError}
            errorFetchText={getKabupaten?.error?.response?.data?.errorMessage[0]}
            loading={getKabupaten.isFetching}
            disable={!Boolean(values?.address?.provinsiId)}
          />
        </div>
      </div>
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <div className="sm:mr-2 sm:flex-1">
          <Select 
            label='Kecamatan'
            placeholder='Pilih Kecamatan'
            name='kecamatanId'
            defaultValue={getKecamatan?.data?.find(item => item.value === values?.address?.kecamatanId)}
            onChange={val => setValues({
              ...values, 
              address: { 
                ...values.address, 
                kecamatanId: val.value,
                desaId: ''
              }
            })}
            options={getKecamatan?.data ?? []}
            error={Boolean(errors?.address?.kecamatanId && touched?.address?.kecamatanId)}
            errorText={Boolean(errors?.address?.kecamatanId && touched?.address?.kecamatanId) && errors?.address?.kecamatanId}
            errorFetch={getKecamatan.isError}
            errorFetchText={getKecamatan?.error?.response?.data?.errorMessage[0]}
            loading={getKecamatan.isFetching}
            disable={!Boolean(values?.address?.kabupatenId)}
          />
        </div>
        <div className="sm:ml-2 sm:flex-1">
          <Select 
            label='Desa'
            placeholder='Pilih Desa'
            name='desaId'
            defaultValue={getDesa?.data?.find(item => item.value === values?.address?.desaId)}
            onChange={val => setValues({...values, address: { ...values.address, desaId: val.value }})}
            options={getDesa?.data ?? []}
            error={Boolean(errors?.address?.desaId && touched?.address?.desaId)}
            errorText={Boolean(errors?.address?.desaId && touched?.address?.desaId) && errors?.address?.desaId}
            errorFetch={getDesa.isError}
            errorFetchText={getDesa?.error?.response?.data?.errorMessage[0]}
            loading={getDesa.isFetching}
            disable={!Boolean(values?.address?.kecamatanId)}
          />
        </div>
      </div>
      <Input 
        label='Kode Pos'
        name='postalCode'
        type='text'
        value={values?.postalCode}
        onChange={handleChange}
        error={Boolean(errors.postalCode && touched.postalCode)}
        errorText={Boolean(errors.postalCode && touched.postalCode) && errors.postalCode}
        wrapperClassName='sm:mr-2 sm:flex-1'
      />
      <TextArea 
        label='Alamat'
        name='street'
        value={values?.street}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.street && touched.street)}
        errorText={Boolean(errors.street && touched.street) && errors.street}
      />
    </div>
  )
}
