/*
Alert merupakan komponen yang digunakan untuk menampilkan pemberitahuan.
message type berisi: 
    1. error
    2. success
    3. warning
*/

export const Alert = ({
    message,
    messageType,
    hidden,
    hideMessage,
    className,
    hideCloseButton
}) => {
    const divClassName = 'max-w-lg py-4 px-10 rounded-md border-[1px]'
    const divErrorClassName = `${divClassName} bg-rose-100 border-rose-200`
    const divWarningClassName = `${divClassName} bg-yellow-100 border-yellow-200`
    const buttonClassName = 'float-right font-bold'
    const buttonErrorClassName = `${buttonClassName} text-red-500`
    const buttonWarningClassName = `${buttonClassName} text-yellow-500`

    return (
        <div className={`${messageType === 'error' ? divErrorClassName : messageType === 'warning' ? divWarningClassName : ''} ${className}`} hidden={hidden}>
            <button type='button' onClick={hideMessage} className={`${messageType === 'error' ? buttonErrorClassName : messageType === 'warning' ? buttonWarningClassName : ''} ${hideCloseButton ? 'hidden' : ''}`}>X</button>
            <h5>{message}</h5>
        </div>
    )
}