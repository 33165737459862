import { useFormikContext } from "formik";
import { Input, InputEditor } from "components";

export const FormSection = () => {
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  return (
    <div className="space-y-3">
      <Input
        label="Judul"
        name="title"
        type="text"
        value={values?.title}
        onChange={handleChange}
        error={Boolean(errors.title && touched.title)}
        errorText={Boolean(errors.title && touched.title) && errors.title}
      />
      <Input
        label="Urutan List"
        name="index"
        type="number"
        value={values?.index}
        onChange={handleChange}
        error={Boolean(errors.index && touched.index)}
        errorText={Boolean(errors.index && touched.index) && errors.index}
      />
      <InputEditor
        label="Konten"
        value={values?.body}
        onChange={(val) => setValues({ ...values, body: val })}
        error={Boolean(errors.body && touched.body)}
        errorText={Boolean(errors.body && touched.body) && errors.body}
      />
    </div>
  );
};
