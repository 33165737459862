import { BeatLoader } from 'react-spinners'

export const DataStatus = ({
    text,
    loading,
    loadingText
}) => {
    return (
        <div className={`flex flex-1 flex-col overflow-y-auto justify-center items-center p-10`}>
            {loading && <BeatLoader color={"#5D5FEF"} loading={loading} size={10} margin={2} />}
            <span className={`text-sm font-semibold mt-3 text-center`}>
                {loading ? loadingText : text}
            </span>
        </div>
    )
}
