export const formInitialValues = (data) => ({
  publicId: data?.publicId ?? '',
  name: data?.name ?? '',
  periode: data?.periode ?? '',
  description: data?.description ?? '',
  email: data?.email ?? '',
  fax: data?.fax ?? '',
  telp: data?.telp ?? '',
  website: data?.website ?? '',
  principalPublicId: data?.principal?.publicId ?? '',
  vicePrincipalOfAcademicPublicId: data?.vicePrincipalOfAcademic?.publicId ?? '',
  vicePrincipalOfStudentPublicId: data?.vicePrincipalOfStudent?.publicId ?? '',
  vicePrincipalOfFinanceAndOperationPublicId: data?.vicePrincipalOfFinanceAndOperation?.publicId ?? '',
  vicePrincipalOfPublicRelationAndPartnerPublicId: data?.vicePrincipalOfPublicRelationAndPartner?.publicId ?? '',
  isActive: true,
  street: data?.address?.street ?? '',
  postalCode: data?.address?.postalCode ?? '',
  address: {
    desaId: data?.address?.desa?.id ?? '',
    kecamatanId: data?.address?.desa?.kecamatan?.id ?? '',
    kabupatenId: data?.address?.desa?.kecamatan?.kabupaten?.id ?? '',
    provinsiId: data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.id ?? '',
    street: data?.address?.street ?? '',
    postalCode: data?.address?.postalCode ?? '',
  }
})