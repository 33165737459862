const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class PelanggaranSiswaApi {
  async getListPelanggaranPageable(axiosPrivate, params){
    const fetch = await axiosPrivate.get(`${url}/v1/students/violations/pageable`, { params })
    return fetch.data.payload
  }

  async getListPelanggaran(axiosPrivate, params){
    const fetch = await axiosPrivate.get(`${url}/v1/students/violations`, { params })
    return fetch.data.payload
  }
}

export default new PelanggaranSiswaApi()