const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class BerandaApi {
  async getListTopTenKegiatan(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/reports/top-ten-student-has-achievements`, { params })
    return fetch.data.payload
  }

  async getListPelanggaran(axiosPrivate, params){
    const fetch = await axiosPrivate.get(`${url}/v1/students/violations`, { params })
    return fetch.data.payload
  }
}

export default new BerandaApi()