import { mappingDataOptions } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class RegionalApi {
    async getProvinsi(axiosPrivate) {
        const fetch = await axiosPrivate.get(`${url}/v1/regionals/provinsi`)
        return mappingDataOptions(fetch.data.payload)
    }

    async getKabupaten(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/regionals/kabupaten`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    async getKecamatan(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/regionals/kecamatan`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    async getDesa(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/regionals/desa`, { params })
        return mappingDataOptions(fetch.data.payload)
    }
}

export default new RegionalApi()