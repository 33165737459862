// Components
import { 
    Select,
    Input, 
    Label, 
    RadioButton, 
    RadioButtonGroup, 
    RadioButtonWrapper, 
    TextArea 
} from "components"

// Form
import { useFormikContext } from "formik"

export const FormSection = ({ modal, dropdown }) => {
    // Hooks
    const { values, setValues, errors, touched, handleChange } = useFormikContext()

    return (
        <>
            <Input 
                label='Nama' 
                name='name'
                type='text'
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name && touched.name)}
                errorText={Boolean(errors.name && touched.name) && errors.name}
            />
            <Input 
                label='Kode' 
                name='code'
                type='text'
                value={values.code}
                onChange={handleChange}
                error={Boolean(errors.code && touched.code)}
                errorText={Boolean(errors.code && touched.code) && errors.code}
            />
            <Input 
                label='Tingkatan Kelas' 
                name='grade'
                type='number'
                value={values.grade}
                onChange={handleChange}
                error={Boolean(errors.grade && touched.grade)}
                errorText={Boolean(errors.grade && touched.grade) && errors.grade}
            />
            <Select 
                label='Guru BK'
                placeholder='Pilih Guru BK'
                name='counselingPublicId'
                defaultValue={dropdown.getGuru?.data?.find(item => item.value === values.counselingPublicId)}
                onChange={val => setValues({...values, counselingPublicId: val.value})}
                options={dropdown.getGuru?.data ?? []}
                error={Boolean(errors.counselingPublicId && touched.counselingPublicId)}
                errorText={Boolean(errors.counselingPublicId && touched.counselingPublicId) && errors.counselingPublicId}
                errorFetch={dropdown.getGuru.isError}
                errorFetchText={dropdown.getGuru?.error?.response?.data?.errorMessage[0]}
                loading={dropdown.getGuru.isFetching}
            />
            <TextArea 
                label='Keterangan (opsional)'
                name='description'
                value={values.description}
                rows={4}
                onChange={handleChange}
                error={Boolean(errors.description && touched.description)}
                errorText={Boolean(errors.description && touched.description) && errors.description}
            />
            <RadioButtonWrapper label='Status'>
                <RadioButtonGroup>
                    <RadioButton 
                        checked={Boolean(values.isActive)}
                        label='Aktif'
                        onChange={() => setValues({...values, isActive: true})}
                    />
                    <RadioButton 
                        checked={Boolean(!values.isActive)}
                        label='Tidak Aktif'
                        onChange={() => setValues({...values, isActive: false})}
                    />
                </RadioButtonGroup>
            </RadioButtonWrapper>
            {modal.error && 
                <Label 
                    type='error'
                    text={modal.errorText}
                />
            }
        </>
    )
}
