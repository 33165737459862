const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class ProfilSekolahApi {
  async getSchoolAccount(axiosPrivate) {
    const fetch = await axiosPrivate.get(`${url}/v1/schools/account`)
    return fetch.data.payload
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.put(`${url}/v1/schools/${id}`, data)
  }
}

export default new ProfilSekolahApi()