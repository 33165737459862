import * as Yup from "yup";

export const formValidationSchema = () => {
  return Yup.object().shape({
    tingkatanKelasPublicId: Yup.string().required(
      "Tingkatan kelas wajib dipilih"
    ),
    kelasPublicId: Yup.string().required("Kelas wajib dipilih"),
    siswaPublicId: Yup.string().required("Siswa wajib dipilih"),
    keterangan: Yup.string().required("Keterangan wajib diisi"),
    hari: Yup.string().nullable().required("Hari wajib dipilih"),
  });
};

export const formUpdateValidationSchema = () => {
  return Yup.object().shape({
    keterangan: Yup.string().required("Keterangan wajib diisi"),
    hari: Yup.string().nullable().required("Hari wajib dipilih"),
  });
};
