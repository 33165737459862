import { mappingDataOptions } from "utilities";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class ClassroomsApi {
  async getClassrooms(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/classrooms`, { params });
    return mappingDataOptions(fetch.data.payload);
  }

  async getClassroomsStudents(axiosPrivate, params, classroomPublicId) {
    const fetch = await axiosPrivate.get(
      `${url}/v1/classrooms/${classroomPublicId}/students`,
      { params }
    );
    return mappingDataOptions(fetch.data.payload);
  }
}

export default new ClassroomsApi();
