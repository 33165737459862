export const formInitialValues = (action, data = {}) => ({
  publicId: action !== "create" ? data?.publicId : undefined ?? undefined,
  name: action !== "create" ? data?.name : "" ?? "",
  nip: action !== "create" ? data?.nip : "" ?? "",
  username: action !== "create" ? data?.user?.username : "" ?? "",
  isUsernameAvailable: action !== "create" ? true : false ?? false,
  gender: action !== "create" ? data?.gender : "" ?? "",
  dateOfBirth: action !== "create" ? data?.dateOfBirth : "" ?? "",
  email: action !== "create" ? data?.email : "" ?? "",
  isEmailAvailable: action !== "create" ? true : false ?? false,
  isActive: action !== "create" ? data?.isActive : true ?? true,
  street: action !== "create" ? data?.address?.street : "" ?? "",
  teacherCategoryPublicId:
    action !== "create" ? data?.teacherCategory.publicId : "" ?? "",
  teacherCategoryName:
    action !== "create" ? data?.teacherCategory?.name : "" ?? "",
  address: {
    desaId: action !== "create" ? data?.address?.desa?.id : "" ?? "",
    kecamatanId:
      action !== "create" ? data?.address?.desa?.kecamatan?.id : "" ?? "",
    kabupatenId:
      action !== "create"
        ? data?.address?.desa?.kecamatan?.kabupaten?.id
        : "" ?? "",
    provinsiId:
      action !== "create"
        ? data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.id
        : "" ?? "",
    street: action !== "create" ? data?.address?.street : "" ?? "",
  },
});
