import React, { useState } from "react";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { PengaturanMenus } from "../PengaturanMenus";
import { useMutation, useQuery } from "react-query";
import SetupKebijakanPrivasiApi from "./__SetupKebijakanPrivasiApi__";
import {
  DeleteButon,
  EditButton,
  ListLayout,
  Modal,
  Sidebar,
  ViewButton,
} from "components";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__SetupKebijakanPrivasiUtilities__";
import {
  DetailSection,
  FormSection,
  PreviewSection,
} from "./__SetupKebijakanPrivasiComponents__";

export const SetupKebijakanPrivasi = () => {
  const axiosPrivate = useAxiosPrivate();
  const menus = PengaturanMenus;
  const { auth } = useAuth();
  const permissions = auth.permissions;

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  const columns = [
    {
      name: "No",
      selector: (_, index) =>
        (getKebijakanPrivasi.data?.pageable?.pageNumber - 1) *
          getKebijakanPrivasi.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Urutan List",
      selector: (row) => row.index,
      wrap: true,
      width: "100px",
    },
    {
      name: "Judul",
      selector: (row) => row.title,
      wrap: true,
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_KP_R") && (
            <ViewButton
              icon
              noText
              onClick={() => onReadButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_KP_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_KP_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
      width: "150px",
    },
  ];

  const getKebijakanPrivasi = useQuery(
    ["kebijakan-privasi-list", pagination],
    () =>
      SetupKebijakanPrivasiApi.getList(
        axiosPrivate,
        paginationHandler(pagination)
      ).catch(() => {
        return [];
      })
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      errorText: "",
      error: false,
      data: data,
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const onPreviewButtonClickHandler = () =>
    setModal({
      show: true,
      type: "preview",
      errorText: "",
      error: false,
    });

  const createKebijakanPrivasi = useMutation(
    (data) => SetupKebijakanPrivasiApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getKebijakanPrivasi.refetch();
      },
    }
  );

  const updateKebijakanPrivasi = useMutation(
    ({ data, publicId }) =>
      SetupKebijakanPrivasiApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getKebijakanPrivasi.refetch();
      },
    }
  );

  const deleteKebijakanPrivasi = useMutation(
    (id) => SetupKebijakanPrivasiApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getKebijakanPrivasi.refetch();
      },
    }
  );

  const submitHandler = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const finalValues = formSubmitValueMapper(values);
    if (modal.type === "create") {
      await createKebijakanPrivasi
        .mutateAsync(finalValues)
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    } else {
      await updateKebijakanPrivasi
        .mutateAsync({
          data: finalValues,
          publicId: values?.publicId,
        })
        .then((res) => {
          resetForm();
          successFetching(res);
          onHideModalHandler();
        })
        .catch((err) => {
          const errorMessage = errorFetching(err);
          setModal({
            ...modal,
            error: true,
            errorText: errorMessage,
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Sidebar name="Setup Kebijakan Privasi" menus={menus}>
      <div className="mb-3 w-full">
        <ViewButton
          className="float-right"
          onClick={onPreviewButtonClickHandler}
          text="Lihat Kebijakan Privasi"
        />
      </div>
      <ListLayout
        permissions={permissions}
        permissionCreate="SCH_KP_C"
        data={getKebijakanPrivasi.data?.content}
        columns={columns}
        loading={getKebijakanPrivasi.isFetching}
        error={getKebijakanPrivasi.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getKebijakanPrivasi.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
      />

      {/* <---- MODAL CREATE ----> */}
      {modal.type === "create" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues("create", {})}
          validationSchema={formValidationSchema()}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;
            return (
              <Modal
                id="modal-tambah-kebijakan-privasi"
                header="Tambah Kebijakan Privasi"
                size="large"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "create")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL READ ---> */}
      {modal.type === "read" && modal.show && (
        <Modal
          id="modal-detil-kebijakan-privasi"
          header="Detail Kebijakan Privasi"
          size="large"
          type="read"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "read")}
        >
          <DetailSection data={modal?.data} />
        </Modal>
      )}

      {/* <--- MODAL UPDATE ---> */}
      {modal.type === "update" && modal.show && (
        <Formik
          enableReinitialize
          validationSchema={formValidationSchema()}
          initialValues={formInitialValues("update", modal?.data)}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { handleSubmit, resetForm, isSubmitting } = formik;

            return (
              <Modal
                id="modal-ubah-kebijakan-privasi"
                header="Ubah Kebijakan Privasi"
                size="large"
                type="update"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "update")}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              >
                <FormSection />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL DELETE ---> */}
      {modal.type === "delete" && modal.show && (
        <Modal
          id="modal-hapus-kebijakan-privasi"
          header="Hapus Kebijakan Privasi"
          size="large"
          type="delete"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "delete")}
          isSubmitting={deleteKebijakanPrivasi.isLoading}
          onSubmit={() => {
            deleteKebijakanPrivasi
              .mutateAsync(modal.data.publicId)
              .then((res) => {
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          <DetailSection data={modal?.data} />
        </Modal>
      )}

      {/* <--- MODAL PREVIEW ---> */}
      {modal.type === "preview" && modal.show && (
        <Modal
          id="modal-preview-kebijakan-privasi"
          header="Preview Kebijakan Privasi"
          size="large"
          type="preview"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "preview")}
        >
          <PreviewSection modal={modal} />
        </Modal>
      )}
    </Sidebar>
  );
};
