import * as Yup from 'yup'

export const formValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Nama Sekolah wajib diisi'),
    periode: Yup.string()
      .required('Tahun Ajaran wajib diisi'),
    email: Yup.string().email()
      .required('Email wajib diisi (cth. example@email.com)')
      .nullable(),
    website: Yup.string().url()
      .required('Website wajib diisi'),
    telp: Yup.string()
      .required('Telepon wajib diisi'),
    fax: Yup.string()
      .required('Fax wajib diisi'),
    principalPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    vicePrincipalOfAcademicPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    vicePrincipalOfStudentPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    vicePrincipalOfFinanceAndOperationPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    vicePrincipalOfPublicRelationAndPartnerPublicId: Yup.string()
      .required('Wajib dipilih')
      .nullable(),
    street: Yup.string()
      .required('Alamat wajib diisi'),
    postalCode: Yup.string()
      .required('Kode Pos wajib diisi'),
    address: Yup.object().shape({
      desaId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      kecamatanId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      kabupatenId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      provinsiId: Yup.string()
        .required('Wajib dipilih')
        .nullable()
    })
  })
}