import { DeleteButon, EditButton, ListLayout, Modal } from "components";
import React, { useState } from "react";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { useMutation, useQuery } from "react-query";
import { Formik } from "formik";
import { DetailSection, FormSection } from "./__AlasanTerlambatComponents__";
import * as Yup from "yup";
import AlasanTerlambatApi from "./__AlasanTerlambatApi__";

export const AlasanTerlambat = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getAlasanTerlambat.data?.pageable?.pageNumber - 1) *
          getAlasanTerlambat.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Alasan Terlambat",
      selector: (row) => row.value,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_LIB_LATE_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_LIB_LATE_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  // <--- useQuery --->
  const getAlasanTerlambat = useQuery(
    ["alasan-terlambat-list", pagination],
    () =>
      AlasanTerlambatApi.getList(
        axiosPrivate,
        paginationHandler(pagination)
      ).catch(() => {
        return [];
      })
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- Form --->
  const formInitialValues = {
    publicId: modal.type !== "create" ? modal?.data?.publicId ?? "" : undefined,
    value: modal.type !== "create" ? modal?.data?.value ?? "" : "",
    isActive: modal.type !== "create" ? modal?.data?.isActive ?? true : true,
  };

  const formValidationSchema = Yup.object().shape({
    value: Yup.string().required("Alasan Terlambat wajib diisi"),
  });

  // <--- useMutation --->
  const createAlasanTerlambat = useMutation(
    (data) => AlasanTerlambatApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getAlasanTerlambat.refetch();
      },
    }
  );

  const updateAlasanTerlambat = useMutation(
    ({ data, publicId }) =>
      AlasanTerlambatApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getAlasanTerlambat.refetch();
      },
    }
  );

  const deleteAlasanTerlambat = useMutation(
    (id) => AlasanTerlambatApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getAlasanTerlambat.refetch();
      },
    }
  );

  return (
    <>
      <ListLayout
        permissions={permissions}
        permissionCreate="SCH_LIB_LATE_C"
        data={getAlasanTerlambat.data?.content}
        columns={columns}
        loading={getAlasanTerlambat.isFetching}
        error={getAlasanTerlambat.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getAlasanTerlambat.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
      />

      {/* <--- MODAL CREATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createAlasanTerlambat
            .mutateAsync(values)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;

          return (
            <Modal
              id="modal-tambah-alasan-terlambat"
              header="Tambah Alasan Terlambat"
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "create")}
              onSubmit={handleSubmit}
              isSubmitting={createAlasanTerlambat.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL UPDATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          updateAlasanTerlambat
            .mutateAsync({
              data: values,
              publicId: values.publicId,
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-ubah-alasan-terlambat"
              header="Ubah Alasan Terlambat"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "update")}
              onSubmit={handleSubmit}
              isSubmitting={updateAlasanTerlambat.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL DELETE ---> */}
      <Modal
        id="modal-hapus-alasan-terlambat"
        header="Hapus Alasan Terlambat"
        size="small"
        type="delete"
        onHide={onHideModalHandler}
        show={Boolean(modal.show && modal.type === "delete")}
        isSubmitting={deleteAlasanTerlambat.isLoading}
        onSubmit={() => {
          deleteAlasanTerlambat
            .mutateAsync(modal.data.publicId)
            .then((res) => {
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        <DetailSection data={modal} />
      </Modal>
    </>
  );
};
