export const formByPassPresensiInitialValues = () => ({
  gradePublicId: "",
  classroomPublicId: "",
  studentPublicId: "",
  flag: "-",
  description: "",
});

export const formPersetujuanMasukKelasInitialValues = (data) => ({
  flag: data?.flag,
  attendanceQrCodePublicId: data?.attendanceQrCodePublicId,
  description: data?.description,
  studentClass: data?.studentClass,
  studentName: data?.studentName,
  studentNis: data?.studentNis,
  time: data?.time,
  date: data?.date,
  isApproved: data?.isApproved,
});
