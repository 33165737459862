import {
  DataStatus,
  DefaultButton,
  Input,
  ListLayout,
  PrintButton,
} from "components";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  paginationHandler,
  useAxiosPrivate,
} from "utilities";
import { CetakPelanggaranSiswa } from "./__PelanggaranSiswaComps__";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import PelanggaranSiswaApi from "./__PelanggaranSiswaApi__";
import { ExportPelanggaranSiswa } from "./__PelanggaranSiswaComps__/ExportPelanggaranSiswa";

const ComponentToPrintWrapper = ({
  cetakData,
  filter,
  handleOnBeforePrint,
}) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <PrintButton
            icon
            text="Cetak"
            className="flex-grow mr-3"
            loading={cetakData.loading}
          />
        )}
        content={() => componentRef.current}
        removeAfterPrint={true}
        onBeforePrint={handleOnBeforePrint}
        documentTitle="Laporan Pelanggaran Siswa"
      />
      <div style={{ display: "none" }}>
        <CetakPelanggaranSiswa
          data={cetakData.data}
          filter={filter}
          ref={componentRef}
        />
      </div>
    </>
  );
};

export const PelanggaranSiswa = () => {
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    status: 2,
    startDate: undefined,
    endDate: undefined,
  });
  const [filter, setFilter] = useState({
    startDate: undefined,
    endDate: undefined,
  });
  const [cetakData, setCetakData] = useState({
    loading: false,
    data: [],
  });
  const [exportData, setExportData] = useState([]);

  // <--- useQuery --->
  const getPelanggaranSiswaPageable = useQuery(
    ["pelanggaran-siswa-list-pageable", pagination],
    () =>
      PelanggaranSiswaApi.getListPelanggaranPageable(
        axiosPrivate,
        paginationHandler(pagination)
      ),
    { enabled: Boolean(pagination?.startDate) || Boolean(pagination.endDate) }
  );

  const getPelanggaranSiswa = useQuery(
    ["pelanggaran-siswa-list", filter],
    () => PelanggaranSiswaApi.getListPelanggaran(axiosPrivate, filter),
    {
      enabled: Boolean(filter?.startDate) || Boolean(filter.endDate),
      onSuccess: (res) => {
        setCetakData({ ...cetakData, data: res, loading: false });
        setExportData(res)
      },
      onError: (err) => {
        errorFetching(err);
        setCetakData({ ...cetakData, data: [], loading: false });
        setExportData([])
      },
    }
  );

  // <--- Table's column --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getPelanggaranSiswaPageable.data?.pageable?.pageNumber - 1) *
          getPelanggaranSiswaPageable.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentName,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "80px",
      wrap: true,
    },
    {
      name: "Pelanggaran",
      selector: (row) => row?.violationName,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Tgl. Pelanggaran",
      selector: (row) =>
        row?.activityDate ? getDashDMY(row?.activityDate) : "-",
      wrap: true,
      width: "150px",
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "300px",
    },
  ];

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  // <--- Print --->
  const handleOnBeforePrint = () => {
    setCetakData({ ...cetakData, loading: true });
    getPelanggaranSiswa.refetch();
  };

  return (
    <>
      <div className="sm:flex sm:flex-row sm:space-y-0 space-y-3">
        <div className="sm:flex sm:flex-row sm:space-x-3 sm:space-y-0 sm:flex-grow space-y-3">
          <div>
            <Input
              label="Tanggal Awal"
              type="date"
              value={filter.startDate}
              onChange={(e) => {
                setPagination({ ...pagination, startDate: e.target.value });
                setFilter({ ...filter, startDate: e.target.value });
              }}
            />
          </div>
          <div>
            <Input
              label="Tanggal Akhir"
              type="date"
              value={filter.endDate}
              onChange={(e) => {
                setPagination({ ...pagination, endDate: e.target.value });
                setFilter({ ...filter, endDate: e.target.value });
              }}
            />
          </div>
          <div className="grid content-end">
            <DefaultButton
              text="Reset Pencarian"
              onClick={() => {
                setPagination({
                  ...pagination,
                  startDate: undefined,
                  endDate: undefined,
                });
                setFilter({
                  ...filter,
                  startDate: undefined,
                  endDate: undefined,
                });
              }}
            />
          </div>
        </div>
        {cetakData?.data?.length > 0 && exportData?.length > 0 ? (
          <>
            <div className="grid content-end">
              <ComponentToPrintWrapper
                cetakData={cetakData}
                filter={filter}
                handleOnBeforePrint={handleOnBeforePrint}
              />
            </div>
            <div className="grid content-end">
              <ReactHTMLTableToExcel
                id="btn-export-table-pelanggaran-siswa"
                className="download-table-xls-button btn-success justify-center flex flex-row items-center p-2"
                table="export-table-pelanggaran-siswa"
                filename={`Pelanggaran_Siswa`}
                sheet={`Sheet 1`}
                buttonText="Export to Excel"
              />
            </div>
          </>
        ) : null}
      </div>
      {pagination?.startDate || pagination?.endDate ? (
        <>
          <ListLayout
            data={getPelanggaranSiswaPageable.data?.content}
            columns={columns}
            loading={getPelanggaranSiswaPageable.isFetching}
            error={
              getPelanggaranSiswaPageable.error?.response?.data?.errorMessage[0]
            }
            pagination={true}
            totalRows={
              getPelanggaranSiswaPageable.data?.pageable?.totalElements
            }
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            customLeftTopSection={() => <></>}
            customRightTopSection={() => <></>}
          />
          <div style={{ display: "none" }}>
            <ExportPelanggaranSiswa data={exportData} />
          </div>
        </>
      ) : (
        <DataStatus text="Silahkan memilih pencarian tanggal awal atau tanggal akhir terlebih dahulu" />
      )}
    </>
  );
};
