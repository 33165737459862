import React from "react";
import { DataStatus, Input, InputEditor, InputImageMultiple, Label, RadioButton, RadioButtonGroup, RadioButtonWrapper, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";
import { KategoriBlogApi } from "api";
import BlogApi from "../__BlogApi__";
import { formInitialValues } from "../__BlogUtilities__";

export const FormSection = ({ modal, publicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, setFieldValue, errors, touched, handleChange } =
    useFormikContext();

  const getKategoriBlog = useQuery(
    ["blog-atribut-kategori-blog"],
    () => KategoriBlogApi.getKategoriBlogWithoutOptionsAddition(axiosPrivate),
    { enabled: modal.show }
  );

  const getDetail = useQuery(
    ["blog-atribut-detail", publicId],
    () => BlogApi.detail(axiosPrivate, publicId),
    {
      enabled: Boolean(modal.show && modal.type === 'update'),
      onSuccess: (data) => {
        setValues(formInitialValues("update", data));
      }
    }
  )

  if(modal.type === "update" && (getDetail.isError || getDetail.isFetching)) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data blog..."
        text="Data blog gagal dimuat"
      />
    )
  }

  return (
    <div className="space-y-3">
      <Select
        label="Kategori"
        placeholder="Pilih Kategori"
        name="publicId"
        defaultValue={getKategoriBlog?.data?.find(
          (item) => item.value === values?.category?.publicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            category: {
              ...values.category,
              publicId: val.value,
              name: val.label,
              code: val.code
            },
          })
        }
        options={getKategoriBlog?.data ?? []}
        error={Boolean(
          errors?.category?.publicId && touched?.category?.publicId
        )}
        errorText={
          Boolean(errors?.category?.publicId && touched?.category?.publicId) &&
          errors?.category?.publicId
        }
        errorFetch={getKategoriBlog.isError}
        errorFetchText={getKategoriBlog?.error?.response?.data?.errorMessage[0]}
        loading={getKategoriBlog.isFetching}
      />
      <RadioButtonWrapper label='Status'>
          <RadioButtonGroup>
              <RadioButton 
                  checked={Boolean(values.isPublish)}
                  label='Terbit'
                  onChange={() => setValues({...values, isPublish: true})}
              />
              <RadioButton 
                  checked={Boolean(!values.isPublish)}
                  label='Tidak Terbit'
                  onChange={() => setValues({...values, isPublish: false})}
              />
          </RadioButtonGroup>
      </RadioButtonWrapper>
      <Input
        label="Judul"
        name="title"
        type="text"
        value={values?.title}
        onChange={handleChange}
        error={Boolean(errors.title && touched.title)}
        errorText={Boolean(errors.title && touched.title) && errors.title}
      />
      <InputEditor
        label="Konten"
        value={values?.body}
        onChange={(val) => setValues({ ...values, body: val })}
        error={Boolean(errors.body && touched.body)}
        errorText={Boolean(errors.body && touched.body) && errors.body}
      />
      <InputImageMultiple 
        label="Cover Blog"
        type="action"
        data={values?.images}
        setData={(val) => setFieldValue("images", val)}
        disabledButton={Boolean(values?.images?.length === 1)}
      />
      {modal.error && <Label type="error" text={modal.errorText} />}
    </div>
  );
};
