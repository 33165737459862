import { SchoolsApi } from "api";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  mappingDataGender,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import AlumniApi from "./__AlumniApi__";
import {
  DataStatus,
  DeleteButon,
  LabelDanger,
  LabelSuccess,
  ListLayout,
  Modal,
  Select,
  ViewButton,
} from "components";
import { ContentKembaliKeKelas, DetailSection } from "./__AlumniComponents__";
import { Formik } from "formik";
import { formInitialValues, formValidationSchema } from "./__AlumniUtilities__";

export const Alumni = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: "name",
    isActive: true,
    schoolPeriodePublicId: undefined,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });
  const [isActive, setIsActive] = useState("true");
  const [filter, setFilter] = useState({ schoolPeriodePublicId: "" });

  // <--- useQuery --->
  const getTahunAjaran = useQuery(["alumni-atribut-tahun-ajaran"], () =>
    SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" })
  );
  const getAlumni = useQuery(["alumni-list", pagination], () =>
    AlumniApi.getList(axiosPrivate, paginationHandler(pagination))
  );

  // <-- useMutation -->
  const kembaliKeKelas = useMutation(
    ({ classroomPublicId, studentPublicId }) =>
      AlumniApi.kembaliKeKelas(
        axiosPrivate,
        classroomPublicId,
        studentPublicId
      ),
    {
      onSuccess: () => {
        getAlumni.refetch();
      },
    }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        isActive: e.target.name,
      });
      setIsActive(e.target.name);
    } else {
      setPagination({
        ...pagination,
        isActive: undefined,
      });
      setIsActive("none");
    }
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onReadButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "read",
      errorText: "",
      error: false,
      data: data,
    });

  const onKembaliKeKelasButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "back",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getAlumni.data?.pageable?.pageNumber - 1) *
          getAlumni.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.nis,
      width: "150px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.name,
      minWidth: "400px",
      wrap: true,
    },
    {
      name: "Jenis Kelamin",
      selector: (row) => mappingDataGender(row?.gender),
      width: "120px",
      wrap: true,
    },
    {
      name: "Tanggal Lahir",
      selector: (row) =>
        row?.dateOfBirth ? getDashDMY(row?.dateOfBirth) : "-",
      width: "130px",
      wrap: true,
    },
    {
      name: "Status",
      cell: (data) =>
        data?.isActive ? (
          <LabelSuccess text="Aktif" />
        ) : (
          <LabelDanger text="Tidak Aktif" />
        ),
      width: "120px",
    },
    {
      name: "Aksi",
      button: true,
      width: "200px",
      cell: (data) => (
        <>
          {permissions.includes("STUDENT_R") && (
            <ViewButton
              icon
              noText
              onClick={() => onReadButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("STUDENT_U") && (
            <DeleteButon
              className="w-auto"
              text="Kembali ke Kelas"
              onClick={() => onKembaliKeKelasButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="flex mb-4">
        <Select
          label="Tahun Ajaran"
          placeholder="Pilih Tahun Ajaran"
          defaultValue={getTahunAjaran?.data?.find(
            (item) => item.value === filter?.tingkatanPublicId
          )}
          onChange={(val) => {
            setFilter({
              ...filter,
              schoolPeriodePublicId: val.value,
            });
            setPagination({
              ...pagination,
              search: undefined,
              searchBy: undefined,
              schoolPeriodePublicId: val?.value,
            });
          }}
          options={getTahunAjaran?.data ?? []}
          errorFetch={getTahunAjaran.isError}
          errorFetchText={
            getTahunAjaran?.error?.response?.data?.errorMessage[0]
          }
          loading={getTahunAjaran.isFetching}
        />
      </div>
      {filter?.schoolPeriodePublicId === "" ? (
        <DataStatus text="Silahkan memilih tahun ajaran terlebih dahulu" />
      ) : (
        <>
          <ListLayout
            permissions={[]}
            data={getAlumni.data?.content}
            columns={columns}
            loading={getAlumni.isFetching}
            error={getAlumni.error?.response?.data?.errorMessage[0]}
            pagination={true}
            totalRows={getAlumni.data?.pageable?.totalElements}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            onSearchChange={searchDataHandler}
            activeStatusFilter
            activeOnChange={activeDataHandler}
            activeValues={isActive}
          />

          {/* <--- MODAL READ ---> */}
          {modal.type === "read" && modal.show && (
            <Modal
              id="modal-detil-alumni"
              header="Detail Alumni"
              size="large"
              type="read"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "read")}
            >
              <DetailSection data={modal} kelas={filter} />
            </Modal>
          )}

          {/* <-- MODAL KEMBALI KE KELAS --> */}
          {modal.type === "back" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={formInitialValues(modal?.data)}
              validationSchema={formValidationSchema}
              onSubmit={async (values, { resetForm }) => {
                kembaliKeKelas
                  .mutateAsync({
                    classroomPublicId: values?.classroomPublicId,
                    studentPublicId: values?.studentPublicId,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  })
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;

                return (
                  <Modal
                    id="modal-kembali-ke-kelas"
                    header="Kembali ke Kelas"
                    size="medium"
                    type="update"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "back")}
                    onSubmit={handleSubmit}
                    isSubmitting={kembaliKeKelas.isLoading}
                  >
                    <ContentKembaliKeKelas />
                  </Modal>
                );
              }}
            </Formik>
          )}
        </>
      )}
    </>
  );
};
