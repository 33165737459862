import React from "react";
import {
  generateBodyTablePresensiBulanan,
  generateHeaderTablePresensiBulanan,
} from "../__PresensiBulananSiswaUtilities__";
import { TablePrint } from "components";

export const ExportPresensiBulananSiswa = ({ data }) => {
  const TABLE_HEADER = generateHeaderTablePresensiBulanan(data);
  const TABLE_BODY = generateBodyTablePresensiBulanan(data?.students?.[0].date);

  return (
    <div className="mt-5">
      <TablePrint
        tableId="export-table-presensi-bulanan-siswa"
        data={data.students}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  );
};
