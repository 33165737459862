import React from 'react'

export const Checkbox = ({
    name,
    className,
    inputClassName,
    inputStyle,
    value,
    checked,
    onChange,
    label,
    disabled,
    id
}) => {
    return (
        <div className={className}>
            <input 
                id={id}
                type='checkbox'
                name={name}
                className={`checkbox-primary ${inputClassName}`}
                style={inputStyle}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <small className='align-middle ml-2'>
                {label}
            </small>
        </div>
    )
}
