import { SchoolsApi } from "api";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  getDay,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import DispensasiSiswaApi from "./__DispensasiSiswaApi__";
import {
  CreateButton,
  DeleteButon,
  EditButton,
  ListLayout,
  Modal,
  SearchInput,
  Select,
} from "components";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitValueMapper,
  formUpdateValidationSchema,
  formValidationSchema,
} from "./__DispensasiSiswaUtilities__";
import { DetailSection, FormSection } from "./__DispensasiSiswaComponents__";

export const DispensasiSiswa = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    direction: "DESC",
    tahunAjaranPublicId: undefined,
  });
  const [filter, setFilter] = useState({
    tahunAjaranPublicId: undefined,
    tahunAjaran: undefined,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- useQuery --->
  const getTahunAjaran = useQuery(
    ["dispensasi-siswa-atribut-tahun-ajaran"],
    () => SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" }),
    {
      onSuccess: (res) => {
        const tahunAjaranPublicId = res?.find((val) => val?.isActive)?.value;
        const tahunAjaran = res?.find((val) => val?.isActive)?.label;
        setFilter({
          tahunAjaranPublicId: tahunAjaranPublicId,
          tahunAjaran: tahunAjaran,
        });
        setPagination({
          ...pagination,
          tahunAjaranPublicId: tahunAjaranPublicId,
        });
      },
    }
  );

  const getList = useQuery(
    ["dispensasi-siswa-list", pagination],
    () =>
      DispensasiSiswaApi.getListPageable(
        axiosPrivate,
        paginationHandler(pagination)
      ),
    {
      enabled: Boolean(pagination?.tahunAjaranPublicId),
    }
  );

  // <--- useMutation --->
  const createDispensasi = useMutation(
    (data) => DispensasiSiswaApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getList.refetch();
      },
    }
  );

  const updateDispensasi = useMutation(
    ({ data, publicId }) =>
      DispensasiSiswaApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getList.refetch();
      },
    }
  );

  const deleteDispensasi = useMutation(
    (id) => DispensasiSiswaApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getList.remove();
        getList.refetch();
      },
    }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onCreateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: data,
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getList.data?.pageable?.pageNumber - 1) *
          getList.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.kelasNama,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.siswaNama,
      minWidth: "400px",
      wrap: true,
    },
    {
      name: "Hari",
      selector: (row) => {
        const hari = row?.hari;
        if (hari === 7) {
          return getDay(0);
        }

        return getDay(hari);
      },
      minWidth: "120px",
      wrap: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row?.keterangan,
      minWidth: "400px",
      wrap: true,
    },
    {
      name: "Aksi",
      button: true,
      width: "200px",
      cell: (data) => (
        <>
          {permissions.includes("SCH_DP_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_DP_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-row flex-1 items-end">
        <div className="mr-5">
          <Select
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            defaultValue={getTahunAjaran?.data?.find(
              (item) => item.value === filter?.tahunAjaranPublicId
            )}
            onChange={(val) => {
              setFilter({
                ...filter,
                tahunAjaranPublicId: val.value,
                tahunAjaran: val.label,
              });
              setPagination({
                ...pagination,
                search: undefined,
                searchBy: undefined,
                tahunAjaranPublicId: val?.value,
              });
            }}
            options={getTahunAjaran?.data ?? []}
            errorFetch={getTahunAjaran.isError}
            errorFetchText={
              getTahunAjaran?.error?.response?.data?.errorMessage[0]
            }
            loading={getTahunAjaran.isFetching}
          />
        </div>
        <div className="flex-1">
          <SearchInput onChange={searchDataHandler} />
        </div>
        <div>
          <CreateButton
            type="button"
            onClick={onCreateButtonClickHandler}
            disabled={!Boolean(pagination?.tahunAjaranPublicId)}
          />
        </div>
      </div>

      <ListLayout
        permissions={permissions}
        data={getList.data?.content}
        columns={columns}
        loading={getList.isFetching}
        error={getList.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getList.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        customLeftTopSection={() => <></>}
      />

      {/* <--- MODAL CREATE ---> */}
      {modal.type === "create" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(
            "create",
            {},
            filter.tahunAjaranPublicId
          )}
          validationSchema={formValidationSchema()}
          onSubmit={async (values, { resetForm }) => {
            const finalValues = formSubmitValueMapper(values);

            createDispensasi
              .mutateAsync(finalValues)
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          {(formik) => {
            const { handleSubmit, resetForm } = formik;

            return (
              <Modal
                id="modal-tambah-dispensasi-siswa"
                header="Tambah Dispensasi Siswa"
                size="small"
                type="create"
                onHide={() => {
                  resetForm();
                  onHideModalHandler();
                }}
                show={Boolean(modal.show && modal.type === "create")}
                onSubmit={handleSubmit}
                isSubmitting={createDispensasi.isLoading}
              >
                <FormSection modal={modal} tahunAjaran={filter.tahunAjaran} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL UPDATE ---> */}
      {modal.type === "update" && modal.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(modal?.type, modal?.data)}
          validationSchema={formUpdateValidationSchema()}
          onSubmit={(values, { resetForm }) => {
            updateDispensasi
              .mutateAsync({
                data: {
                  keterangan: values?.keterangan,
                  hari: values?.hari,
                },
                publicId: modal?.data?.publicId,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik;

            return (
              <Modal
                id="modal-ubah-dispensasi-siswa"
                header="Ubah Dispensasi Siswa"
                size="small"
                type="update"
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === "update")}
                onSubmit={handleSubmit}
                isSubmitting={updateDispensasi.isLoading}
              >
                <FormSection modal={modal} tahunAjaran={filter.tahunAjaran} />
              </Modal>
            );
          }}
        </Formik>
      )}

      {/* <--- MODAL DELETE ---> */}
      {modal.type === "delete" && modal.show && (
        <Modal
          id="modal-hapus-dispensasi-siswa"
          header="Hapus Dispensasi Siswa"
          size="small"
          type="delete"
          onHide={onHideModalHandler}
          show={Boolean(modal.show && modal.type === "delete")}
          isSubmitting={deleteDispensasi.isLoading}
          onSubmit={() => {
            deleteDispensasi
              .mutateAsync(modal.data.publicId)
              .then((res) => {
                successFetching(res);
                onHideModalHandler();
              })
              .catch((err) => {
                const errorMessage = errorFetching(err);
                setModal({
                  ...modal,
                  error: true,
                  errorText: errorMessage,
                });
              });
          }}
        >
          <DetailSection data={modal.data} tahunAjaran={filter.tahunAjaran} />
        </Modal>
      )}
    </>
  );
};
