import React from "react";
import parse from "html-react-parser";
import { useAxiosPrivate } from "utilities";
import { useQuery } from "react-query";
import SetupQnaApi from "../__SetupQnaApi__";
import { DataStatus } from "components";

export const DetailSection = ({ data }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = data?.data;

  const getDetail = useQuery(
    ["qna-atribut-detail", publicId],
    () => SetupQnaApi.detail(axiosPrivate, publicId),
    {
      enabled: Boolean(data.show && data.type === "read"),
    }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data QnA..."
        text="Data QnA gagal dimuat"
      />
    );
  } else {
    return (
      <div className="space-y-3">
        <div className="text-center text-2xl font-semibold">
          {getDetail?.data?.title}
        </div>
        <div className="text-center text-sm">
          {/* KATEGORI */}
          Kategori: {getDetail?.data?.qnaCategory?.name}
        </div>
        <hr className="mt-5" />
        <div className="mt-5 mb-5">{parse(getDetail?.data?.body)}</div>
      </div>
    );
  }
};
