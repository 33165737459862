import { Input, InputFileSingle, Label } from "components"
import { useFormikContext } from "formik"

export const ContentImportSiswa = ({ data }) => {
  // Hooks
  const { setValues, errors } = useFormikContext()
  
  return (
    <>
      <Input 
        label='Kelas' 
        value={`${data?.tingkatanName} ${data?.kelasName}`}
        wrapperClassName='flex-1 ml-1'
        disabled
      />
      <InputFileSingle 
        label='File (.xlsx dan .xls)'
        acceptFileType={['xlsx', 'xls']}
        onChange={(val) => setValues({
          dataFile: val
        })}
				error={Boolean(errors.dataFile)}
				errorText={Boolean(errors.dataFile) && errors.dataFile}
      />
      {data.error && 
          <Label 
              type='error'
              text={data.errorText}
          />
      }
    </>
  )
}
