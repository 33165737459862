const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class TahunAjaranApi {
  async getList(axiosPrivate, params) {
      const fetch = await axiosPrivate.get(`${url}/v1/school-periode/pageable`, { params })
      return fetch.data.payload
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v1/school-periode`, data)
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.patch(`${url}/v1/school-periode/${id}/periode`, data)
  }

  setActive(axiosPrivate, schoolPeriodePublicId) {
    return axiosPrivate.patch(`${url}/v1/school-periode/${schoolPeriodePublicId}/active`)
  }
}

export default new TahunAjaranApi()