const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class PresensiBulananSiswaApi {
  async getReport(axiosPrivate, classroomPublicId, periodePublicId, yearMonth) {
    const fetch = await axiosPrivate.get(
      `${url}/v1/attendance/classroom/${classroomPublicId}/periode/${periodePublicId}/year-month/${yearMonth}`
    );
    return fetch.data.payload;
  }
}

export default new PresensiBulananSiswaApi();
