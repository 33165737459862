export const formInitialValues = data => ({
    userPublicId: data?.publicId,
    name: data?.name,
    username: data?.username,
    email: data?.email,
    isBlocked: data?.isBlocked
})

export const formResetPasswordInitialValues = data => ({
    userPublicId: data?.publicId,
    password: ''
})