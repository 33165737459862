// <--- API --->
import { SchoolsApi } from "api";

// <--- Third-Party Library --->
import { useQuery } from "react-query";

// <--- Utilities --->
import { useAuth, useAxiosPrivate } from "utilities";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const package_json = require("../../../../../package.json");
  const version = package_json.version;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const isAuthenticated = Boolean(auth?.status !== "unauthenticated");

  // <--- useQuery --->
  const getAkunSekolah = useQuery(
    ["kesiswaan-atribut-akun-sekolah"],
    () => SchoolsApi.getAccount(axiosPrivate),
    { enabled: Boolean(isAuthenticated) }
  );

  return (
    <footer className="border-t-2">
      <div className="container mx-auto flex items-center mt-5">
        <label className="w-full text-center text-xs font-medium">
          © 2022 {getAkunSekolah?.data?.name ?? ""} - {version}
        </label>
      </div>
      <div className="container mx-auto flex items-center mt-3 mb-5">
        <label
          className="w-full text-center text-xs font-normal underline hover:cursor-pointer hover:text-clobasoft-dark-blue"
          onClick={() => navigate("/kebijakan-privasi")}
        >
          Kebijakan Privasi
        </label>
      </div>
    </footer>
  );
};
