import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { HashLoader } from "react-spinners";

export const SectionStatus = ({ status }) => {
  if (status === "LOADING" || status === "INIT") {
    return (
      <div>
        <HashLoader color={"#1976D2"} size={250} />
      </div>
    );
  } else if (status === "SUCCESS") {
    return (
      <div className="text-clobasoft-green text-[25rem]">
        <FaCheckCircle />
      </div>
    );
  }

  return <></>;
};
