import React from "react";
import { Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { DropdownApi } from "api";

export const FormPersetujuanMasukKelasSection = () => {
  const { values, setValues } = useFormikContext();

  const getAttendanceFlagVerification = useQuery(
    ["attendance-flag-verification", "dropdown"],
    () => DropdownApi.getAttendanceFlagVerification()
  );

  return (
    <>
      <Input label="Nama Siswa" type="text" value={values.studentName} disabled />
      <Input label="Kelas" type="text" value={values.studentClass} disabled />
      <Input label="Jam Absen" type="text" value={values.time} disabled />
      <Input
        label="Alasan Terlambat"
        type="text"
        value={values.description ?? "-"}
        disabled
      />
      <Select
        label="Status"
        placeholder="Pilih Status"
        name="flag"
        defaultValue={getAttendanceFlagVerification?.data?.find(
          (item) => item.value === values.flag
        )}
        onChange={(val) =>
          setValues({
            ...values,
            flag: val.value,
          })
        }
        options={getAttendanceFlagVerification?.data ?? []}
        loading={getAttendanceFlagVerification.isFetching}
      />
    </>
  );
};
