import { SchoolsApi } from "api";
import { Input, LabelWarning, RowDetailLayout } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";

export const ContentLulus = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched } = useFormikContext();

  // <--- useQuery --->
  const getAkunSekolah = useQuery(
    ["naik-kelas-atribut-akun-sekolah"],
    () => SchoolsApi.getAccount(axiosPrivate),
    {
      enabled: modal.show,
      onSuccess: (res) => {
        setValues({
          ...values,
          periode: res?.periode,
        });
      },
    }
  );

  return (
    <div className="space-y-3">
      <div className="flex flex-row">
        <Input 
          label='Tahun Ajaran' 
          value={getAkunSekolah.isFetching ? 'Memuat data tahun ajaran...' : getAkunSekolah?.data?.periode}
          wrapperClassName='flex-1 mr-1'
          disabled
        />
        <Input 
          label='Kelas' 
          value={`${values?.tingkatanKelas} ${values?.kelas}`}
          wrapperClassName='flex-1 ml-1'
          disabled
        />
      </div>
      <div className="w-100 text-center">
        <label className="text-red-600 font-bold">Apakah Anda yakin akan meluluskan siswa {values?.tingkatanKelas}-{values?.kelas}?</label>
      </div>
    </div>
  );
};
