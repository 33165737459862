// <--- Utilities --->
import { paginationHandler, useAuth, useAxiosPrivate } from "utilities"

// <--- Components --->
import { DeleteButon, EditButton, ListLayout } from "components"

// <--- Third-Party Library --->
import { useQuery } from "react-query"

// <--- API --->
import KategoriGuruApi from "./__KategoriGuruApi__"

// <--- React --->
import { useState } from "react"

export const KategoriGuru = () => {
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const permissions = auth.permissions

    // <--- States --->
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        isActive: true
	})
    const [isActive, setIsActive] = useState('true')
    const [modal, setModal] = useState({
		show: false,
		type: 'create',
        errorText: '',
        error: false,
		data: {}
	})

    // <--- Table's columns --->
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => ((getKategoriGuru.data?.pageable?.pageNumber - 1) * getKategoriGuru.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'Nama',
            selector: row => row.name,
            wrap: true
        },
        {
            name: 'Kode',
            selector: row => row.code,
            wrap: true
        },
        {
            name: 'Keterangan',
            selector: row => row.description,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row.isActive ? 'Aktif' : 'Tidak Aktif',
            wrap: true,
            width: '150px'
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('TEACHER_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('TEACHER_D') && <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />}
            </>
        }
    ]

    // <--- useQuery --->
    const getKategoriGuru = useQuery(
        ['kategori-guru-list', pagination],
        () => KategoriGuruApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => { return [] })
    )

    // <--- Functions --->
	const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }

    const activeDataHandler = (e) => {
        if(e.target.name !== 'all'){
            setPagination({
                ...pagination,
                isActive: e.target.name
            })
            setIsActive(e.target.name)
        } else{
            setPagination({
                ...pagination,
                isActive: undefined
            })
            setIsActive('none')
        }
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data })


    return (
        <>
            {/* <--- Search input, Create Button, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                permissionCreate='TEACHER_C'
                data={getKategoriGuru.data?.content}
                columns={columns}
                loading={getKategoriGuru.isFetching}
                error={getKategoriGuru.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getKategoriGuru.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
                activeStatusFilter
                activeOnChange={activeDataHandler}
                activeValues={isActive}
            />
        </>
    )
}
