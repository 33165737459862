import { mappingDataOptions } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class GuruApi {
    async getList(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/teachers/pageable`, { params })
        return fetch.data.payload
    }

    async detail(axiosPrivate, id) {
        const fetch = await axiosPrivate.get(`${url}/v1/teachers/${id}`)
        return fetch.data.payload
    }

    async getKategoriGuru(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/teacher-categories`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    create(axiosPrivate, data) {
        return axiosPrivate.post(`${url}/v1/teachers`, data)
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/teachers/${id}`, data)
    }

    delete(axiosPrivate, id) {
        return axiosPrivate.delete(`${url}/v1/teachers/${id}`)
    }
}

export default new GuruApi()