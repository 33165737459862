import React from "react";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { ClassroomsApi, DropdownApi, GradeApi } from "api";
import { useAxiosPrivate } from "utilities";
import {
  Label,
  RadioButton,
  RadioButtonGroup,
  Select,
  TextArea,
} from "components";

export const FormByPassSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, handleChange, errors, touched } =
    useFormikContext();

  const getTingkatanKelas = useQuery(
    ["by-pass-atribut-tingkatan-kelas"],
    () =>
      GradeApi.getGrade(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        direction: "ASC",
      }),
    {
      enabled: Boolean(modal.show && modal.type === "bypass"),
    }
  );

  const getKelas = useQuery(
    ["by-pass-atribut-kelas", values?.gradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values?.gradePublicId,
        sortBy: "name",
        direction: "ASC",
      }),
    {
      enabled: Boolean(
        modal.show && modal.type === "bypass" && values?.gradePublicId
      ),
    }
  );

  const getSiswa = useQuery(
    ["by-pass-atribut-siswa", values?.classroomPublicId],
    () =>
      ClassroomsApi.getClassroomsStudents(
        axiosPrivate,
        { isActive: true, sortBy: "student_name", direction: "ASC" },
        values?.classroomPublicId
      ),
    {
      enabled: Boolean(
        modal.show && modal.type === "bypass" && values?.classroomPublicId
      ),
    }
  );

  const getAttendanceFlag = useQuery(["attendance-flag", "dropdown"], () =>
    DropdownApi.getAttendanceFlag()
  );

  return (
    <>
      <RadioButtonGroup>
        <div>
          <small>Tingkatan Kelas</small>
        </div>
        {getTingkatanKelas?.data?.map((val, index) => (
          <RadioButton
            key={index}
            checked={Boolean(values.gradePublicId === val.value)}
            label={val.label}
            name={val.value}
            onChange={(value) => {
              setValues({
                ...values,
                gradePublicId: value.target.name,
                classroomPublicId: "",
                studentPublicId: "",
              });
            }}
          />
        ))}
      </RadioButtonGroup>
      <Select
        label="Kelas"
        placeholder="Pilih Kelas"
        name="classroomPublicId"
        defaultValue={getKelas?.data?.find(
          (item) => item.value === values.classroomPublicId
        )}
        onChange={(val) =>
          setValues({ ...values, classroomPublicId: val.value })
        }
        options={getKelas?.data ?? []}
        error={Boolean(errors.classroomPublicId && touched.classroomPublicId)}
        errorText={
          Boolean(errors.classroomPublicId && touched.classroomPublicId) &&
          errors.classroomPublicId
        }
        errorFetch={getKelas.isError}
        errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
        loading={getKelas.isFetching}
        disable={Boolean(values.gradePublicId === "")}
      />
      <Select
        label="Siswa"
        placeholder="Pilih Siswa"
        name="studentPublicId"
        defaultValue={getSiswa?.data?.find(
          (item) => item.value === values.studentPublicId
        )}
        onChange={(val) => setValues({ ...values, studentPublicId: val.value })}
        options={getSiswa?.data ?? []}
        error={Boolean(errors.studentPublicId && touched.studentPublicId)}
        errorText={
          Boolean(errors.studentPublicId && touched.studentPublicId) &&
          errors.studentPublicId
        }
        errorFetch={getSiswa.isError}
        errorFetchText={getSiswa?.error?.response?.data?.errorMessage[0]}
        loading={getSiswa.isFetching}
        disable={Boolean(values.classroomPublicId === "")}
      />
      <Select
        label="Status"
        placeholder="Pilih Status"
        name="flag"
        defaultValue={getAttendanceFlag?.data?.find(
          (item) => item.value === values.flag
        )}
        onChange={(val) =>
          setValues({
            ...values,
            flag: val.value,
          })
        }
        options={getAttendanceFlag?.data ?? []}
        errorFetch={getAttendanceFlag.isError}
        errorFetchText={
          getAttendanceFlag?.error?.response?.data?.errorMessage[0]
        }
        loading={getAttendanceFlag.isFetching}
      />
      <TextArea
        label="Keterangan"
        name="description"
        value={values.description}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.description && touched.description)}
        errorText={
          Boolean(errors.description && touched.description) &&
          errors.description
        }
        disabled={
          Boolean(values?.flag === "-") || Boolean(values?.flag === "MASUK")
        }
      />
      {modal.error && <Label type="error" text={modal.errorText} />}
    </>
  );
};
