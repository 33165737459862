import { SchoolsApi } from "api";
import {
  CreateButton,
  EditButton,
  ViewButton,
  LabelDanger,
  LabelSuccess,
  ListLayout,
  SearchInput,
  Modal,
} from "components";
import { Formik } from "formik";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import * as Yup from "yup";
import TahunAjaranApi from "./__TahunAjaranApi__";
import {
  FormSection,
  SetTahunAjaranSection,
} from "./__TahunAjaranComponents__";

export const TahunAjaran = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: undefined,
    direction: "DESC",
  });
  const [isActive, setIsActive] = useState("none");
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getTahunAjaran.data?.pageable?.pageNumber - 1) *
          getTahunAjaran.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Tahun Ajaran",
      selector: (row) => row.periode,
      wrap: true,
    },
    {
      name: "Status",
      cell: (data) =>
        data?.isActive ? (
          <LabelSuccess text="Aktif" />
        ) : (
          <LabelDanger text="Tidak Aktif" />
        ),
      wrap: true,
      width: "120px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCHOOL_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
        </>
      ),
    },
  ];

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const activeDataHandler = (e) => {
    if (e.target.name !== "all") {
      setPagination({
        ...pagination,
        isActive: e.target.name,
      });
      setIsActive(e.target.name);
    } else {
      setPagination({
        ...pagination,
        isActive: undefined,
      });
      setIsActive("none");
    }
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onSetTahunAjaranButton = () =>
    setModal({
      show: true,
      type: "set",
      errorText: "",
      error: false,
      data: {},
    });

  // useQuery
  const getTahunAjaran = useQuery(["tahun-ajaran-list", pagination], () =>
    TahunAjaranApi.getList(axiosPrivate, paginationHandler(pagination)).catch(
      () => {
        return [];
      }
    )
  );

  // <--- useMutation --->
  const createTahunAjaran = useMutation(
    (data) => TahunAjaranApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getTahunAjaran.refetch();
      },
    }
  );

  const updateTahunAjaran = useMutation(
    ({ data, publicId }) => TahunAjaranApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getTahunAjaran.refetch();
      },
    }
  );

  const setActiveTahunAjaran = useMutation(
    (publicId) => TahunAjaranApi.setActive(axiosPrivate, publicId),
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  // <--- Form --->
  const formInitialValues = {
    publicId: modal.type !== "create" ? modal?.data?.publicId ?? "" : undefined,
    periode: modal.type !== "create" ? modal?.data?.periode ?? "" : "",
  };

  const formValidationSchema = Yup.object().shape({
    periode: Yup.string().required("Tahun Ajaran wajib diisi"),
  });

  return (
    <>
      {/* <--- Search input, Create Button, and Table ---> */}
      <div className="sm:flex">
        <div className="sm:flex-1">
          <SearchInput onChange={searchDataHandler} />
        </div>
        <div className="flex sm:justify-end mt-3 sm:mt-0">
          {permissions.includes("SCHOOL_U") && (
            <ViewButton
              type="button"
              text="Set Tahun Ajaran"
              className="mr-3"
              onClick={onSetTahunAjaranButton}
            />
          )}
          {permissions.includes("SCHOOL_C") && (
            <CreateButton type="button" onClick={onClickCreateButton} />
          )}
        </div>
      </div>
      <ListLayout
        data={getTahunAjaran.data?.content}
        columns={columns}
        loading={getTahunAjaran.isFetching}
        error={getTahunAjaran.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getTahunAjaran.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        activeStatusFilter
        activeOnChange={activeDataHandler}
        activeValues={isActive}
        customRightTopSection={() => <></>}
        customLeftTopSection={() => <></>}
      />

      {/* <--- MODAL CREATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createTahunAjaran
            .mutateAsync(values)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;

          return (
            <Modal
              id="modal-tambah-tahun-ajaran"
              header="Tambah Tahun Ajaran"
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "create")}
              onSubmit={handleSubmit}
              isSubmitting={createTahunAjaran.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL UPDATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          updateTahunAjaran
            .mutateAsync({
              data: values,
              publicId: values.publicId,
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-ubah-tahun-ajaran"
              header="Ubah Tahun Ajaran"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "update")}
              onSubmit={handleSubmit}
              isSubmitting={updateTahunAjaran.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL SET TAHUN AJARAN ---> */}
      <Formik
        enableReinitialize
        initialValues={{
          schoolPeriodePublicId: "",
        }}
        validationSchema={Yup.object().shape({
          schoolPeriodePublicId: Yup.string().required(
            "Tahun Ajaran Baru wajib dipilih"
          ),
        })}
        onSubmit={(values, { resetForm }) => {
          setActiveTahunAjaran
            .mutateAsync(values?.schoolPeriodePublicId)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-set-tahun-ajaran"
              header="Set Tahun Ajaran"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "set")}
              onSubmit={handleSubmit}
              isSubmitting={setActiveTahunAjaran.isLoading}
            >
              <SetTahunAjaranSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
