import { Table } from "antd";

export const AntdTable = ({ columns, data, loading, pagination, size, scrollY }) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={pagination}
      scroll={{
        x: 1300,
        y: scrollY,
      }}
      bordered
      size={size}
    />
  );
};
