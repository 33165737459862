import { PrintLayout, TablePrint } from "components";
import { forwardRef } from "react";
import { getMonth } from "utilities";
import {
  generateBodyTablePresensiBulanan,
  generateHeaderTablePresensiBulanan,
} from "../__PresensiBulananSiswaUtilities__";

export const CetakPresensiBulananSiswa = forwardRef(({ data, filter }, ref) => {
  const splitMonthYear = filter.monthYear.split("-");
  const year = splitMonthYear[1];
  const month = getMonth(Number(splitMonthYear[0])-1);

  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {
          "\
        @page { size: landscape; }\
      "
        }
      </style>
      <PrintLayout
        footer={
          <div>
            <CetakKeteranganHeaderTabel />
            <CetakKeteranganIsiTabel />
          </div>
        }
      >
        <>
          {/* <-- TITLE --> */}
          <div className="text-center">
            <h3 className="font-bold">LAPORAN PRESENSI BULANAN SISWA</h3>
            <h4 className="font-semibold">
              Bulan {month} {year} - Kelas {filter.grade}-{filter.classroom}
            </h4>
            <small className="font-semibold">
              Tahun Ajaran {filter?.periode}
            </small>
          </div>
          <TabelPresensi data={data} />
        </>
      </PrintLayout>
    </div>
  );
});

const TabelPresensi = ({ data }) => {
  const TABLE_HEADER = generateHeaderTablePresensiBulanan(data);
  const TABLE_BODY = generateBodyTablePresensiBulanan(data?.students?.[0].date);

  return (
    <div className="mt-5">
      <TablePrint
        data={data.students}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </div>
  );
};

const CetakKeteranganHeaderTabel = () => {
  return (
    <div className="w-full text-sm inline-flex">
      <div className="font-semibold mr-5" style={{ fontSize: "10px" }}>
        Keterangan Header Tabel:
      </div>
      <div className="flex flex-row space-x-10">
        <div className="flex" style={{ fontSize: "10px" }}>
          M<div className="mx-2">:</div> Masuk
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          T<div className="mx-2">:</div> Terlambat
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          I<div className="mx-2">:</div> Izin
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          S<div className="mx-2">:</div> Sakit
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          A<div className="mx-2">:</div> Tidak Presensi
        </div>
      </div>
    </div>
  );
};

const CetakKeteranganIsiTabel = () => {
  return (
    <div className="w-full text-sm inline-flex mt-2">
      <div className="font-semibold mr-5" style={{ fontSize: "10px" }}>
        Keterangan Isi Tabel:
      </div>
      <div className="flex flex-row space-x-10">
        <div className="flex" style={{ fontSize: "10px" }}>
          M<div className="mx-2">:</div> Masuk
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          T<div className="mx-2">:</div> Terlambat
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          I<div className="mx-2">:</div> Izin
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          S<div className="mx-2">:</div> Sakit
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          IM<div className="mx-2">:</div> Izin Meninggalkan Sekolah
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          IT<div className="mx-2">:</div> Izin Terlambat
        </div>
        <div className="flex" style={{ fontSize: "10px" }}>
          -<div className="mx-2">:</div> Tidak Presensi
        </div>
      </div>
    </div>
  );
};
