import parse from "html-react-parser";

export const DetailSection = ({ data }) => {
  return (
    <div className="space-y-3">
      <div className="text-center text-2xl font-semibold">{data?.title}</div>
      <hr className="mt-5" />
      <div className="mt-5 mb-5">{parse(data?.body)}</div>
    </div>
  );
};
