import { ClassroomsApi, GradeApi } from "api";
import { Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";

export const ContentPindahKelas = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched } = useFormikContext();

  // <--- useQuery --->
  const getTingkatanKelas = useQuery(["tingkatan-kelas-list"], () =>
    GradeApi.getGrade(axiosPrivate, {
      isActive: true,
      sortBy: "name",
      direction: "ASC",
    })
  );

  const getKelas = useQuery(
    ["kelas-list", values.destinationGradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values.destinationGradePublicId,
        sortBy: "name",
        direction: "ASC",
      }),
    { enabled: Boolean(values.destinationGradePublicId !== "") }
  );

  return (
    <div className="space-y-3">
      <Input
        label="Nama Siswa"
        value={modal?.data?.name}
        wrapperClassName="flex-1 ml-1"
        disabled
      />
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <Input
          label="NIS (Nomor Induk Siswa)"
          value={modal?.data?.nis}
          wrapperClassName="sm:flex-1 sm:mr-1"
          disabled
        />
        <Input
          label="Kelas"
          value={`${values?.tingkatanKelas} ${values?.kelas}`}
          wrapperClassName="sm:flex-1 sm:ml-1"
          disabled
        />
      </div>
      <Select
        label="Tingkatan Kelas"
        placeholder="Pilih Tingkatan Kelas"
        defaultValue={getTingkatanKelas?.data?.find(
          (item) => item.value === values.destinationGradePublicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            destinationGradePublicId: val.value,
            destinationClassroomPublicId: "",
          })
        }
        options={getTingkatanKelas?.data ?? []}
        error={Boolean(
          errors?.destinationGradePublicId && touched?.destinationGradePublicId
        )}
        errorText={
          Boolean(
            errors?.destinationGradePublicId &&
              touched?.destinationGradePublicId
          ) && errors?.destinationGradePublicId
        }
        errorFetch={getTingkatanKelas.isError}
        errorFetchText={
          getTingkatanKelas?.error?.response?.data?.errorMessage[0]
        }
        loading={getTingkatanKelas.isFetching}
      />
      <Select
        label="Kelas Tujuan"
        placeholder="Pilih Kelas Tujuan"
        defaultValue={getKelas?.data?.find(
          (item) => item.value === values?.destinationClassroomPublicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            destinationClassroomPublicId: val.value,
          })
        }
        options={
          getKelas?.data?.filter(
            (val) => val.value !== values.originClassroomPublicId
          ) ?? []
        }
        error={Boolean(
          errors?.destinationClassroomPublicId &&
            touched?.destinationClassroomPublicId
        )}
        errorText={
          Boolean(
            errors?.destinationClassroomPublicId &&
              touched?.destinationClassroomPublicId
          ) && errors?.destinationClassroomPublicId
        }
        errorFetch={getKelas.isError}
        errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
        loading={getKelas.isFetching}
        disable={Boolean(values.destinationGradePublicId === "")}
      />
    </div>
  );
};
