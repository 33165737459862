import * as Yup from 'yup'

export const formValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required('Nama Siswa wajib diisi'),
        nis: Yup.string()
            .required('NIS wajib diisi'),
        username: Yup.string()
            .required('Username wajib diisi'),
        gender: Yup.string()
            .required('Wajib dipilih')
            .nullable(),
        dateOfBirth: Yup.date()
            .required('Tanggal Lahir wajib diisi'),
        phoneNo: Yup.string()
            .required('No. Hp wajib diisi (cth. 08123456789)'),
        email: Yup.string().email()
            .required('Email wajib diisi (cth. example@email.com)')
            .nullable(),
        street: Yup.string()
            .required('Alamat wajib diisi'),
        address: Yup.object().shape({
            desaId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            kecamatanId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            kabupatenId: Yup.string()
                .required('Wajib dipilih')
                .nullable(),
            provinsiId: Yup.string()
                .required('Wajib dipilih')
                .nullable()
        })
    })
}

export const naikKelasValidationSchema = () => {
    return Yup.object().shape({
        destinationGradePublicId: Yup.string()
            .required('Wajib dipilih')
            .nullable(),
        destinationClassroomPublicId: Yup.string()
            .required('Wajib dipilih')
            .nullable()
    })
}

export const pindahKelasValidationSchema = () => {
    return Yup.object().shape({
        destinationGradePublicId: Yup.string()
            .required('Wajib dipilih')
            .nullable(),
        destinationClassroomPublicId: Yup.string()
            .required('Wajib dipilih')
            .nullable()
    })
}

export const importSiswaValidationSchema = () => {
    return Yup.object().shape({
        dataFile: Yup.mixed()
            .required('Wajib pilih file')
            .nullable()
    })
}