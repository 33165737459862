import { 
    DataStatus,
    LabelDanger,
    LabelInfo,
    LabelSuccess,
    RowDetailLayout 
} from "components"
import { 
    useQuery 
} from "react-query";
import { 
    useAxiosPrivate 
} from "utilities";
import PenggunaApi from "../__PenggunaApi__";

export const DetailSection = ({ data }) => {
    const axiosPrivate = useAxiosPrivate();
    const { publicId } = data?.data

    // <--- useQuery --->
    const getDetail = useQuery(
        [`detail-pengguna-${publicId}`],
        () => PenggunaApi.detail(axiosPrivate, publicId),
        { enabled: Boolean(data?.data?.publicId) }
    )

    if(getDetail.isFetching){
        return (
            <DataStatus 
                loading={getDetail.isFetching}
                loadingText='Memuat data pengguna'
                text={getDetail.error?.response?.data?.errorMessage[0]}
            />
        )
    } else{
        const dataDetail = getDetail?.data

        return (
            <>
                <RowDetailLayout 
                    label='Nama'
                    text={dataDetail?.name}
                />
                <RowDetailLayout 
                    label='Username'
                    text={dataDetail?.username}
                />
                <RowDetailLayout 
                    label='email'
                    text={dataDetail?.email}
                />
                <RowDetailLayout 
                    label='Role'
                    withComponent
                    component={<span className="w-auto">{dataDetail?.roles.map((val, index) => <span key={index} className={`${dataDetail.roles.length !== index + 1 && 'mr-1'}`}><LabelInfo text={val?.name} /></span>)}</span>}
                />
                <RowDetailLayout 
                    label='Status'
                    withComponent
                    component={<span className="w-auto">{dataDetail?.isBlocked ? <LabelDanger text='Terblokir' /> : <LabelSuccess text='Aktif' />}</span>}
                />
            </>
        )
    }
}
