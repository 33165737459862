import React from 'react'

export const Label = ({
    type,
    text
}) => {
    return (
        <div className='flex items-center'>
            <label className={`w-full text-center ${type === 'error' && 'validation-error-text'} ${type === 'success' && 'validation-success-text'}`}>
                {text}
            </label>
        </div>
    )
}
