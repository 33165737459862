import * as Yup from "yup";

export const formValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required("Judul wajib diisi"),
    body: Yup.string().required("Konten wajib diisi"),
    category: Yup.object().shape({
      publicId: Yup.string().required("Wajib dipilih").nullable(),
    }),
  });
};
