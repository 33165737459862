// Components
import { 
    Input, 
    Label, 
    RadioButton, 
    RadioButtonGroup, 
    RadioButtonWrapper, 
    TextArea 
} from "components"

// Form
import { useFormikContext } from "formik"

export const FormSection = ({ modal }) => {
    const { values, setValues, errors, touched, handleChange } = useFormikContext()

    return (
        <>
            <Input 
                label='Nama Regional' 
                name='name'
                type='text'
                value={values.name}
                onChange={handleChange}
				error={Boolean(errors.name && touched.name)}
				errorText={Boolean(errors.name && touched.name) && errors.name}
            />
            <TextArea 
                label='Keterangan (opsional)'
                name='description'
                value={values.description}
                rows={4}
                onChange={handleChange}
				error={Boolean(errors.description && touched.description)}
				errorText={Boolean(errors.description && touched.description) && errors.description}
            />
            <RadioButtonWrapper label='Status'>
                <RadioButtonGroup>
                    <RadioButton 
                        checked={Boolean(values.isActive)}
                        label='Aktif'
                        onChange={() => setValues({...values, isActive: true})}
                    />
                    <RadioButton 
                        checked={Boolean(!values.isActive)}
                        label='Tidak Aktif'
                        onChange={() => setValues({...values, isActive: false})}
                    />
                </RadioButtonGroup>
            </RadioButtonWrapper>
            {modal.error && 
                <Label 
                    type='error'
                    text={modal.errorText}
                />
            }
        </>
    )
}
