import { SchoolsApi } from 'api'
import { Input, Label, Select } from 'components'
import { useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { useAxiosPrivate } from 'utilities'

export const SetTahunAjaranSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate()
  const { values, setValues, errors, touched, handleChange } = useFormikContext()

  const getTahunAjaranAktif = useQuery(['tahun-ajaran-aktif'], () => SchoolsApi.getTahunAjaran(axiosPrivate, {isActive: true}), {enabled: Boolean(modal.show && modal.type === 'set')})
  const getTahunAjaran = useQuery(['tahun-ajaran-dropdown'], () => SchoolsApi.getTahunAjaran(axiosPrivate, {isActive: false, direction: 'DESC'}), {enabled: Boolean(modal.show && modal.type === 'set')})

  return (
    <div className='space-y-3'>
      <Input 
        label='Tahun Ajaran Sekarang'
        disabled
        value={getTahunAjaranAktif.isFetching ? 'Memuat data...' : getTahunAjaranAktif?.data?.[0]?.label}
      />
      <Select 
        label='Tahun Ajaran Baru'
        placeholder='Pilih Tahun Ajaran Baru'
        name='schoolPeriodePublicId'
        defaultValue={getTahunAjaran?.data?.find(item => item.value === values.schoolPeriodePublicId)}
        onChange={val => setValues({...values, schoolPeriodePublicId: val.value})}
        options={getTahunAjaran?.data ?? []}
        error={Boolean(errors.schoolPeriodePublicId && touched.schoolPeriodePublicId)}
        errorText={Boolean(errors.schoolPeriodePublicId && touched.schoolPeriodePublicId) && errors.schoolPeriodePublicId}
        errorFetch={getTahunAjaran.isError}
        errorFetchText={getTahunAjaran?.error?.response?.data?.errorMessage[0]}
        loading={getTahunAjaran.isFetching}
      />
      {modal.error && 
        <Label 
          type='error'
          text={modal.errorText}
        />
      }
    </div>
  )
}
