export const TablePrint = ({
  tableHeader = [],
  tableBody = [],
  data = [],
  tableId = ""
}) => {
  return (
    <div>
      <table id={tableId} className="min-w-full border border-clobasoft-medium-gray text-[11px]">
        <thead className="border-b border-clobasoft-medium-gray">
          <tr>
            {tableHeader.map((val, index) => {
              return (
                <th 
                  key={index} 
                  scope='col' 
                  style={val?.props}
                  className={`text-black px-1 py-2 text-center border-r border-clobasoft-medium-gray font-bold`}
                >
                  {val.text}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? data.map((val, index) => {
            return (
              <tr key={index} className="border-b border-clobasoft-medium-gray">
                {tableBody.map((td, idx) => {
                  return (
                    <td 
                      key={idx} 
                      style={td?.props} 
                      className='whitespace-normal px-2 py-1 border-r border-clobasoft-medium-gray text-xs'
                    >
                      {td.field(val, index)}
                    </td>
                  )
                })}
              </tr>
            )
          }) : <tr><td colSpan={tableHeader.length} className="text-xs text-center py-3">Tidak Ada Data</td></tr>}
        </tbody>
      </table>
    </div>
  )
}