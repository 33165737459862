import {
  CreateButton,
  DatePicker,
  DeleteButon,
  EditButton,
  ListLayout,
  Modal,
  SearchInput,
} from "components";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import { Formik } from "formik";
import { DetailSection, FormSection } from "./__HariLiburComponents__";
import * as Yup from "yup";
import HariLiburApi from "./__HariLiburApi__";

export const HariLibur = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  // <--- States --->
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: undefined,
    direction: "DESC",
    sortBy: "eventDate",
    year: selectedYear.getFullYear(),
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getHariLibur.data?.pageable?.pageNumber - 1) *
          getHariLibur.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Tanggal",
      selector: (row) => getDashDMY(new Date(row.eventDate)),
      wrap: true,
      width: "150px",
    },
    {
      name: "Keterangan",
      selector: (row) => (row.name === "" ? "-" : row.name),
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("SCH_LIB_HD_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("SCH_LIB_HD_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  // <--- useQuery --->
  const getHariLibur = useQuery(["hari-libur-list", pagination], () =>
    HariLiburApi.getList(axiosPrivate, paginationHandler(pagination)).catch(
      () => {
        return [];
      }
    )
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onChangeYear = (date) => {
    setSelectedYear(new Date(date));
    setPagination({
      ...pagination,
      year: new Date(date).getFullYear(),
    });
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- Form --->
  const formInitialValues = {
    publicId: modal.type !== "create" ? modal?.data?.publicId ?? "" : undefined,
    eventDate: modal.type !== "create" ? modal?.data?.eventDate ?? "" : "",
    name: modal.type !== "create" ? modal?.data?.name ?? "" : "",
    isActive: modal.type !== "create" ? modal?.data?.isActive ?? true : true,
  };

  const formValidationSchema = Yup.object().shape({
    eventDate: Yup.string().required("Tanggal Hari Libur wajib diisi"),
    name: Yup.string().required("Keterangan Hari Libur wajib diisi"),
  });

  // <--- useMutation --->
  const createHariLibur = useMutation(
    (data) => HariLiburApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getHariLibur.refetch();
      },
    }
  );

  const updateHariLibur = useMutation(
    ({ data, publicId }) => HariLiburApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getHariLibur.refetch();
      },
    }
  );

  const deleteHariLibur = useMutation(
    (id) => HariLiburApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getHariLibur.refetch();
      },
    }
  );

  return (
    <>
      <div className="flex flex-row items-end">
        <div className="flex flex-row flex-1 items-end">
          <div className="mr-5">
            <DatePicker
              label="Tahun"
              dateFormat="yyyy"
              showYearPicker
              selected={selectedYear}
              onChange={onChangeYear}
            />
          </div>
          <div className="flex-1">
            <SearchInput onChange={searchDataHandler} />
          </div>
        </div>
        <div>
          {permissions.includes("SCH_LIB_HD_C") && (
            <CreateButton type="button" onClick={onClickCreateButton} />
          )}
        </div>
      </div>
      <ListLayout
        permissions={permissions}
        data={getHariLibur.data?.content}
        columns={columns}
        loading={getHariLibur.isFetching}
        error={getHariLibur.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getHariLibur.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        customRightTopSection={() => <></>}
        customLeftTopSection={() => <></>}
      />

      {/* <--- MODAL CREATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createHariLibur
            .mutateAsync(values)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;

          return (
            <Modal
              id="modal-tambah-hari-libur"
              header="Tambah Hari Libur"
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "create")}
              onSubmit={handleSubmit}
              isSubmitting={createHariLibur.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL UPDATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          updateHariLibur
            .mutateAsync({
              data: values,
              publicId: values.publicId,
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-ubah-hari-libur"
              header="Ubah Hari Libur"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "update")}
              onSubmit={handleSubmit}
              isSubmitting={updateHariLibur.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL DELETE ---> */}
      <Modal
        id="modal-hapus-hari-libur"
        header="Hapus Hari Libur"
        size="small"
        type="delete"
        onHide={onHideModalHandler}
        show={Boolean(modal.show && modal.type === "delete")}
        isSubmitting={deleteHariLibur.isLoading}
        onSubmit={() => {
          deleteHariLibur
            .mutateAsync(modal.data.publicId)
            .then((res) => {
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        <DetailSection data={modal} />
      </Modal>
    </>
  );
};
