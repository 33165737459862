import React from "react";
import ReactQuill, { Quill } from "react-quill";
import BlotFormatter from "quill-format-img";
import "react-quill/dist/quill.snow.css";

Quill.register("modules/blotFormatter", BlotFormatter);

export const InputEditor = ({
  label,
  value,
  placeholder,
  onChange,
  error,
  errorText,
}) => {
  return (
    <div>
      <small>{label}</small>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        scrollingContainer="#scrolling-container"
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "align",
          "image",
        ]}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ align: [] }],
            ["link", "image"],
            ["clean"],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          blotFormatter: {},
        }}
      />
      {error && (
        <div>
          <small className="validation-error-text">{errorText}</small>
        </div>
      )}
    </div>
  );
};
