const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class SetupQnaApi {
  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/qna/pageable`, {
      params,
    });
    return fetch.data.payload;
  }
  
  async detail(axiosPrivate, id) {
    const fetch = await axiosPrivate.get(`${url}/v1/qna/${id}`);
    return fetch.data.payload;
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v1/qna`, data);
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.put(`${url}/v1/qna/${id}`, data);
  }

  delete(axiosPrivate, id) {
    return axiosPrivate.delete(`${url}/v1/qna/${id}`);
  }
}

export default new SetupQnaApi();
