import { RowDetailLayout } from "components";
import React from "react";
import { getDashDMY } from "utilities";
import { ConvertAbsencePermitFlag } from ".";

export const DetailSection = ({ data }) => {
  return (
    <>
      <RowDetailLayout label="Nama Siswa" text={data?.studentNama} />
      <RowDetailLayout
        label="NIS (Nomor Induk Siswa)"
        text={data?.studentNis}
      />
      <RowDetailLayout label="Kelas" text={data?.studentClass} />
      <RowDetailLayout
        label="Tanggal Awal Izin"
        text={getDashDMY(new Date(data?.startDate)) ?? "-"}
      />
      <RowDetailLayout
        label="Tanggal Akhir Izin"
        text={getDashDMY(new Date(data?.endDate)) ?? "-"}
      />
      <RowDetailLayout label="Keterangan Izin" text={data?.description} />
      <RowDetailLayout
        label="Status"
        text={
          data?.flag ? (
            <ConvertAbsencePermitFlag flag={data?.flag} status={data?.status} />
          ) : (
            "-"
          )
        }
      />
      <RowDetailLayout label="Catatan" text={data?.statusDescription} />
    </>
  );
};
