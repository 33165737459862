import React from "react";
import { RowDetailLayout } from "components";
import { getDashDMY } from "utilities";

export const DetailSection = ({ data }) => {
  return (
    <>
      <RowDetailLayout
        label="Tanggal"
        text={getDashDMY(new Date(data.data.eventDate))}
      />
      <RowDetailLayout label="Keterangan" text={data.data.name} />
    </>
  );
};
