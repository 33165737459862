import React from "react";
import { useQuery } from "react-query";
import { hexToRGB, useAxiosPrivate } from "utilities";
import BlogApi from "../__BlogApi__";
import parse from "html-react-parser";
import { DataStatus, InputImageMultiple } from "components";

export const DetailSection = ({ data }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = data?.data;

  const getDetail = useQuery(
    ["blog-atribut-detail", publicId],
    () => BlogApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(data.show && data.type !== "create") }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data blog..."
        text="Data blog gagal dimuat"
      />
    );
  } else {
    return (
      <div className="space-y-3">
        <div className="text-center text-2xl font-semibold">
          {/* JUDUL */}
          {getDetail?.data?.title}
        </div>
        <div className="text-center text-sm">
          {/* KATEGORI */}
          Kategori:
          <label
            className={`text-sm py-1 px-3 rounded-md font-bold w-fit ml-3`}
            style={{
              color: getDetail?.data?.category?.colorCode,
              backgroundColor: hexToRGB(
                getDetail?.data?.category?.colorCode,
                0.2
              ),
            }}
          >
            {getDetail?.data?.category?.name}
          </label>
        </div>
        <div className="text-center text-sm">
          {/* AUTHOR */}
          Dibuat oleh. {getDetail?.data?.user?.name}
        </div>
        <div className="text-center text-sm">
          Dibuat pada tanggal: {getDetail?.data?.createdAt}
        </div>
        {getDetail?.data?.createdAt !== getDetail?.data?.updatedAt ? (
          <div className="text-center text-sm">
            Diubah pada tanggal: {getDetail?.data?.updatedAt}
          </div>
        ) : null}
        <hr className="mt-5" />
        <div className="mt-5 mb-5">{parse(getDetail?.data?.body)}</div>
      </div>
    );
  }
};
