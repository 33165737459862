import { Spinner } from "../.."

export const LoginButton = ({
    isSubmitting,
    onClick
}) => {
    return (
        <button type='submit' disabled={isSubmitting} onClick={onClick} className='w-full mt-2 p-2 btn-primary'>
            {isSubmitting ? <><Spinner /><span className="ml-2">Harap Tunggu...</span></> : "Masuk"}
        </button>
    )
}