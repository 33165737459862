import { SchoolsApi } from "api";
import { Select, Table } from "components";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";
import BerandaApi from "../__BerandaApi__";
import { useEffect, useState } from "react";

export const TabelKegiatan = () => {
  const axiosPrivate = useAxiosPrivate();
  const [tahunAjaran, setTahunAjaran] = useState(undefined);

  // <--- Table's columns --->
  const columns = [
    {
      name: "No",
      selector: (_, index) => index + 1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.nis,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.name,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "100px",
      wrap: true,
    },
    {
      name: "Poin",
      selector: (row) => row?.totalPoint,
      wrap: true,
      width: "100px",
    },
  ];

  // <--- useQuery --->
  const getTahunAjaran = useQuery(
    ["tabel-kegiatan-atribut-tahun-ajaran", "dropdown"],
    () => SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" }),
    {
      onSuccess: (res) => {
        setTahunAjaran(res?.find((val) => val?.isActive)?.label);
      },
    }
  );

  const getListTopTenKegiatan = useQuery(
    ["list-top-ten", tahunAjaran],
    () =>
      BerandaApi.getListTopTenKegiatan(axiosPrivate, {
        periode: tahunAjaran,
      }),
    { enabled: Boolean(tahunAjaran) }
  );

  return (
    <div>
      <h3 className="text-center font-semibold">
        TOP 10 POIN KEGIATAN TERTINGGI
      </h3>
      <div className="flex flex-row items-center my-5 justify-center">
        <label className="mr-2 text-sm">Tahun Ajaran</label>
        <Select
          placeholder="Pilih Tahun Ajaran"
          defaultValue={getTahunAjaran?.data?.find(
            (item) => item.label === tahunAjaran
          )}
          onChange={(val) => {
            setTahunAjaran(val.label);
          }}
          options={getTahunAjaran?.data}
          errorFetch={getTahunAjaran.isError}
          errorFetchText={
            getTahunAjaran?.error?.response?.data?.errorMessage[0]
          }
          loading={getTahunAjaran.isFetching}
        />
      </div>
      <Table
        columns={columns}
        data={getListTopTenKegiatan?.data}
        loading={getListTopTenKegiatan.isFetching}
        error={getListTopTenKegiatan.error?.response?.data?.errorMessage[0]}
      />
    </div>
  );
};
