import { SearchIcon } from '@heroicons/react/outline';

export const SearchInput = ({
    onChange,
    placeholder="Masukkan kata kunci"
}) => {
    return (
        <div className="mt-1 flex rounded-md">
            <span className="inline-flex items-center px-2 rounded-l-md border border-r-0 border-gray-300 bg-clobasoft-light-gray text-gray-600 text-sm">
                <SearchIcon className="h-4 w-4 text-gray" />
            </span>
            <input type="text" className="search-primary" placeholder={placeholder} onChange={onChange} />
        </div>
    )
}