export const RadioButton = ({
    checked,
    name,
    onChange,
    label
}) => {
    return (
        <div>
            <input
                type='radio'
                checked={checked}
                name={name}
                onChange={onChange}
                className='radio-primary'
            />
            <small className="ml-2">{label}</small>
        </div>
    )
}
