import { AchievementApi, SchoolsApi } from "api";
import {
  DefaultButton,
  DataStatus,
  ListLayout,
  Select,
  PrintButton,
} from "components";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  paginationHandler,
  useAxiosPrivate,
} from "utilities";
import {
  CetakKegiatanSiswa,
  ExportKegiatanSiswa,
} from "./__KegiatanSiswaComps__";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import KegiatanSiswaApi from "./__KegiatanSiswaApi__";
import ReactToPrint from "react-to-print";

const ComponentToPrintWrapper = ({
  cetakData,
  filter,
  handleOnBeforePrint,
}) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <PrintButton
            icon
            text="Cetak"
            className="flex-grow"
            loading={cetakData.loading}
          />
        )}
        content={() => componentRef.current}
        removeAfterPrint={true}
        onBeforePrint={handleOnBeforePrint}
        documentTitle="Laporan Kegiatan Siswa"
      />
      <div style={{ display: "none" }}>
        <CetakKegiatanSiswa
          data={cetakData.data}
          filter={filter}
          ref={componentRef}
        />
      </div>
    </>
  );
};

export const KegiatanSiswa = () => {
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    status: 2,
    periode: undefined,
    achievementLibraryPublicId: undefined,
    achievementRegionalPublicId: undefined,
    achievementParticipationPublicId: undefined,
  });
  const [filter, setFilter] = useState({
    publicId: {
      status: 2,
      periode: undefined,
      achievementLibraryPublicId: undefined,
      achievementRegionalPublicId: undefined,
      achievementParticipationPublicId: undefined,
    },
    data: {
      periode: "",
      achievementLibrary: "",
      achievementRegional: "",
      achievementParticipation: "",
    },
  });
  const [cetakData, setCetakData] = useState({
    loading: false,
    data: [],
  });
  const [exportData, setExportData] = useState([]);

  // <--- Table's columns --->
  const columns = [
    {
      name: "No",
      selector: (_, index) =>
        (getKegiatanSiswaPageable.data?.pageable?.pageNumber - 1) *
          getKegiatanSiswaPageable.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentName,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "80px",
      wrap: true,
    },
    {
      name: "Kegiatan",
      selector: (row) => row?.name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Partisipasi",
      selector: (row) => row?.participationName,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Jenis Kegiatan",
      selector: (row) => row?.achievementName,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Regional",
      selector: (row) => row?.regional,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Tgl. Kegiatan",
      selector: (row) =>
        row?.activityDate ? getDashDMY(row?.activityDate) : "-",
      width: "100px",
    },
  ];

  // <--- useQuery --->
  const getKegiatanSiswaPageable = useQuery(
    ["kegiatan-siswa-list-pageable", pagination],
    () =>
      KegiatanSiswaApi.getListPrestasiPageable(
        axiosPrivate,
        paginationHandler(pagination)
      ),
    { enabled: Boolean(pagination?.achievementLibraryPublicId) }
  );

  const getKegiatanSiswa = useQuery(
    ["kegiatan-siswa-list", filter?.publicId],
    () => KegiatanSiswaApi.getListPrestasi(axiosPrivate, filter?.publicId),
    {
      enabled: Boolean(filter?.publicId?.achievementLibraryPublicId),
      onSuccess: (res) => {
        setCetakData({ ...cetakData, data: res, loading: false });
        setExportData(res);
      },
      onError: (err) => {
        errorFetching(err);
        setCetakData({ ...cetakData, data: [], loading: false });
        setExportData([]);
      },
    }
  );

  const getTahunAjaran = useQuery(["kesiswaan-atribut-tahun-ajaran"], () =>
    SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" })
  );

  const getJenisKegiatan = useQuery(
    ["kegiatan-siswa-atribut-jenis-kegiatan"],
    () =>
      AchievementApi.getAchievementLibrary(axiosPrivate, {
        isActive: true,
        hasAchievement: true,
      })
  );

  const getRegional = useQuery(
    ["kegiatan-siswa-atribut-regional", pagination?.achievementLibraryPublicId],
    () =>
      AchievementApi.getAchievementRegionals(axiosPrivate, {
        isActive: true,
        achievementLibraryPublicId: pagination?.achievementLibraryPublicId,
      }),
    { enabled: Boolean(pagination?.achievementLibraryPublicId) }
  );

  const getPartisipasi = useQuery(
    [
      "kegiatan-siswa-atribut-partisipasi",
      pagination?.achievementLibraryPublicId,
      pagination?.achievementRegionalPublicId,
    ],
    () =>
      AchievementApi.getAchievementParticipation(axiosPrivate, {
        isActive: true,
        achievementLibraryPublicId: pagination?.achievementLibraryPublicId,
        achievementRegionalPublicId: pagination?.achievementRegionalPublicId,
      }),
    {
      enabled: Boolean(
        pagination?.achievementLibraryPublicId &&
          pagination?.achievementRegionalPublicId
      ),
    }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  // <--- Print --->
  const handleOnBeforePrint = () => {
    setCetakData({ ...cetakData, loading: true });
    getKegiatanSiswa.refetch();
  };

  return (
    <>
      <div className="sm:flex sm:flex-row sm:space-x-3 sm:space-y-0 space-y-3">
        <div className="sm:flex-grow">
          <Select
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            defaultValue={getTahunAjaran?.data?.find(
              (item) => item.label === pagination?.periode
            )}
            onChange={(val) => {
              setPagination({
                ...pagination,
                periode: val?.label,
              });
              setFilter({
                publicId: {
                  ...filter.publicId,
                  periode: val?.label,
                },
                data: {
                  ...filter.data,
                  periode: val?.label,
                },
              });
            }}
            options={getTahunAjaran?.data ?? []}
            errorFetch={getTahunAjaran.isError}
            errorFetchText={
              getTahunAjaran?.error?.response?.data?.errorMessage[0]
            }
            loading={getTahunAjaran.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Jenis Kegiatan"
            placeholder="Pilih Jenis Kegiatan"
            defaultValue={getJenisKegiatan?.data?.find(
              (item) => item.value === pagination?.achievementLibraryPublicId
            )}
            onChange={(val) => {
              setPagination({
                ...pagination,
                achievementLibraryPublicId: val.value,
                achievementRegionalPublicId: undefined,
                achievementParticipationPublicId: undefined,
              });
              setFilter({
                publicId: {
                  ...filter.publicId,
                  achievementLibraryPublicId: val.value,
                  achievementRegionalPublicId: undefined,
                  achievementParticipationPublicId: undefined,
                },
                data: {
                  ...filter.data,
                  achievementLibrary: val.label,
                  achievementRegional: "",
                  achievementParticipation: "",
                },
              });
            }}
            options={getJenisKegiatan?.data ?? []}
            errorFetch={getJenisKegiatan.isError}
            errorFetchText={
              getJenisKegiatan?.error?.response?.data?.errorMessage[0]
            }
            loading={getJenisKegiatan.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Regional"
            placeholder="Pilih Regional"
            defaultValue={getRegional?.data?.find(
              (item) => item.value === pagination?.achievementRegionalPublicId
            )}
            onChange={(val) => {
              setPagination({
                ...pagination,
                achievementRegionalPublicId: val.value,
                achievementParticipationPublicId: undefined,
              });
              setFilter({
                publicId: {
                  ...filter.publicId,
                  achievementRegionalPublicId: val.value,
                  achievementParticipationPublicId: undefined,
                },
                data: {
                  ...filter.data,
                  achievementRegional: val.label,
                  achievementParticipation: "",
                },
              });
            }}
            options={getRegional?.data ?? []}
            errorFetch={getRegional.isError}
            errorFetchText={getRegional?.error?.response?.data?.errorMessage[0]}
            loading={getRegional.isFetching}
            disable={!Boolean(pagination?.achievementLibraryPublicId)}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Partisipasi"
            placeholder="Pilih Partisipasi"
            defaultValue={getPartisipasi?.data?.find(
              (item) =>
                item.value === pagination?.achievementParticipationPublicId
            )}
            onChange={(val) => {
              setPagination({
                ...pagination,
                achievementParticipationPublicId: val.value,
              });
              setFilter({
                publicId: {
                  ...filter.publicId,
                  achievementParticipationPublicId: val.value,
                },
                data: {
                  ...filter.data,
                  achievementParticipation: val.label,
                },
              });
            }}
            options={getPartisipasi?.data ?? []}
            errorFetch={getPartisipasi.isError}
            errorFetchText={
              getPartisipasi?.error?.response?.data?.errorMessage[0]
            }
            loading={getPartisipasi.isFetching}
            disable={
              !Boolean(
                pagination?.achievementLibraryPublicId &&
                  pagination?.achievementRegionalPublicId
              )
            }
          />
        </div>
        <div className="grid content-end">
          <DefaultButton
            text="Reset Pencarian"
            onClick={() => {
              setPagination({
                ...pagination,
                achievementLibraryPublicId: undefined,
                achievementRegionalPublicId: undefined,
                achievementParticipationPublicId: undefined,
              });
              setFilter({
                publicId: {
                  ...filter.publicId,
                  achievementLibraryPublicId: undefined,
                  achievementRegionalPublicId: undefined,
                  achievementParticipationPublicId: undefined,
                },
                data: {
                  ...filter.data,
                  achievementLibrary: "",
                  achievementRegional: "",
                  achievementParticipation: "",
                },
              });
            }}
          />
        </div>
        {cetakData?.data?.length > 0 && exportData?.length > 0 ? (
          <>
            <div className="grid content-end">
              <ComponentToPrintWrapper
                cetakData={cetakData}
                filter={filter?.data}
                handleOnBeforePrint={handleOnBeforePrint}
              />
            </div>
            <div className="grid content-end">
              <ReactHTMLTableToExcel
                id="btn-export-table-kegiatan-siswa"
                className="download-table-xls-button btn-success justify-center flex flex-row items-center p-2"
                table="export-table-kegiatan-siswa"
                filename={`Kegiatan_Siswa`}
                sheet={`Sheet 1`}
                buttonText="Export to Excel"
              />
            </div>
          </>
        ) : null}
      </div>
      {pagination?.achievementLibraryPublicId ||
      pagination?.achievementRegionalPublicId ||
      pagination?.achievementParticipationPublicId ? (
        <>
          <ListLayout
            data={getKegiatanSiswaPageable.data?.content}
            columns={columns}
            loading={getKegiatanSiswaPageable.isFetching}
            error={
              getKegiatanSiswaPageable.error?.response?.data?.errorMessage[0]
            }
            pagination={true}
            totalRows={getKegiatanSiswaPageable.data?.pageable?.totalElements}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            customLeftTopSection={() => <></>}
            customRightTopSection={() => <></>}
          />
          <div style={{ display: "none" }}>
            <ExportKegiatanSiswa data={exportData} />
          </div>
        </>
      ) : (
        <DataStatus text="Silahkan memilih pencarian jenis kegiatan terlebih dahulu" />
      )}
    </>
  );
};
