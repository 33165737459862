import {
  CreateButton,
  RadioButton,
  RadioButtonGroup,
  SearchInput,
  Table,
} from "../..";

export const ListLayout = ({
  permissions = [],
  permissionCreate,
  customRightTopSection,
  customLeftTopSection,
  customRightStatusSection,
  onSearchChange,
  onClickCreateButton,
  columns,
  data,
  loading,
  error,
  pagination,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  activeStatusFilter,
  activeValues,
  activeOnChange,
  activeText = [],
  showTable = true,
  tableId = "",
}) => {
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex-1">
            {customLeftTopSection ? (
              customLeftTopSection()
            ) : (
              <SearchInput onChange={onSearchChange} />
            )}
          </div>
          <div className="mt-1 ml-2">
            {customRightTopSection
              ? customRightTopSection()
              : permissions.includes(permissionCreate) && (
                  <CreateButton type="button" onClick={onClickCreateButton} />
                )}
          </div>
        </div>
        {activeStatusFilter && (
          <div className="flex flex-row">
            <div className="flex items-center mt-3 flex-1">
              <small className="mr-5 mt-1 font-medium">Status :</small>
              <RadioButtonGroup>
                <RadioButton
                  checked={activeValues === "none"}
                  label={activeText.length > 0 ? activeText[0] : "Semua"}
                  name="all"
                  onChange={activeOnChange}
                />
                <RadioButton
                  checked={activeValues === "true"}
                  label={activeText.length > 0 ? activeText[1] : "Aktif"}
                  name="true"
                  onChange={activeOnChange}
                />
                <RadioButton
                  checked={activeValues === "false"}
                  label={activeText.length > 0 ? activeText[2] : "Tidak Aktif"}
                  name="false"
                  onChange={activeOnChange}
                />
              </RadioButtonGroup>
            </div>
            <div className="ml-2">
              {customRightStatusSection ? customRightStatusSection() : <></>}
            </div>
          </div>
        )}
        {showTable && (
          <div className="mt-3">
            <Table
              tableId={tableId}
              columns={columns}
              data={data}
              loading={loading}
              error={error}
              pagination={pagination}
              totalRows={totalRows}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};
