import { mappingDataOptions } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class TingkatanKelasApi {
    async getList(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/grades/pageable`, { params })
        return fetch.data.payload
    }

    async getGuru(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/teachers`, { params })
        return mappingDataOptions(fetch.data.payload)
    }

    create(axiosPrivate, data) {
        return axiosPrivate.post(`${url}/v1/grades`, data)
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/grades/${id}`, data)
    }

    delete(axiosPrivate, id) {
        return axiosPrivate.delete(`${url}/v1/grades/${id}`)
    }
}

export default new TingkatanKelasApi()