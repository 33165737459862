import { CreateButton, DefaultButton, DeleteButon } from "components";
import { HiPhotograph } from "react-icons/hi";
import React, { useCallback, useMemo, useRef, useState } from "react";
import ImageViever from "react-simple-image-viewer";
import FileSaver from "file-saver";
import { FaDownload } from "react-icons/fa";

export const InputImageMultiple = ({
  type = "view" || "action",
  data,
  showCreateButton = true,
  acceptFileType = ["jpg", "png", "gif", "jpeg"],
  setData,
  disabledButton,
  label,
}) => {
  const inputFileRef = useRef();
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: 0,
  });

  // Trigger input file agar terclick
  const onClickFilesHandler = () =>
    Boolean(type !== "view") &&
    Boolean(inputFileRef?.current ? inputFileRef.current.click() : false);

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = e.target.files && e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split("/")[1];

    // Check jika type file sudah benar
    if (acceptFileType.includes(getFileType)) {
      const generateName = file.name;
      const generateLink = URL.createObjectURL(file);
      const finalValue = {
        data: file,
        name: generateName,
        fileUrl: generateLink,
      };

      return setData([...data, finalValue]);
    } else {
      return window.alert(
        `Format file tidak valid, harap masukan file berformat ${acceptFileType.join(
          ", "
        )}`
      );
    }
  };

  const filterImageHandler = data
    .map((val, index) => ({ ...val, index: index }))
    .filter((val, index) => {
      const file = val.name ? val.name : val.fileUrl;
      const getFileType = file?.split(".")
        ? String(file?.split(".").pop())
        : "";

      // Check jika type file sudah benar
      if (acceptFileType.includes(getFileType)) {
        return {
          ...val,
          index: index,
        };
      } else {
        return false;
      }
    });

  // Filter data gambar agar array hanya terisi link gambar (untuk ImageViewer)
  const filterLinkHandler = useMemo(() => {
    return data.map((val) => val.fileUrl);
  }, [data]);

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback((index) => {
    setViewImageConfig({
      show: true,
      index: index,
    });
  }, []);

  // Menangani hapus file
  const onDeleteHandler = useCallback(
    (index) => {
      const filterData = data.filter((_val, _index) => _index !== index);
      setData(filterData);
    },
    [data, setData]
  );

  return (
    <div>
      <small>{label}</small>
      {/* Button Section */}
      <div className={`mb-3`}>
        <input
          ref={inputFileRef}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{ display: "none" }}
          onChange={onChangeFileHandler}
        />
        {Boolean(type !== "view" && showCreateButton) && (
          <>
            <CreateButton
              text="Tambah Gambar"
              tooltip={false}
              onClick={onClickFilesHandler}
              disabled={disabledButton}
            />
            <small
              className="text-red-600 font-semibold"
              style={{ marginRight: "1em" }}
            >
              *** Ukuran file max. 2 MB
            </small>
          </>
        )}
      </div>

      {/* Image Section */}
      <div
        className="bg-white border-clobasoft-light-gray border-[1px] rounded-md"
        onClick={onClickFilesHandler}
      >
        {filterImageHandler && filterImageHandler.length > 0 ? (
          <div className="flex flex-row">
            {filterImageHandler.map((val, index) => (
              <div className="p-4 w-96 h-96">
                <div className="w-100 flex flex-row justify-center">
                  <img
                    alt={val.name}
                    key={index}
                    src={val.fileUrl}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIndexImageHandler(index);
                    }}
                    className="h-80 w-full object-cover"
                  />
                </div>
                <div className="grid grid-cols-2 p-2">
                  <div>
                    <DefaultButton
                      noText
                      icon
                      customIcon={<FaDownload />}
                      onClick={(e) => {
                        e.stopPropagation();
                        FileSaver.saveAs(val.fileUrl, val.name);
                      }}
                    />
                  </div>
                  {type !== "view" ? (
                    <div className="col-end-4">
                      <DeleteButon
                        noText
                        icon
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteHandler(index);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center py-5">
            <HiPhotograph size={60} className="text-clobasoft-medium-gray" />
            <span className="mt-2 text-clobasoft-medium-gray">
              Tidak ada gambar
            </span>
          </div>
        )}
      </div>

      {/*Image Viewer  */}
      {viewImageConfig.show && (
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={filterLinkHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 10000 }}
          />
        </div>
      )}
    </div>
  );
};
