import { useQuery } from "react-query";
import {
  getDashDMY,
  mappingDataGender,
  paginationHandler,
  useAxiosPrivate,
} from "utilities";
import {
  Card,
  DataStatus,
  Label,
  RowDetailLayout,
  Tabs,
  Table,
} from "components";
import { useState } from "react";
import { StudentApi } from "api";
import AlumniApi from "../__AlumniApi__";

const TabInformasiUmum = ({ data, error }) => {
  return (
    <>
      <RowDetailLayout label="Nama Siswa" text={data?.name} />
      <RowDetailLayout label="NIS (Nomor Induk Siswa)" text={data?.nis} />
      <RowDetailLayout
        label="Jenis Kelamin"
        text={mappingDataGender(data?.gender)}
      />
      <RowDetailLayout
        label="Tanggal Lahir"
        text={data?.dateOfBirth && getDashDMY(data?.dateOfBirth)}
      />
      <RowDetailLayout label="Email" text={data?.email} />
      <RowDetailLayout
        label="Status"
        text={data?.isActive ? "Aktif" : "Tidak Aktif"}
      />
      {error.error && <Label type="error" text={error.errorText} />}
    </>
  );
};

const TabAlamat = ({ data }) => {
  return (
    <>
      <RowDetailLayout
        label="Provinsi"
        text={data?.desa?.kecamatan?.kabupaten?.provinsi?.nama}
      />
      <RowDetailLayout
        label="Kabupaten"
        text={data?.desa?.kecamatan?.kabupaten?.nama}
      />
      <RowDetailLayout label="Kecamatan" text={data?.desa?.kecamatan?.nama} />
      <RowDetailLayout label="Desa" text={data?.desa?.nama} />
      <RowDetailLayout label="Alamat" text={data?.street} />
    </>
  );
};

const TabInformasiKelas = ({ data }) => {
  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
      {data?.map((val, index) => {
        return (
          <Card
            key={index}
            title={`Kelas ${val?.classroom?.grade?.name}-${val?.classroom?.name}`}
            body={
              <>
                <RowDetailLayout label="Tahun Ajaran" text={val?.periode} />
                <RowDetailLayout
                  label="Guru Wali Kelas"
                  text={val?.classroom?.teacherGuide?.name}
                />
                <RowDetailLayout
                  label="Guru Bimbingan Konseling"
                  text={val?.classroom?.grade?.counseling?.name}
                />
              </>
            }
          />
        );
      })}
    </div>
  );
};

const TabKegiatan = ({ studentPublicId }) => {
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    status: 2,
  });

  // <--- useQuery --->
  const getKegiatan = useQuery(
    [`alumni-atribut-kegiatan-${studentPublicId}`, pagination],
    () =>
      StudentApi.getStudentAchievements(
        axiosPrivate,
        studentPublicId,
        paginationHandler(pagination)
      ),
    { enabled: Boolean(studentPublicId) }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getKegiatan.data?.pageable?.pageNumber - 1) *
          getKegiatan.data?.pageable?.pageSize +
        index +
        1,
      width: "50px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "70px",
      wrap: true,
    },
    {
      name: "Kegiatan",
      selector: (row) => row?.name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Partisipasi",
      selector: (row) => row?.participationName,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Jenis Kegiatan",
      selector: (row) => row?.achievementName,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Regional",
      selector: (row) => row?.regional,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Poin",
      selector: (row) => row?.point,
      width: "60px",
      wrap: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "210px",
    },
    {
      name: "Tgl. Kegiatan",
      selector: (row) =>
        row?.activityDate ? getDashDMY(row?.activityDate) : "-",
      width: "110px",
    },
  ];

  return (
    <Table
      columns={columns}
      data={getKegiatan?.data?.content}
      loading={getKegiatan?.isFetching}
      error={getKegiatan.error?.response?.data?.errorMessage[0]}
      pagination={true}
      totalRows={getKegiatan.data?.pageable?.totalElements}
      handlePageChange={handlePageChange}
      handlePerRowsChange={handlePerRowsChange}
    />
  );
};

const TabPelanggaran = ({ studentPublicId }) => {
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [pagination, setPagination] = useState({
    direction: "DESC",
    page: 1,
    size: 10,
    isActive: true,
  });

  // <--- useQuery --->
  const getPelanggaran = useQuery(
    [`alumni-atribut-pelanggaran-${studentPublicId}`, pagination],
    () =>
      StudentApi.getStudentViolations(
        axiosPrivate,
        studentPublicId,
        paginationHandler(pagination)
      ),
    { enabled: Boolean(studentPublicId) }
  );

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  // <--- Table's columns --->
  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (getPelanggaran.data?.pageable?.pageNumber - 1) *
          getPelanggaran.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "80px",
      wrap: true,
    },
    {
      name: "Nama Pelanggaran",
      selector: (row) => row?.violationName,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Tgl. Pelanggaran",
      selector: (row) =>
        row?.activityDate ? getDashDMY(row?.activityDate) : "-",
      width: "130px",
    },
  ];

  return (
    <Table
      columns={columns}
      data={getPelanggaran?.data?.content}
      loading={getPelanggaran?.isFetching}
      error={getPelanggaran.error?.response?.data?.errorMessage[0]}
      pagination={true}
      totalRows={getPelanggaran.data?.pageable?.totalElements}
      handlePageChange={handlePageChange}
      handlePerRowsChange={handlePerRowsChange}
    />
  );
};

export const DetailSection = ({ data }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = data?.data;

  // useQuery
  const getDetail = useQuery(
    ["alumni-atribut-detail", publicId],
    () => AlumniApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(data.show && data.type === "read") }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data siswa..."
        text="Data siswa gagal dimuat"
      />
    );
  } else {
    return (
      <Tabs
        data={[
          {
            title: "Informasi Umum",
            component: <TabInformasiUmum data={getDetail.data} error={data} />,
          },
          {
            title: "Alamat",
            component: <TabAlamat data={getDetail.data?.address} />,
          },
          {
            title: "Informasi Kelas",
            component: (
              <TabInformasiKelas data={getDetail?.data?.studentHasClasses} />
            ),
          },
          {
            title: "Kegiatan",
            component: <TabKegiatan studentPublicId={publicId} />,
          },
          {
            title: "Pelanggaran",
            component: <TabPelanggaran studentPublicId={publicId} />,
          },
        ]}
      />
    );
  }
};
