import { createContext, useEffect, useState } from "react";
import { LoadingPage } from "views"; 
export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, [])
    

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {
                isLoading ? <LoadingPage /> : children
            }
        </AuthContext.Provider>
    )
}