import { mappingDataOptions } from "utilities";

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class AchievementApi {
  async getAchievementLibrary(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/achievement-libraries`, { params })
    return mappingDataOptions(fetch.data.payload)
  }

  async getAchievementRegionals(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/achievement-regionals`, { params })
    return mappingDataOptions(fetch.data.payload)
  }

  async getAchievementParticipation(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/achievement-participations`, { params })
    return mappingDataOptions(fetch.data.payload)
  }
}

export default new AchievementApi()