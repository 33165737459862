// Components
import { DataStatus, Label, RowDetailLayout, Tabs } from "components";

// Third-Party Library
import { useQuery } from "react-query";

// Utilities
import { getDashDMY, mappingDataGender, useAxiosPrivate } from "utilities";

// API
import GuruApi from "../__GuruApi__";

const TabInformasiUmum = ({ data, error }) => {
  return (
    <>
      <RowDetailLayout
        label="Kategori Guru"
        text={data?.teacherCategory?.name}
      />
      <RowDetailLayout label="Nama Guru" text={data?.name} />
      <RowDetailLayout label="NIP (Nomor Induk Pegawai)" text={data?.nip} />
      <RowDetailLayout
        label="Jenis Kelamin"
        text={mappingDataGender(data?.gender)}
      />
      <RowDetailLayout
        label="Tanggal Lahir"
        text={data?.dateOfBirth ? getDashDMY(data?.dateOfBirth) : "-"}
      />
      <RowDetailLayout label="Email" text={data?.email} />
      <RowDetailLayout
        label="Status"
        text={data?.isActive ? "Aktif" : "Tidak Aktif"}
      />
      {error.error && <Label type="error" text={error.errorText} />}
    </>
  );
};

const TabAlamat = ({ data }) => {
  return (
    <>
      <RowDetailLayout
        label="Provinsi"
        text={data?.desa?.kecamatan?.kabupaten?.provinsi?.nama}
      />
      <RowDetailLayout
        label="Kabupaten"
        text={data?.desa?.kecamatan?.kabupaten?.nama}
      />
      <RowDetailLayout label="Kecamatan" text={data?.desa?.kecamatan?.nama} />
      <RowDetailLayout label="Desa" text={data?.desa?.nama} />
      <RowDetailLayout label="Alamat" text={data?.street} />
    </>
  );
};

export const DetailSection = ({ data }) => {
  const axiosPrivate = useAxiosPrivate();
  const { publicId } = data?.data;

  // useQuery
  const getDetail = useQuery(
    ["guru-atribut-detail", publicId],
    () => GuruApi.detail(axiosPrivate, publicId),
    { enabled: Boolean(data.show && data.type !== "create") }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data guru..."
        text="Data guru gagal dimuat"
      />
    );
  } else {
    return (
      <Tabs
        data={[
          {
            title: "Informasi Umum",
            component: <TabInformasiUmum data={getDetail.data} error={data} />
          },
          {
            title: "Alamat",
            component: <TabAlamat data={getDetail.data?.address} />
          },
        ]}
      />
    );
  }
};
