import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  hexToRGB,
  paginationHandler,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import * as Yup from "yup";
import KategoriBlogApi from "./__KategoriBlogApi__";
import { DeleteButon, EditButton, ListLayout, Modal } from "components";
import { Formik } from "formik";
import { DetailSection, FormSection } from "./__KategoriBlogComponents__";

export const KategoriBlog = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });

  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  const columns = [
    {
      name: "No.",
      selector: (_, index) =>
        (getKategoriBlog.data?.pageable?.pageNumber - 1) *
          getKategoriBlog.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kode",
      selector: (row) => row.code,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Warna",
      selector: (row) => (
        <label
          className={`text-sm py-1 px-3 rounded-md font-bold w-fit`}
          style={{
            color: row.colorCode,
            backgroundColor: hexToRGB(row.colorCode, 0.2),
          }}
        >
          {row.name}
        </label>
      ),
      minWidth: "200px"
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          {permissions.includes("BLOG_CAT_U") && (
            <EditButton
              icon
              noText
              onClick={() => onUpdateButtonClickHandler(data)}
              className="mr-1"
            />
          )}
          {permissions.includes("BLOG_CAT_D") && (
            <DeleteButon
              icon
              noText
              onClick={() => onDeleteButtonClickHandler(data)}
            />
          )}
        </>
      ),
    },
  ];

  const getKategoriBlog = useQuery(["kategori-blog-list", pagination], () =>
    KategoriBlogApi.getList(axiosPrivate, paginationHandler(pagination)).catch(
      () => {
        return [];
      }
    )
  );

  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickCreateButton = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  const formInitialValues = {
    publicId: modal.type !== "create" ? modal?.data?.publicId ?? "" : undefined,
    name: modal.type !== "create" ? modal?.data?.name ?? "" : "",
    code: modal.type !== "create" ? modal?.data?.code ?? "" : "",
    colorCode: modal.type !== "create" ? modal?.data?.colorCode ?? "#000000" : "#000000",
  };

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required("Nama Kategori Blog wajib diisi"),
    code: Yup.string().required("Kode Kategori Blog wajib diisi"),
    colorCode: Yup.string().required("Wajib memilih Warna Kategori Blog")
  });

  const createKategoriBlog = useMutation(
    (data) => KategoriBlogApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getKategoriBlog.refetch();
      },
    }
  );

  const updateKategoriBlog = useMutation(
    ({ data, publicId }) =>
      KategoriBlogApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        getKategoriBlog.refetch();
      },
    }
  );

  const deleteKategoriBlog = useMutation(
    (id) => KategoriBlogApi.delete(axiosPrivate, id),
    {
      onSuccess: () => {
        getKategoriBlog.refetch();
      },
    }
  );

  return (
    <>
      <ListLayout
        permissions={permissions}
        permissionCreate="BLOG_CAT_C"
        data={getKategoriBlog.data?.content}
        columns={columns}
        loading={getKategoriBlog.isFetching}
        error={getKategoriBlog.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getKategoriBlog.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        onSearchChange={searchDataHandler}
        onClickCreateButton={onClickCreateButton}
      />

      {/* <--- MODAL CREATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          createKategoriBlog
            .mutateAsync(values)
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit, resetForm } = formik;

          return (
            <Modal
              id="modal-tambah-kategori-blog"
              header="Tambah Kategori Blog"
              size="small"
              type="create"
              onHide={() => {
                resetForm();
                onHideModalHandler();
              }}
              show={Boolean(modal.show && modal.type === "create")}
              onSubmit={handleSubmit}
              isSubmitting={createKategoriBlog.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL UPDATE ---> */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          updateKategoriBlog
            .mutateAsync({
              data: values,
              publicId: values.publicId,
            })
            .then((res) => {
              resetForm();
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        {(formik) => {
          const { handleSubmit } = formik;

          return (
            <Modal
              id="modal-ubah-kategori-blog"
              header="Ubah Kategori Blog"
              size="small"
              type="update"
              onHide={onHideModalHandler}
              show={Boolean(modal.show && modal.type === "update")}
              onSubmit={handleSubmit}
              isSubmitting={updateKategoriBlog.isLoading}
            >
              <FormSection modal={modal} />
            </Modal>
          );
        }}
      </Formik>

      {/* <--- MODAL DELETE ---> */}
      <Modal
        id="modal-hapus-kategori-blog"
        header="Hapus Kategori Blog"
        size="small"
        type="delete"
        onHide={onHideModalHandler}
        show={Boolean(modal.show && modal.type === "delete")}
        isSubmitting={deleteKategoriBlog.isLoading}
        onSubmit={() => {
          deleteKategoriBlog
            .mutateAsync(modal.data.publicId)
            .then((res) => {
              successFetching(res);
              onHideModalHandler();
            })
            .catch((err) => {
              const errorMessage = errorFetching(err);
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage,
              });
            });
        }}
      >
        <DetailSection data={modal} />
      </Modal>
    </>
  );
};
