import { AntdTable, DefaultButton, PrintButton, Select } from "components";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { errorFetching, useAxiosPrivate } from "utilities";
import { ClassroomsApi, DropdownApi, GradeApi, SchoolsApi } from "api";
import PresensiBulananSiswaApi from "./__PresensiBulananSiswaApi__";
import ReactToPrint from "react-to-print";
import {
  CetakPresensiBulananSiswa,
  ConvertFlagPresensiBulanan,
  ExportPresensiBulananSiswa,
} from "./__PresensiBulananSiswaComps__";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export const PresensiBulananSiswa = () => {
  const axiosPrivate = useAxiosPrivate();
  const [filter, setFilter] = useState({
    gradePublicId: undefined,
    grade: undefined,
    classroomPublicId: undefined,
    classroom: undefined,
    periodePublicId: undefined,
    periode: undefined,
    periodeData: undefined,
    yearMonth: undefined,
    monthYear: undefined,
  });
  const [cetakData, setCetakData] = useState(undefined);
  const [exportData, setExportData] = useState(undefined);

  const getTahunAjaran = useQuery(
    ["laporan-presensi-bulanan-siswa", "dropdown", "tahun-ajaran"],
    () => SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" })
  );

  const getBulanTahunAjaran = useQuery(
    ["laporan-presensi-bulanan-siswa", "dropdown", "bulan-tahun-ajaran"],
    () =>
      DropdownApi.getYearMonthPeriode(
        filter.periodeData.startDate,
        filter.periodeData.endDate
      ),
    {
      enabled: Boolean(filter.periodeData?.startDate),
    }
  );

  const getTingkatanKelas = useQuery(
    ["laporan-presensi-bulanan-siswa", "dropdown", "tingkatan-kelas"],
    () =>
      GradeApi.getGrade(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        direction: "ASC",
      })
  );

  const getReport = useQuery(
    [
      "laporan-presensi-bulanan-siswa",
      "report",
      filter.classroomPublicId,
      filter.periodePublicId,
      filter.yearMonth,
    ],
    () =>
      PresensiBulananSiswaApi.getReport(
        axiosPrivate,
        filter.classroomPublicId,
        filter.periodePublicId,
        filter.yearMonth
      ),
    {
      enabled: Boolean(
        filter.classroomPublicId && filter.periodePublicId && filter.yearMonth
      ),
      onSuccess: (res) => {
        setCetakData(res);
        setExportData(res);
      },
      onError: (err) => {
        errorFetching(err);
        setCetakData(undefined);
        setExportData(undefined);
      },
    }
  );

  const getKelas = useQuery(
    [
      "laporan-presensi-bulanan-siswa",
      "dropdown",
      "kelas",
      filter?.gradePublicId,
    ],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        gradePublicId: filter.gradePublicId,
      }),
    { enabled: Boolean(filter?.gradePublicId) }
  );

  const columns = [
    {
      title: "No",
      render: (_, __, index) => `${index + 1}.`,
      width: 60,
      fixed: "left",
      align: "center",
      rowSpan: 2,
    },
    {
      title: "NIS",
      render: (row, _, __) => row?.studentNis,
      width: 100,
      fixed: "left",
      align: "center",
      rowSpan: 2,
    },
    {
      title: "Nama Siswa",
      render: (row, _, __) => (
        <div className="text-left">{row?.studentName}</div>
      ),
      width: 400,
      fixed: "left",
      align: "center",
      rowSpan: 2,
    },
  ];

  const dateColumns = () => {
    const tampArray = [];
    getReport?.data?.students?.[0].date.forEach((data) =>
      tampArray.push({
        title: (
          <div
            className={
              (data.isHoliday || data.isWeekend) && "font-bold text-red-500"
            }
          >
            {data.date.split("-")[2]}
          </div>
        ),
        width: 60,
        align: "center",
        className: `${data.isHoliday || data.isWeekend ? "bg-red-200" : ""}`,
        render: (row, _, __) =>
          row?.date
            ?.filter((val) => val.date.toString() === data.date.toString())
            ?.map((val) =>
              val.attendance ? (
                <ConvertFlagPresensiBulanan flag={val.attendance.flag} />
              ) : val.isHoliday || val.isWeekend ? (
                "L"
              ) : (
                "-"
              )
            ),
        rowSpan: 2,
      })
    );
    return tampArray;
  };

  const totalColumns = [
    {
      title: "Total Hari",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalEffectiveDays,
    },
    {
      title: "Masuk",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalMasuk,
    },
    {
      title: "Terlambat",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalTerlambat,
    },
    {
      title: "Izin",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalIzin,
    },
    {
      title: "Sakit",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalIzinSakit,
    },
    {
      title: "Tidak Presensi",
      width: 100,
      align: "center",
      className: "font-semibold",
      render: (row, _, __) => row?.totalAlpha,
    },
  ];

  const mergeColumns = columns.concat(dateColumns()).concat(totalColumns);

  const KeteranganComponent = () => {
    return (
      <div className="mt-5 w-full text-sm">
        <div className="underline font-semibold">Keterangan:</div>
        <div className="flex flex-row space-x-10">
          <div className="flex">
            <ConvertFlagPresensiBulanan />
            <div className="mx-2">:</div> Tidak Presensi
          </div>
          <div className="flex">
            <ConvertFlagPresensiBulanan flag="MASUK" />
            <div className="mx-2">:</div> Masuk
          </div>
          <div className="flex">
            <ConvertFlagPresensiBulanan flag="TERLAMBAT" />
            <div className="mx-2">:</div> Terlambat
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiBulanan flag="IZIN_SAKIT" />
            </div>
            <div className="mx-2">:</div> Sakit
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiBulanan flag="IZIN" />
            </div>
            <div className="mx-2">:</div> Izin
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiBulanan flag="MASUK_KELUAR" />
            </div>
            <div className="mx-2">:</div> Izin Meninggalkan Sekolah
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiBulanan flag="IZIN_TERLAMBAT" />
            </div>
            <div className="mx-2">:</div> Izin Terlambat
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="sm:flex sm:flex-row sm:space-x-3 sm:space-y-0 space-y-3 mb-5">
        <div className="sm:flex-grow">
          <Select
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            defaultValue={getTahunAjaran?.data?.find(
              (item) => item.value === filter?.periodePublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                periodePublicId: val.value,
                periode: val.label,
                periodeData: val,
              })
            }
            options={getTahunAjaran?.data ?? []}
            errorFetch={getTahunAjaran.isError}
            errorFetchText={
              getTahunAjaran?.error?.response?.data?.errorMessage[0]
            }
            loading={getTahunAjaran.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Bulan Tahun"
            placeholder={`${
              Boolean(filter.periodeData)
                ? Boolean(filter.periodeData?.startDate)
                  ? "Pilih Bulan Tahun"
                  : `Bulan Tahun di ${filter.periode} Tidak Ditemukan`
                : "Pilih Bulan Tahun"
            }`}
            defaultValue={getBulanTahunAjaran?.data?.find(
              (item) => item.value === filter?.yearMonth
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                yearMonth: val.value,
                monthYear: val.label,
              })
            }
            options={getBulanTahunAjaran?.data ?? []}
            errorFetch={getBulanTahunAjaran.isError}
            errorFetchText={
              getBulanTahunAjaran?.error?.response?.data?.errorMessage[0]
            }
            loading={getBulanTahunAjaran.isFetching}
            disable={!Boolean(filter.periodeData?.startDate)}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Tingkatan Kelas"
            placeholder="Pilih Tingkatan Kelas"
            defaultValue={getTingkatanKelas?.data?.find(
              (item) => item.value === filter?.gradePublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                gradePublicId: val.value,
                grade: val.label,
              })
            }
            options={getTingkatanKelas?.data ?? []}
            errorFetch={getTingkatanKelas.isError}
            errorFetchText={
              getTingkatanKelas?.error?.response?.data?.errorMessage[0]
            }
            loading={getTingkatanKelas.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Kelas"
            placeholder="Pilih Kelas"
            defaultValue={getKelas?.data?.find(
              (item) => item.value === filter?.classroomPublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                classroomPublicId: val.value,
                classroom: val.label,
              })
            }
            options={getKelas?.data ?? []}
            errorFetch={getKelas.isError}
            errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
            loading={getKelas.isFetching}
            disable={!Boolean(filter.gradePublicId)}
          />
        </div>
        <div className="grid content-end">
          <DefaultButton
            text="Reset Pencarian"
            onClick={() =>
              setFilter({
                ...filter,
                classroomPublicId: undefined,
                gradePublicId: undefined,
                periodeData: undefined,
                periodePublicId: undefined,
                yearMonth: undefined,
                classroom: undefined,
                grade: undefined,
                periode: undefined,
                monthYear: undefined,
              })
            }
          />
        </div>
        {cetakData && exportData ? (
          <>
            <div className="grid content-end">
              <ComponentToPrintWrapper data={cetakData} filter={filter} />
            </div>
            <div className="grid content-end">
              <ReactHTMLTableToExcel
                id="btn-export-table-presensi-bulanan-siswa"
                className="download-table-xls-button btn-success justify-center flex flex-row items-center p-2"
                table="export-table-presensi-bulanan-siswa"
                filename={`Laporan Presensi Bulan ${filter.monthYear}_Kelas ${filter.grade}-${filter.classroom}`}
                sheet={`Bulan ${filter.monthYear}_Kelas ${filter.grade}-${filter.classroom}`}
                buttonText="Export to Excel"
              />
            </div>
          </>
        ) : null}
      </div>
      <AntdTable
        data={getReport?.data?.students ?? []}
        columns={mergeColumns}
        pagination={false}
        size="small"
        scrollY={500}
        loading={getReport.isFetching}
      />
      <KeteranganComponent />

      {exportData && (
        <div style={{ display: "none" }}>
          <ExportPresensiBulananSiswa data={exportData} />
        </div>
      )}
    </>
  );
};

const ComponentToPrintWrapper = ({ data, filter }) => {
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => <PrintButton icon text="Cetak" className="flex-grow" />}
        content={() => componentRef.current}
        removeAfterPrint={true}
        documentTitle={`Laporan Presensi Bulan ${filter.monthYear}_Kelas ${filter.grade}-${filter.classroom}`}
      />

      <div style={{ display: "none" }}>
        <CetakPresensiBulananSiswa
          data={data}
          filter={filter}
          ref={componentRef}
        />
      </div>
    </>
  );
};
