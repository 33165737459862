import { DropdownApi } from 'api';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashDMY, getDashDMYTime, paginationHandler, statusConvertPrestasi, useAxiosPrivate } from 'utilities'
import KegiatanKesiswaanApi from '../__KegiatanKesiswaanApi__';
import { Card, DataStatus, ListLayout, RowDetailLayout, Select, TextArea } from 'components';
import { useMediaQuery } from 'react-responsive';
import { MAX_MOBILE_WIDTH } from 'helper/MaxMobileWidthHelper';

const InformasiKegiatanSection = ({ data, modal }) => {
  const styleValue = "text-sm text-gray-700 pl-3 w-full"
  const styleLabel = "w-40"
  const isMobile = useMediaQuery({ query: MAX_MOBILE_WIDTH })

  if(isMobile){
    return (
      <>
        <RowDetailLayout 
          label='Nama Siswa'
          text={modal?.data?.studentName}
        />
        <RowDetailLayout 
          label='NIS'
          text={modal?.data?.studentNis}
        />
        <RowDetailLayout 
          label='Nama Kegiatan'
          text={data?.name}
        />
        <RowDetailLayout 
          label='Partisipasi'
          text={data?.achievement?.achievementParticipation?.name}
        />
        <RowDetailLayout 
          label='Jenis Kegiatan'
          text={data?.achievement?.achievementLibrary?.name}
        />
        <RowDetailLayout 
          label='Regional'
          text={data?.achievement?.achievementRegional?.name}
        />
        <RowDetailLayout 
          label='Tanggal Kegiatan'
          text={data?.activityDate ? getDashDMY(data?.activityDate) : '-'}
        />
        <RowDetailLayout 
          label='Poin'
          text={data?.achievement?.point}
        />
        <RowDetailLayout 
          label='Keterangan'
          text={data?.description}
        />
      </>
    )
  } else{
    return (
      <div className="space-y-1">
        <div className="grid grid-cols-2 space-x-5">
          <div className="space-y-1">
            {/* LEFT SECTION */}
            <div className="flex flex-row">
              <small className={styleLabel}>Nama Siswa</small>
              <small>:</small>
              <strong className={styleValue}>{modal?.data?.studentName}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>NIS</small>
              <small>:</small>
              <strong className={styleValue}>{modal?.data?.studentNis}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Nama Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Partisipasi</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementParticipation?.name}</strong>
            </div>
          </div>
          <div className="space-y-1">
            {/* RIGHT SECTION */}
            <div className="flex flex-row">
              <small className={styleLabel}>Jenis Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementLibrary?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Regional</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementRegional?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Tanggal Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.activityDate ? getDashDMY(data?.activityDate) : '-'}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Poin</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.point}</strong>
            </div>
          </div>
        </div>
        <RowDetailLayout 
          label='Keterangan'
          text={data?.description}
        />
      </div>
    )
  }
}

const LogKegiatanSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate()
  const { studentPublicId, publicId } = modal?.data

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 3,
    isActive: true,
    direction: 'DESC'
  })

  // <--- useQuery --->
  const getLogs = useQuery(
    ['logs-prestasi-siswa-atribut-guru', pagination],
    () => KegiatanKesiswaanApi.getAchievementLogsPrestasiSiswa(axiosPrivate, studentPublicId, publicId, paginationHandler(pagination)),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  // <--- Columns --->
  const columns = [
    {
      name: 'No.',
      selector: (_, index) => index + 1,
      width: "70px" 
    },
    {
      name: 'Keterangan',
      selector: row => row?.description,
      wrap: true,
      minWidth: '250px'
    },
    {
      name: 'Status',
      selector: row => statusConvertPrestasi(row?.status),
      width: '125px'
    },
    {
      name: 'Tanggal Simpan',
      selector: row => getDashDMYTime(row?.createdAt),
      width: '160px',
      wrap: true
    }
  ]

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  return (
    // <div className="mt-4">
    //   <strong className="text-lg font-semibold">Log Kegiatan</strong>
      <ListLayout 
        data={getLogs.data?.content}
        columns={columns}
        loading={Boolean(getLogs.isFetching)}
        error={getLogs.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getLogs.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        paginationRowsPerPageOptions={[3,5]}
        customLeftTopSection={() => <></>}
      />
    // </div>
  )
}

export const FormSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate()
  const { values, setValues, errors, touched, handleChange, resetForm } = useFormikContext()
  const { studentPublicId, publicId } = modal?.data

  useEffect(() => {
    if(modal.show && modal.type === 'update'){
      resetForm()
    }
  }, [modal.show])

  // useQuery
  const getDetail = useQuery(
    ['detail-prestasi-siswa-atribut-guru', studentPublicId, publicId],
    () => KegiatanKesiswaanApi.getDetailPrestasiSiswa(axiosPrivate, studentPublicId, publicId),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  const getStatusPrestasi = useQuery(
    ['status-prestasi-atribut-guru', 'dropdown'],
    () => DropdownApi.getStatusPrestasi(),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  if(getDetail.isFetching || getDetail.isError){
    return (
      <DataStatus 
        loading={getDetail.isFetching}
        loadingText='Memuat data kegiatan siswa...'
        text='Data kegiatan siswa gagal dimuat'
      />
    )
  } else{
    return (
      <>
        <Card 
          title="Informasi Kegiatan"
          body={<InformasiKegiatanSection data={getDetail?.data} modal={modal} />}
        />
        <Card 
          title="Log Kegiatan"
          wrapperClassname="mt-3 mb-3"
          body={<LogKegiatanSection modal={modal} />}
        />
        <Select 
          label='Status'
          placeholder='Pilih Status'
          name='status'
          defaultValue={getStatusPrestasi?.data?.find(item => item.value === values?.status)}
          onChange={val => {
            if(values?.status === '' && val.value === 2){
              setValues({ ...values, status: val.value, description: 'APPROVED' })
            } else{
              if(values?.description === '' && val.value === 2){
                setValues({ ...values, status: val.value, description: 'APPROVED' })
              } else{
                if(values?.status === 2 && values?.description === 'APPROVED'){
                  setValues({ ...values, status: val.value, description: '' })
                } else{
                  setValues({ ...values, status: val.value})
                }
              }
            }
          }}
          options={getStatusPrestasi?.data ?? []}
          error={Boolean(errors?.status && touched?.status)}
          errorText={Boolean(errors?.status && touched?.status) && errors?.status}
          errorFetch={getStatusPrestasi.isError}
          errorFetchText={getStatusPrestasi?.error?.response?.data?.errorMessage[0]}
          loading={getStatusPrestasi.isFetching}
        />
        {(values?.status === 3 || values?.status === 4) && 
          <div className="mt-3">
            <TextArea 
              label='Keterangan'
              name='description'
              value={values.description}
              rows={3}
              onChange={handleChange}
              error={Boolean(errors.description && touched.description)}
              errorText={Boolean(errors.description && touched.description) && errors.description}
            />
          </div>
        }
      </>
    )
  }
}
