import { useFormikContext } from "formik";
import React from "react";
import { useAxiosPrivate } from "utilities";
import { useQuery } from "react-query";
import { ClassroomsApi, DropdownApi, GradeApi } from "api";
import { Input, Label, Select, TextArea } from "components";

export const FormSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, handleChange, errors, touched } =
    useFormikContext();

  const getTingkatanKelas = useQuery(
    ["dashboard-izin-atribut-tingkatan-kelas"],
    () =>
      GradeApi.getGrade(axiosPrivate, {
        isActive: true,
        direction: "ASC",
        sortBy: "name",
      }),
    {
      enabled: Boolean(modal.show),
    }
  );

  const getKelas = useQuery(
    ["dashboard-izin-atribut-kelas", values?.gradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values?.gradePublicId,
        direction: "ASC",
        sortBy: "name",
      }),
    {
      enabled: Boolean(modal.show && values?.gradePublicId),
    }
  );

  const getSiswa = useQuery(
    ["dashboard-izin-atribut-siswa", values?.classroomPublicId],
    () =>
      ClassroomsApi.getClassroomsStudents(
        axiosPrivate,
        { isActive: true, sortBy: "student_name", direction: "ASC" },
        values?.classroomPublicId
      ),
    {
      enabled: Boolean(modal.show && values?.classroomPublicId),
    }
  );

  const getAbsencePermitFlag = useQuery(
    ["absence-permit-flag", "dropdown"],
    () => DropdownApi.getAbsencePermitFlag()
  );

  return (
    <>
      <Select
        label="Tingkatan Kelas"
        placeholder="Pilih Tingkatan Kelas"
        name="gradePublicId"
        defaultValue={getTingkatanKelas?.data?.find(
          (item) => item.value === values.gradePublicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            gradePublicId: val.value,
            classroomPublicId: "",
            studentPublicId: "",
          })
        }
        options={getTingkatanKelas?.data ?? []}
        error={Boolean(errors.gradePublicId && touched.gradePublicId)}
        errorText={
          Boolean(errors.gradePublicId && touched.gradePublicId) &&
          errors.gradePublicId
        }
        errorFetch={getTingkatanKelas.isError}
        errorFetchText={
          getTingkatanKelas?.error?.response?.data?.errorMessage[0]
        }
        loading={getTingkatanKelas.isFetching}
        disable={Boolean(modal.type === "update")}
      />
      <Select
        label="Kelas"
        placeholder="Pilih Kelas"
        name="classroomPublicId"
        defaultValue={getKelas?.data?.find(
          (item) => item.value === values.classroomPublicId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            classroomPublicId: val.value,
            studentPublicId: "",
          })
        }
        options={getKelas?.data ?? []}
        error={Boolean(errors.classroomPublicId && touched.classroomPublicId)}
        errorText={
          Boolean(errors.classroomPublicId && touched.classroomPublicId) &&
          errors.classroomPublicId
        }
        errorFetch={getKelas.isError}
        errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
        loading={getKelas.isFetching}
        disable={
          Boolean(values.gradePublicId === "") ||
          Boolean(modal.type === "update")
        }
      />
      <Select
        label="Siswa"
        placeholder="Pilih Siswa"
        name="studentPublicId"
        defaultValue={getSiswa?.data?.find(
          (item) => item.value === values.studentPublicId
        )}
        onChange={(val) => setValues({ ...values, studentPublicId: val.value })}
        options={getSiswa?.data ?? []}
        error={Boolean(errors.studentPublicId && touched.studentPublicId)}
        errorText={
          Boolean(errors.studentPublicId && touched.studentPublicId) &&
          errors.studentPublicId
        }
        errorFetch={getSiswa.isError}
        errorFetchText={getSiswa?.error?.response?.data?.errorMessage[0]}
        loading={getSiswa.isFetching}
        disable={
          Boolean(values.classroomPublicId === "") ||
          Boolean(modal.type === "update")
        }
      />
      <Input
        label="Tanggal Awal Izin"
        name="startDate"
        type="date"
        value={values?.startDate}
        onChange={handleChange}
        error={Boolean(errors.startDate && touched.startDate)}
        errorText={
          Boolean(errors.startDate && touched.startDate) && errors.startDate
        }
      />
      <Input
        label="Tanggal Akhir Izin"
        name="endDate"
        type="date"
        value={values?.endDate}
        onChange={handleChange}
        error={Boolean(errors.endDate && touched.endDate)}
        errorText={Boolean(errors.endDate && touched.endDate) && errors.endDate}
      />
      <Select
        label="Status"
        placeholder="Pilih Status"
        name="flag"
        defaultValue={getAbsencePermitFlag?.data?.find(
          (item) => item.value === values.flag
        )}
        onChange={(val) =>
          setValues({
            ...values,
            flag: val.value,
          })
        }
        options={getAbsencePermitFlag?.data ?? []}
        errorFetch={getAbsencePermitFlag.isError}
        errorFetchText={
          getAbsencePermitFlag?.error?.response?.data?.errorMessage[0]
        }
        loading={getAbsencePermitFlag.isFetching}
      />
      <TextArea
        label="Keterangan Izin"
        name="description"
        value={values.description}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.description && touched.description)}
        errorText={
          Boolean(errors.description && touched.description) &&
          errors.description
        }
      />
      {modal.error && <Label type="error" text={modal.errorText} />}
    </>
  );
};
