import { Accordion as FAccordion } from "flowbite-react";
import parse from "html-react-parser";

export const Accordion = ({ data = [] }) => {
  /**
   *  Struktur object data:
   *  [
   *    {
   *      title: 'Title',
   *      body: 'Body' or <Body />
   *    }
   *  ]
   */
  return (
    <FAccordion alwaysOpen>
      {data?.map((value, index) => (
        <FAccordion.Panel key={index}>
          <FAccordion.Title>{value.title}</FAccordion.Title>
          <FAccordion.Content>{parse(value.body)}</FAccordion.Content>
        </FAccordion.Panel>
      ))}
    </FAccordion>
  );
};
