import { toastTrigger } from "components"

export const errorFetching = err => {
    const errorMessage = err.code === 'ERR_NETWORK' ? 'Anda terputus dari jaringan.' : err?.response?.data?.errorMessage[0]
    
    toastTrigger({
        variant: 'error',
        message: errorMessage
    })

    return errorMessage
}
