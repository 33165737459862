const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class DispensasiSiswaApi {
  async getListPageable(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(
      `${url}/v2/dispensasi-presensi/pageable`,
      {
        params,
      }
    );
    return fetch.data.payload;
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v2/dispensasi-presensi`, data);
  }

  update(axiosPrivate, data, publicId) {
    return axiosPrivate.put(`${url}/v2/dispensasi-presensi/${publicId}`, data);
  }

  delete(axiosPrivate, publicId) {
    return axiosPrivate.delete(`${url}/v2/dispensasi-presensi/${publicId}`);
  }
}

export default new DispensasiSiswaApi();
