const url = window._env_.REACT_APP_API_AUTH_URL

class PenggunaApi {
    async getList(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/users/pageable`, { params })
        return fetch.data.payload
    }

    async detail(axiosPrivate, id) {
        const fetch = await axiosPrivate.get(`${url}/v1/users/${id}`)
        return fetch.data.payload
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/users/${id}`, data)
    }

    resetPassword(axiosPrivate, data, id) {
        return axiosPrivate.patch(`${url}/v1/users/${id}/password`, data)
    }
}

export default new PenggunaApi()