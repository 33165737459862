import { Label, RowDetailLayout } from 'components'

export const DetailSection = ({ data }) => {
    return (
        <>
            <RowDetailLayout
                label='Nama'
                text={data.data?.name}
            />
            <RowDetailLayout
                label='Kode'
                text={data.data?.code}
            />
            <RowDetailLayout
                label='Tingkatan Kelas'
                text={data.data?.grade}
            />
            <RowDetailLayout
                label='Guru BK'
                text={data.data?.counseling?.name}
            />
            <RowDetailLayout
                label='Keterangan'
                text={data.data?.description}
            />
            <RowDetailLayout
                label='Status'
                text={data.data.isActive ? 'Aktif' : 'Tidak Aktif'}
            />
            {data.error && 
                <Label 
                    type='error'
                    text={data.errorText}
                />
            }
        </>
    )
}
