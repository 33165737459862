import "./styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "./utilities";
import { MainRoutes } from "./routes";
import { Toast } from "components";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <React.StrictMode>
      <BrowserRouter basename="/">
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <MainRoutes />
            <Toast />
          </AuthContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
