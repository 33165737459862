import React from "react";
import { PengaturanMenus } from "../PengaturanMenus";
import { errorFetching, successFetching, useAxiosPrivate } from "utilities";
import { useMutation, useQuery } from "react-query";
import BatasJamMasukApi from "./__BatasJamMasukApi__";
import { DataStatus, SaveButton, Sidebar } from "components";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__BatasJamMasukUtilities__";
import { FormSection } from "./__BatasJamMasukComponents__";

export const BatasJamMasuk = () => {
  const axiosPrivate = useAxiosPrivate();
  const menus = PengaturanMenus;

  // useQuery
  const getCurrentAttendanceTime = useQuery(["current-attendance-time"], () =>
    BatasJamMasukApi.getCurrentAttendanceTime(axiosPrivate)
  );

  const create = useMutation(
    (data) => BatasJamMasukApi.create(axiosPrivate, data),
    {
      onSuccess: () => {
        getCurrentAttendanceTime.refetch();
      },
    }
  );

  if (
    getCurrentAttendanceTime.isFetching ||
    getCurrentAttendanceTime.isLoading
  ) {
    return (
      <Sidebar name="Batas Jam Masuk" menus={menus}>
        <DataStatus
          loading={getCurrentAttendanceTime.isFetching}
          loadingText="Memuat batas jam masuk..."
          text="Data batas jam masuk gagal dimuat"
        />
      </Sidebar>
    );
  } else {
    return (
      <Sidebar name="Batas Jam Masuk" menus={menus}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues(getCurrentAttendanceTime.data)}
          validationSchema={formValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            create
              .mutateAsync(formSubmitValueMapper(values))
              .then((res) => {
                resetForm();
                successFetching(res);
              })
              .catch((err) => {
                errorFetching(err);
              });
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik;

            return (
              <>
                <FormSection />
                <div>
                  <SaveButton
                    icon
                    type="submit"
                    onClick={handleSubmit}
                    className="mt-3 w-full sm:w-auto"
                    disabled={create.isLoading}
                    loading={create.isLoading}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </Sidebar>
    );
  }
};
