// <--- React --->
import { useState } from 'react'

// <--- Components --->
import { 
    DeleteButon, EditButton, LabelDanger, LabelSuccess, ListLayout, Modal 
} from 'components';
import { DetailSection, FormSection } from './__PelanggaranComponents__';


// <--- API --->
import PelanggaranApi from './__PelanggaranApi__'

// <--- Utilities --->
import { errorFetching, paginationHandler, successFetching, useAuth, useAxiosPrivate } from 'utilities'

// <--- Third-Party Library --->
import { useMutation, useQuery } from 'react-query'
import { Formik } from 'formik'
import * as Yup from 'yup'

export const Pelanggaran = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const permissions = auth.permissions;

    // <--- States --->
	const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        isActive: true
	})
    const [isActive, setIsActive] = useState('true')
    const [modal, setModal] = useState({
		show: false,
		type: 'create',
        errorText: '',
        error: false,
		data: {}
	})

    // <--- Table's columns --->
    const columns = [
        {
            name: 'No.',
            selector: (row, index) => ((getPelanggaran.data?.pageable?.pageNumber - 1) * getPelanggaran.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'Nama Pelanggaran',
            selector: row => row.name,
            wrap: true,
            minWidth: '300px'
        },
        {
            name: 'Poin',
            selector: row => row.point,
            width: "80px",
        },
        {
            name: 'Keterangan',
            selector: row => row.description === '' ? '-' : row.description,
            wrap: true,
            minWidth: '300px'
        },
        {
            name: 'Status',
            cell: data => data?.isActive ? <LabelSuccess text='Aktif' /> : <LabelDanger text='Tidak Aktif' />,
            wrap: true,
            width: "120px" 
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('ACHIEVEMENT_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('ACHIEVEMENT_D') && <DeleteButon icon noText onClick={() => onDeleteButtonClickHandler(data)} />}
            </>,
        },
    ];

    // <--- useQuery --->
    const getPelanggaran = useQuery(
		['pelanggaran-list', pagination],
		() => PelanggaranApi.getList(axiosPrivate, paginationHandler(pagination)).catch(() => { return [] })
	)
    
    // <--- Functions --->
	const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }

    const activeDataHandler = (e) => {
        if(e.target.name !== 'all'){
            setPagination({
                ...pagination,
                isActive: e.target.name
            })
            setIsActive(e.target.name)
        } else{
            setPagination({
                ...pagination,
                isActive: undefined
            })
            setIsActive('none')
        }
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onDeleteButtonClickHandler = data => setModal({ show: true, type: 'delete', errorText: '', error: false, data: data })

    // <--- Form --->
    const formInitialValues = {
		publicId: modal.type !== 'create' ? modal?.data?.publicId ?? '' : undefined,
		name: modal.type !== 'create' ? modal?.data?.name ?? '' : '',
        point: modal.type !== 'create' ? modal?.data?.point ?? 0 : 0,
		description: modal.type !== 'create' ? modal?.data?.description ?? '' : '',
        isActive: modal.type !== 'create' ? modal?.data?.isActive ?? true : true
	}

    const formValidationSchema = Yup.object().shape({
		name: Yup.string()
			.required('Nama Pelanggaran wajib diisi'),
        point: Yup.number()
            .required('Poin Pelanggaran wajib diisi')
	})

    // <--- useMutation --->
    const createPelanggaran = useMutation((data) => PelanggaranApi.create(axiosPrivate, data), {
		onSuccess: () => {
			getPelanggaran.refetch()
		}
	})

    const updatePelanggaran = useMutation(({data, publicId}) => PelanggaranApi.update(axiosPrivate, data, publicId), {
        onSuccess: () => {
			getPelanggaran.refetch()
		}
    })

    const deletePelanggaran = useMutation(id => PelanggaranApi.delete(axiosPrivate, id), {
		onSuccess: () => {
			getPelanggaran.refetch()
		}
	})
	
    return (
        <>
            {/* <--- Search input, Create Button, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                permissionCreate='ACHIEVEMENT_C'
                data={getPelanggaran.data?.content}
                columns={columns}
                loading={getPelanggaran.isFetching}
                error={getPelanggaran.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getPelanggaran.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
                activeStatusFilter
                activeOnChange={activeDataHandler}
                activeValues={isActive}
            />

            {/* <--- MODAL CREATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    createPelanggaran.mutateAsync(values).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit, resetForm } = formik

					return (
                        <Modal
                            id='modal-tambah-pelanggaran'
                            header='Tambah Pelanggaran'
                            size='small'
                            type='create'
                            onHide={() => {
                                resetForm()
                                onHideModalHandler()
                            }}
                            show={Boolean(modal.show && modal.type === 'create')}
                            onSubmit={handleSubmit}
                            isSubmitting={createPelanggaran.isLoading}
                        >
                            <FormSection modal={modal} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL UPDATE ---> */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) => {
                    updatePelanggaran.mutateAsync({
                        data: values,
                        publicId: values.publicId
                    }).then((res) => {
                        resetForm()
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                {(formik) => {
					const { handleSubmit } = formik

					return (
                        <Modal
                            id='modal-ubah-pelanggaran'
                            header='Ubah Pelanggaran'
                            size='small'
                            type='update'
                            onHide={onHideModalHandler}
                            show={Boolean(modal.show && modal.type === 'update')}
                            onSubmit={handleSubmit}
                            isSubmitting={updatePelanggaran.isLoading}
                        >
                            <FormSection modal={modal} />
                        </Modal>
					)
				}}
            </Formik>

            {/* <--- MODAL DELETE ---> */}
            <Modal
                id='modal-hapus-pelanggaran'
                header='Hapus Pelanggaran'
                size='small'
                type='delete'
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === 'delete')}
                isSubmitting={deletePelanggaran.isLoading}
                onSubmit={() => {
                    deletePelanggaran.mutateAsync(modal.data.publicId).then((res) => {
                        successFetching(res)
                        onHideModalHandler()
                    }).catch(err => {
                        const errorMessage = errorFetching(err)
                        setModal({
                            ...modal,
                            error: true,
                            errorText: errorMessage
                        })
                    })
                }}
            >
                <DetailSection data={modal} />
            </Modal>
        </>
    )
}