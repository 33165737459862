import { ClassroomsApi, DropdownApi, GradeApi } from "api";
import {
  Input,
  Label,
  RadioButton,
  RadioButtonGroup,
  RowDetailLayout,
  Select,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useAxiosPrivate } from "utilities";

export const FormSection = ({ modal, tahunAjaran }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange, resetForm } =
    useFormikContext();

  useEffect(() => {
    if (modal.show && modal.type === "create") {
      resetForm();
    }
  }, [modal.show]);

  const getTingkatanKelas = useQuery(
    ["dispensasi-siswa-atribut-tingkatan-kelas"],
    () =>
      GradeApi.getGrade(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        direction: "ASC",
      }),
    {
      enabled: Boolean(modal.show && modal.type === "create"),
    }
  );

  const getKelas = useQuery(
    ["dispensasi-siswa-atribut-kelas", values?.tingkatanKelasPublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        gradePublicId: values?.tingkatanKelasPublicId,
        sortBy: "name",
        direction: "ASC",
      }),
    {
      enabled: Boolean(
        modal.show && modal.type === "create" && values?.tingkatanKelasPublicId
      ),
    }
  );

  const getSiswa = useQuery(
    ["dispensasi-siswa-atribut-siswa", values?.kelasPublicId],
    () =>
      ClassroomsApi.getClassroomsStudents(
        axiosPrivate,
        { isActive: true, sortBy: "student_name", direction: "ASC" },
        values?.kelasPublicId
      ),
    {
      enabled: Boolean(
        modal.show && modal.type === "create" && values?.kelasPublicId
      ),
    }
  );

  const getDays = useQuery(
    ["dispensasi-siswa-atribut-hari"],
    () => DropdownApi.getDays(),
    {
      enabled: Boolean(
        modal.show && (modal.type === "create" || modal.type === "update")
      ),
    }
  );

  if (modal.type === "create") {
    return (
      <div className="space-y-3">
        <Input label="Tahun Ajaran" value={tahunAjaran} disabled />
        <RadioButtonGroup>
          <div>
            <small>Tingkatan Kelas</small>
          </div>
          {getTingkatanKelas?.data?.map((val, index) => (
            <RadioButton
              key={index}
              checked={Boolean(values.tingkatanKelasPublicId === val.value)}
              label={val.label}
              name={val.value}
              onChange={(value) => {
                setValues({
                  ...values,
                  tingkatanKelasPublicId: value.target.name,
                  kelasPublicId: "",
                  siswaPublicId: "",
                });
              }}
            />
          ))}
        </RadioButtonGroup>
        <Select
          label="Kelas"
          placeholder="Pilih Kelas"
          name="kelasPublicId"
          defaultValue={getKelas?.data?.find(
            (item) => item.value === values.kelasPublicId
          )}
          onChange={(val) => setValues({ ...values, kelasPublicId: val.value })}
          options={getKelas?.data ?? []}
          error={Boolean(errors.kelasPublicId && touched.kelasPublicId)}
          errorText={
            Boolean(errors.kelasPublicId && touched.kelasPublicId) &&
            errors.kelasPublicId
          }
          errorFetch={getKelas.isError}
          errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
          loading={getKelas.isFetching}
          disable={Boolean(values.tingkatanKelasPublicId === "")}
        />
        <Select
          label="Siswa"
          placeholder="Pilih Siswa"
          name="siswaPublicId"
          defaultValue={getSiswa?.data?.find(
            (item) => item.value === values.siswaPublicId
          )}
          onChange={(val) => setValues({ ...values, siswaPublicId: val.value })}
          options={getSiswa?.data ?? []}
          error={Boolean(errors.siswaPublicId && touched.siswaPublicId)}
          errorText={
            Boolean(errors.siswaPublicId && touched.siswaPublicId) &&
            errors.siswaPublicId
          }
          errorFetch={getSiswa.isError}
          errorFetchText={getSiswa?.error?.response?.data?.errorMessage[0]}
          loading={getSiswa.isFetching}
          disable={Boolean(values.kelasPublicId === "")}
        />
        <Select
          label="Hari"
          placeholder="Pilih Hari"
          name="hari"
          defaultValue={getDays?.data?.find(
            (item) => item.value === values?.hari
          )}
          onChange={(val) => setValues({ ...values, hari: val.value })}
          options={getDays?.data ?? []}
          error={Boolean(errors.hari && touched.hari)}
          errorText={Boolean(errors.hari && touched.hari) && errors.hari}
          errorFetch={getDays.isError}
          loading={getDays.isFetching}
        />
        <TextArea
          label="Keterangan"
          name="keterangan"
          value={values.keterangan}
          rows={4}
          onChange={handleChange}
          error={Boolean(errors.keterangan && touched.keterangan)}
          errorText={
            Boolean(errors.keterangan && touched.keterangan) &&
            errors.keterangan
          }
        />
        {modal.error && <Label type="error" text={modal.errorText} />}
      </div>
    );
  } else {
    return (
      <>
        <RowDetailLayout label="Tahun Ajaran" text={tahunAjaran} />
        <RowDetailLayout label="Kelas" text={values?.kelasNama} />
        <RowDetailLayout label="Nama Siswa" text={values?.siswaNama} />
        <Select
          label="Hari"
          placeholder="Pilih Hari"
          name="hari"
          defaultValue={getDays?.data?.find(
            (item) => item.value === values?.hari
          )}
          onChange={(val) => setValues({ ...values, hari: val.value })}
          options={getDays?.data ?? []}
          error={Boolean(errors.hari && touched.hari)}
          errorText={Boolean(errors.hari && touched.hari) && errors.hari}
          errorFetch={getDays.isError}
          loading={getDays.isFetching}
        />
        <TextArea
          label="Keterangan"
          name="keterangan"
          value={values.keterangan}
          rows={4}
          onChange={handleChange}
          error={Boolean(errors.keterangan && touched.keterangan)}
          errorText={
            Boolean(errors.keterangan && touched.keterangan) &&
            errors.keterangan
          }
        />
        {modal.error && <Label type="error" text={modal.errorText} />}
      </>
    );
  }
};
