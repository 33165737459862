import { 
    RolesApi 
} from "api"
import { 
    useState 
} from "react"
import { 
    useMutation,
    useQuery 
} from "react-query"
import { 
    errorFetching,
    paginationHandler,
    successFetching,
    useAuth, 
    useAxiosPrivate 
} from "utilities"
import {
    EditButton,
    ListLayout, 
    ViewButton,
    Button,
    Select,
    LabelDanger,
    LabelSuccess,
    LabelInfo,
    Modal
} from "components"
import { 
    FaUserLock 
} from "react-icons/fa"
import { 
    DetailSection, FormResetPasswordSection, FormSection 
} from "./__PenggunaComps__"
import { 
    Formik 
} from "formik"
import { 
    formInitialValues, 
    formResetPasswordInitialValues, 
    formResetPasswordValidationSchema, 
    formValidationSchema 
} from "./__PenggunaUtilities__"
import PenggunaApi from "./__PenggunaApi__"

export const Pengguna = () => {
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()
    const permissions = auth.permissions

    // <--- States --->
    const [pagination, setPagination] = useState({
		page: 1,
		size: 10,
        sortBy: 'name',
        rolePublicId: undefined
    })
    const [filter, setFilter] = useState({
        rolePublicId: "0"
    })
    const [modal, setModal] = useState({
        show: false,
        type: 'create',
        errorText: '',
        error: false,
        data: {}
    })

    // <--- Table's columns --->
    const columns = [
        {
            name: 'No',
            selector: (row, index) => ((getPengguna.data?.pageable?.pageNumber - 1) * getPengguna.data?.pageable?.pageSize) + index + 1,
            width: "70px" 
        },
        {
            name: 'Nama',
            selector: row => row.name,
            minWidth: "400px",
            wrap: true
        },
        {
            name: 'Username',
            selector: row => row.username,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Role',
            cell: data => data?.roles?.map((val, index) => <span key={index} className={`${data.roles.length !== index + 1 && 'mr-1'}`}><LabelInfo text={val} /></span>) ?? "-",
            wrap: true,
            width: "200px"
        },
        {
            name: 'Status',
            cell: data => data.isBlocked ? <LabelDanger text='Terblokir' /> : <LabelSuccess text='Aktif' />,
            width: '120px'
        },
        {
            name: 'Aksi',
            button: true,
            cell: (data) => <>
                {permissions.includes('USER_R') && <ViewButton icon noText onClick={() => onReadButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('USER_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
                {permissions.includes('USER_U') && <Button onClick={() => onResetPasswordButtonClickHandler(data)} className='btn-none p-2 mr-1'><FaUserLock /></Button>}
            </>,
            width: '120px'
        }
    ]

    // <--- useQuery --->
    const getRoles = useQuery(
        ['peran-list'],
        () => RolesApi.getRoles(axiosPrivate)
    ) 

    const getPengguna = useQuery(
        ['pengguna-list', pagination],
        () => PenggunaApi.getList(axiosPrivate, paginationHandler(pagination))
    )

    // useMutation
    const updatePengguna = useMutation(({data, publicId}) => PenggunaApi.update(axiosPrivate, data, publicId), {
        onSuccess: () => getPengguna.refetch()
    }) 

    const resetPasswordPengguna = useMutation(({data, publicId}) => PenggunaApi.resetPassword(axiosPrivate, data, publicId), {
        onSuccess: () => getPengguna.refetch()
    })

    // <--- Functions --->
	const handlePageChange = page => setPagination({...pagination, page: page})

    const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

    const searchDataHandler = (e) => {
        let time

		clearTimeout(time)

		time = setTimeout(() => {
			setPagination({
				...pagination,
				search: e.target.value,
                searchBy: 'all',
			})
		}, 1000)
    }
    
    const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

    const onClickCreateButton = () => setModal({ show: true, type: 'create', errorText: '', error: false, data: {} })

    const onReadButtonClickHandler = data => setModal({ show: true, type: 'read', errorText: '', error: false, data: data})

    const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
    
    const onResetPasswordButtonClickHandler = data => setModal({ show: true, type: 'reset', errorText: '', error: false, data: data })

    return (
        <>
            <div className="flex flex-row mb-4">
                <div className='flex'>
                    <Select 
                        label='Role'
                        placeholder='Pilih Role'
                        defaultValue={getRoles?.data?.find(item => item.value === filter?.rolePublicId)}
                        onChange={val => {
                            setPagination({
                                ...pagination,
                                rolePublicId: val.value !== '0' ? val.value : undefined
                            })
                            setFilter({
                                rolePublicId: val.value
                            })
                        }}
                        options={getRoles?.data ?? []}
                        errorFetch={getRoles.isError}
                        errorFetchText={getRoles?.error?.response?.data?.errorMessage[0]}
                        loading={getRoles.isFetching}
                    />
                </div>
            </div>
            {/* <--- Roles dropdown, Search input, and Table ---> */}
            <ListLayout 
                permissions={permissions}
                data={getPengguna.data?.content}
                columns={columns}
                loading={getPengguna.isFetching}
                error={getPengguna.error?.response?.data?.errorMessage[0]}
                pagination={true}
                totalRows={getPengguna.data?.pageable?.totalElements}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchChange={searchDataHandler}
                onClickCreateButton={onClickCreateButton}
            />

            {/* <--- MODAL READ ---> */}
            {modal.type === 'read' && modal.show &&
                <Modal
                    id='modal-detil-pengguna'
                    header='Detail Pengguna'
                    size='small'
                    type='read'
                    onHide={onHideModalHandler}
                    show={Boolean(modal.show && modal.type === 'read')}
                >
                    <DetailSection data={modal} />
                </Modal>
            }
            
            {/* <--- MODAL UPDATE ---> */}
            {modal.type === 'update' && modal.show &&
                <Formik
                    enableReinitialize
                    initialValues={formInitialValues(modal?.data)}
                    validationSchema={formValidationSchema()}
                    onSubmit={(values, { resetForm }) => {
                        updatePengguna.mutateAsync({
                            data: values,
                            publicId: values.userPublicId
                        }).then((res) => {
                            resetForm()
                            successFetching(res)
                            onHideModalHandler()
                        }).catch(err => {
                            const errorMessage = errorFetching(err)
                            setModal({
                                ...modal,
                                error: true,
                                errorText: errorMessage
                            })
                        })
                    }}
                >
                    {(formik) => {
                        const { handleSubmit } = formik

                        return (
                            <Modal
                                id='modal-ubah-pengguna'
                                header='Ubah Pengguna'
                                size='small'
                                type='update'
                                onHide={onHideModalHandler}
                                show={Boolean(modal.show && modal.type === 'update')}
                                onSubmit={handleSubmit}
                                isSubmitting={updatePengguna.isLoading}
                            >
                                <FormSection modal={modal} publicId={modal.data.publicId} />
                            </Modal>
                        )
                    }}
                </Formik>
            }
            
            {/* <--- MODAL RESET PASSWORD ---> */}
            {modal.type === 'reset' && modal.show &&
                <Formik
                    enableReinitialize
                    initialValues={formResetPasswordInitialValues(modal?.data)}
                    validationSchema={formResetPasswordValidationSchema()}
                    onSubmit={(values, { resetForm }) => {
                        resetPasswordPengguna.mutateAsync({
                            data: values,
                            publicId: values.userPublicId
                        }).then((res) => {
                            resetForm()
                            successFetching(res)
                            onHideModalHandler()
                        }).catch(err => {
                            const errorMessage = errorFetching(err)
                            setModal({
                                ...modal,
                                error: true,
                                errorText: errorMessage
                            })
                        })
                    }}
                >
                    {(formik) => {
                        const { handleSubmit } = formik

                        return (
                            <Modal
                                id='modal-reset-pengguna'
                                header='Reset Password Pengguna'
                                size='small'
                                type='update'
                                onHide={onHideModalHandler}
                                show={Boolean(modal.show && modal.type === 'reset')}
                                onSubmit={handleSubmit}
                                isSubmitting={resetPasswordPengguna.isLoading}
                            >
                                <FormResetPasswordSection modal={modal} publicId={modal.data.publicId} />
                            </Modal>
                        )
                    }}
                </Formik>
            }
        </>
    )
}
