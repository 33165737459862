const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class AlumniApi {
  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/alumni/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  async detail(axiosPrivate, id) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${id}`);
    return fetch.data.payload;
  }

  kembaliKeKelas(axiosPrivate, classroomPublicId, studentPublicId) {
    return axiosPrivate.put(`${url}/v1/classrooms/${classroomPublicId}/students/${studentPublicId}/ungraduated`)
  }
}

export default new AlumniApi();
