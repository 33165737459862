const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KategoriGuruApi {
    async getList(axiosPrivate, params) {
        const fetch = await axiosPrivate.get(`${url}/v1/teacher-categories/pageable`, { params })
        return fetch.data.payload
    }

    create(axiosPrivate, data) {
        return axiosPrivate.post(`${url}/v1/teacher-categories`, data)
    }

    update(axiosPrivate, data, id) {
        return axiosPrivate.put(`${url}/v1/teacher-categories/${id}`, data)
    }

    delete(axiosPrivate, id) {
        return axiosPrivate.delete(`${url}/v1/teacher-categories/${id}`)
    }
}

export default new KategoriGuruApi()