const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class BatasJamMasukApi {
  async getCurrentAttendanceTime(axiosPrivate) {
    const fetch = await axiosPrivate.get(
      `${url}/v1/library-attendance-max-time/current`
    );
    return fetch.data.payload;
  }

  create(axiosPrivate, data) {
    return axiosPrivate.post(`${url}/v1/library-attendance-max-time`, data);
  }
}

export default new BatasJamMasukApi();
